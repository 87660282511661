<template>
  <div>
    <!-- style="text-align: right" -->


  </div>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue'
export default {

  components: {

    DownOutlined,
  },

  data () {
    return {
      width: 12,  // 初始值为null
      onewidth: 0
    }
  },
  created () {

  },
  methods: {
    handleResize () {
      this.width = this.$el.clientWidth  // 更新宽度

    },
  },
  mounted () {
    if (this.onewidth == 0) {
      this.onewidth = this.$el.clientWidth  // 更新宽度
    }
    this.width = this.$el.clientWidth  // 获取父元素的宽度
    window.addEventListener('resize', this.handleResize)  // 监听窗口大小变化
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)  // 取消监听
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
* {
  margin: 0;
  padding: 0
}
</style>

