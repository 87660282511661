<template>
  <div class="login-container">
    <div style="padding: 10% 0 0 50%;">
      <a-space>
        <a-form ref="form" :model="form" class="login-form">
          <a-space style="height: 65px;" align="center">
            <div style="margin-right: 1%;">
              <img style="width: 30px;" alt="example" src="@/assets/logo.png" />
            </div>
            <!-- <a-space :size="width==onewidth?(width-(width*0.3*0.9)):(width-(width*0.4))"> -->
            <span class="title1">瓯越通供应链</span>
          </a-space>
          <a-space direction="horizontal" style="padding-top: 30px;">
            <a-button :type="logintype == '1' ? 'link' : ''" block @click="password">
              <span :class="logintype == '1' ? 'title' : ''">账户密码登录</span>
            </a-button>
            <!-- <a-button :type="logintype == '2' ? 'link' : ''" block @click="phone">
            <span :class="logintype == '2' ? 'title' : ''">验证码登录</span>
          </a-button> -->
          </a-space>

          <a-form-item style="padding-top: 20px;">
            <a-input class="inputBox" v-model:value="form.username" placeholder="请输入手机号">
              <template #prefix>
                <user-outlined type="user" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-space direction="vertical">
              <a-input-password v-if="logintype == '1'" class="inputBox" v-model:value="form.password"
                placeholder="请输入密码">
                <template #prefix>
                  <lock-outlined />
                </template>
              </a-input-password>

              <a-checkbox-group v-model:value="rememberpassword" @change="passwordtype">
                <a-checkbox value="1">记住密码</a-checkbox>
              </a-checkbox-group>
            </a-space>
            <!-- <a-input v-if="logintype == '2'" class="inputBox1" v-model:value="form.password" placeholder="请输入验证码">
            </a-input>
            <a-button type="primary" v-if="logintype == '2'" block @click="getCode()"
              style="width: 80px;height: 40px;color: #fff;">

              <span class="title2">获取</span>
            </a-button> -->
          </a-form-item>

          <!-- <ValidCode @input="createValidCode" /> -->
          <a-form-item>
            <a-button class="submit" type="primary" @click="onSubmit" :loading="loading">{{ loading ? "登录中" : "立即登录"
            }}</a-button>
          </a-form-item>
        </a-form>
      </a-space>
    </div>
  </div>
</template>

<script>
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import ValidCode from "@/common/ValidCode"
export default {
  components: {
    ValidCode,
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      //  lockoutlined,
      //  useroutlined
    }
  },
  data () {
    return {
      form: {
        username: "",
        password: "",
        show: true,
        count: '',
        timer: null,
      },
      rememberpassword: ["1"],
      loading: false,
      logintype: "1"
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    // 这是首次进入页面加载
    async getMenuList () {
      if (localStorage.getItem("username") != null) {
        this.form.username = localStorage.getItem("username")
        this.form.password = localStorage.getItem("password")
      }
    },
    //登录判断
    onSubmit () {
      //登录中加载
      this.loading = true
      // this.form.username != "" && this.form.password != "" && !/\s/g.test(this.form.username) && !/\s/g.test(this.form.password)
      console.log(this.form, 'this.form')
      if (this.form.username != "" && this.form.password != "") {
        axios.get(API + "/login", {
          params: {
            phoneNumber: this.form.username,
            password: this.form.password
          }
        }
        ).then(res => {
          if (res.data != "") {
            if (res.data.supplierStatus != "2" && res.data.delFlag != "2") {
              this.$message.success("登录成功")
              sessionStorage.setItem("user_info", JSON.stringify(res.data))
              // 判断是否记住密码
              localStorage.setItem("username", this.form.username)
              if (this.rememberpassword.length == 1) {
                localStorage.setItem("password", this.form.password)
              } else {
                sessionStorage.removeItem("password")
              }
              axios.get(API + "/getToken", {
                params: {
                  supplierId: res.data.id
                }
              }).then(tokenres => {
                sessionStorage.setItem("token", tokenres.data)
                this.$router.push('/')
                sessionStorage.setItem("sidebar", "2")
              })
            } else if (res.data.supplierStatus == "2") {
              this.$message.error("此供应商被禁止营业")
              // 加载取消
              this.loading = false
            } else {
              this.$message.error("账户已被删除")
              // 加载取消
              this.loading = false
            }
          } else {
            this.$message.error("密码/账户错误")
            // 加载取消
            this.loading = false
          }
        }).catch(error => {
          this.$message.error("后端接口连接异常")
          // 加载取消
          this.loading = false
        })
      } else {
        this.$message.error("账户密码不能为空")
        // 加载取消
        this.loading = false
      }
      sessionStorage.removeItem("errorQueue")
    },
    //密码登录
    password () {

      this.logintype = "1"
    },
    // 记住密码
    passwordtype (value) {
      console.log(value)
    },
    //手机验证码登录
    phone () {
      this.logintype = "2"
    },
    // 验证码登录
    getCode () {
      const TIME_COUNT = 30
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
  },
}
</script>
<style>
.login-form {
  width: 500px;
  height: 452px;
  margin: 0 auto;
  background: #ffff;
  padding: 40px 110px;
}

/* 背景 */
.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("@/assets/beijing.png");
}

/* Log */
.login-title {
  color: #fff;
  text-align: center;
  margin: 150px 0;
  font-size: 48px;
  font-family: Microsoft Yahei;
}

/* 登陆按钮 */
.submit {
  width: 100%;
  height: 45px;
  font-size: 16px;
}

/* 用户登陆标题 */
.title {
  margin-bottom: 50px;
  color: #666;
  font-weight: 700;
  font-size: 18px;
  font-family: #666;
}

.title1 {
  font-size: 25px;
  font-weight: bold;

}

.title2 {
  margin-bottom: 50px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  font-family: #666;
}

/* 输入框 */
.inputBox {
  height: 45px;
}

.inputBox1 {
  height: 45px;
  width: 200px;
}

/* 输入框内左边距50px */
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 50px;
}
</style>
