import OSS from 'ali-oss'
export function client () {

  const client = new OSS({
    // 正式
    accessKeyId: 'LTAI5t92HqgbHmbxrs5FL93K',
    accessKeySecret: 'n4Lhd9sNM3MwIlv5cCjDPmvRR6gu2s',
    bucket: 'ouyue-huo',
    cname: true,
    endpoint: 'https://huo.ouyuetech.com',
    filedir: 'ouyueHuo/',
    enable: true,
  })
  return client
}

const headers = {
  // 指定该Object被下载时的网页缓存行为。
  // "Cache-Control": "no-cache",
  // 指定该Object被下载时的名称。
  // "Content-Disposition": "example.txt",
  // 指定该Object被下载时的内容编码格式。
  // "Content-Encoding": "utf-8",
  // 指定过期时间，单位为毫秒。
  // Expires: "1000",
  // 指定Object的存储类型。
  // "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  'x-oss-object-acl': 'public-read',
  // 指定Object标签，可同时设置多个标签。
  // "x-oss-tagging": "Tag1=1&Tag2=2",
  // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  // "x-oss-forbid-overwrite": "true",

}
export {
  headers
}
export default client