<template>
  <div>
    <a-space :size="size" direction="vertical" style=" margin: 2px 0 0 0; padding: 20px 24px 24px 67px; '">
      <a-space align="baseline" :size="size">
        <span><span style="color: red;">*</span>商品分类：</span>
        <a-cascader popupClassName="AcascaderMenu" v-model:value="shoptype" ref="selectStyle" :options="options1"
          placeholder="选择分类" change-on-select style="width: 115px;" @change="styleChange" />
      </a-space>

      <a-space :size="size">
        <span><span style="color: red;">*</span>商品名称：</span>
        <a-input placeholder="请输入商品名称" style="width: 400px;" v-model:value="shopname" />
      </a-space>

      <a-space :size="size">
        <span><span style="color: red;">*</span>发货方式：</span>
        <a-space :size="120">
          <a-checkbox-group v-model:value="shippingMode">
            <a-checkbox value="shop" @change="huotype">支持物流发货</a-checkbox>
          </a-checkbox-group>
          <a-checkbox-group v-model:value="codestorevalue">
            <a-checkbox value="code" @change="huotype">支持发码</a-checkbox>
          </a-checkbox-group>
        </a-space>
      </a-space>

      <a-space :size="size" v-if="codestore">
        <span><span style="color: red;">*</span>归属门店：</span>
        <span v-if="dataSource == ''">暂无添加门店请先添加门店</span>
        <a-space :size="120" v-else>
          <a-checkbox-group v-model:value="shippingstore" @change="storetype">
            <a-checkbox :value="data.storeId + ',' + data.storeName" v-for="data in dataSource">{{
              data.storeName }}</a-checkbox>
          </a-checkbox-group>
        </a-space>
      </a-space>

      <a-space :size="size" align="start" style="padding-left: 5px;">
        <span>发货说明：</span>
        <a-textarea placeholder="120字以内" :rows="4" style="width: 400px;" showCount :maxlength="120"
          v-model:value="shippingText" />
      </a-space>

      <a-space :size="size">
        <span><span style="color: red;">*</span>售后权益：</span>
        <a-radio-group>
          <a-radio-group v-model:value="afterSaleInterest" @change="equitytype">
            <a-space :size="50">
              <a-radio value="1">支持七天无理由退换货</a-radio>
              <a-radio value="2">非质量问题不退换</a-radio>
            </a-space>
          </a-radio-group>
        </a-radio-group>
      </a-space>


      <a-space :size="size" v-if="huotime">
        <span><span style="color: red;">*</span>发货时间：</span>
        <a-radio-group v-model:value="Timevalue" @change="huoselecttypea">
          <a-space :size="120">
            <a-radio :value="1">下单后发货</a-radio>
            <a-radio :value="2">指定时间发货</a-radio>
          </a-space>
        </a-radio-group>
      </a-space>

      <a-space :size="size" v-if="huotime && huoselecttype">
        <span><span style="color: red;">*</span>选择时间：</span>
        <a-range-picker style="width: 400px" v-model:value="shippingTime" format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['开始时间', '结束时间']" />
      </a-space>

      <a-space :size="size" v-if="codestorevalue != 'code'">
        <span><span style="color: red;">*</span>多规格商品：</span>
        <a-radio-group size="large" v-model:value="config.visibleFlag" @change="onChangeVisibleFlag">
          <a-radio-button value="WhetherNo" style="width: 150px;text-align: center;" @click="WhetherNo">否</a-radio-button>
          <a-radio-button value="WhetherYes" style="width: 150px;text-align: center;"
            @click="WhetherYes">是</a-radio-button>
        </a-radio-group>
      </a-space>

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherNo'" style="padding-left: 5px;">
        <span>商品货号：</span>
        <a-input style="width: 400px;" placeholder="请输入商品货号" v-model:value="number" />
      </a-space>

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherYes'">
        <span><span style="color: red;">*</span>商品规格：</span>
        <a-button type="primary" @click="showModal(1)">+添加规格</a-button>
        <a-modal :open="visible" title="规格设置" @cancel="handleOk(3)" style="width: 40%;">
          <template #footer>
            <a-button key="back" @click="handleOk(3)">取消</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk(1)">确定</a-button>
          </template>
          <a-space direction="vertical">
            <a-space v-if="addmaxspecification" v-for="( ma, index) in maxtype">
              <a-space direction="vertical">
                <a-input v-model:value="maxtype[index].text" placeholder="请输入规格名称（颜色）" />
                <div style="display: flex;">
                  <div style="display: flex;flex-wrap: wrap;">
                    <div v-if="maxtype != ''" v-for="(u, index1) in maxtype[index].mintext">
                      <a-space>
                        <div>{{ u.text }}</div>
                        <a-button type="link" @click="delmintype(index1, index)" v-if="u.text != ''">
                          <CloseCircleFilled />
                        </a-button>
                      </a-space>
                    </div>
                  </div>
                  <a-button key="back" @click="showModal(2, index)">+添加</a-button>
                </div>
              </a-space>
              <a-button type="link" @click="addspecification('del', index)">
                <CloseCircleFilled />
              </a-button>

            </a-space>

            <a-button key="back" @click="addspecification('add')" type="primary"
              v-if="maxtype.length < 3">添加规格项目</a-button>
            <span>添加规格后,商品的供应价和库存将以规格中的信息为准。若包含多条规格供应价将显示所有规格中的最小值,库存将显示所有规格库存的总和。</span>
          </a-space>
        </a-modal>
        <a-modal :open="visiblea" title="请输入规格值" @cancel="confirmtype(4)">
          <template #footer>
            <a-button key="back" @click="handleOk(4)">取消</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk(2)">确定</a-button>
          </template>
          <a-input v-if="visiblea" v-model:value="maxtype[maxtypeindex].minvalue" placeholder="请输入规格值（红色）" />
        </a-modal>
      </a-space>

      <a-space :size="size" direction="vertical" v-if="config.visibleFlag == 'WhetherYes' && stdata != ''">
        <a-table :columns="columns" :data-source="stdata" @change="tablePaginationChange" :pagination="false">
          <template #name="{ text }">
            <a>{{ text }}</a>
          </template>
          <template #bodyCell="{ column, index }">
            <template v-if="column.dataIndex == 'memberprice'">
              <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="请输入零售价"
                v-model:value="stdata[index].priceonetext" />
            </template>
            <!-- ,priceonetext:"",pricetwotext:"",invtext:"",huotext:"" -->
            <template v-if="column.dataIndex == 'markrtprice'">
              <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="请输入供货价"
                v-model:value="stdata[index].pricetwotext" />
            </template>
            <template v-if="column.dataIndex == 'collectionprice'">
              <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="请输入集采价"
                v-model:value="stdata[index].pricethreetext" />
            </template>
            <template v-if="column.dataIndex == 'inventory'">
              <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="请输入库存"
                v-model:value="stdata[index].invtext" />
            </template>
            <template v-if="column.dataIndex == 'huo'">
              <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="请输入货号"
                v-model:value="stdata[index].huotext" />
            </template>
          </template>
        </a-table>
        <a-space>
          <a-button @click="sameindex('priceonetext')"
            style="width: 100px;text-align: center;height: 60px;">统一零售价</a-button>
          <a-button @click="sameindex('pricetwotext')"
            style="width: 100px;text-align: center;height: 60px;">统一供货价</a-button>
          <a-button @click="sameindex('pricethreetext')"
            style="width: 100px;text-align: center;height: 60px;">统一集采价</a-button>
          <a-button @click="sameindex('invtext')" style="width: 100px;text-align: center;height: 60px;">统一库存</a-button>
        </a-space>
      </a-space>

      <a-modal :open="visibleb" :title="texttitle" @ok="samemenberprice" @cancel="visibleb = false"
        v-if="config.visibleFlag == 'WhetherYes'">
        <template #footer>
          <a-button key="back" @click="samemenberprice()">取消</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="samemenberprice()">确定</a-button>
        </template>
        <a-input v-if="visibleb" v-model:value="allindex" :placeholder="'请输入' + texttitle" />
      </a-modal>

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherNo'" style="padding-left: 5px;">

        <span>商品价格：</span>
        <span><span style="color: red;">*</span>零售价：</span>
        <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="零售价" style="width: 115px;"
          v-model:value="PriceSale" />
        <span><span style="color: red;">*</span>供货价：</span>
        <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="供货价" style="width: 115px;"
          v-model:value="PriceSupply" />
        <span>集采价：</span>
        <a-input onkeyup="value=value.replace(/[^\d.]/g,'')" placeholder="集采价" style="width: 115px;"
          v-model:value="PriceCollection" />
      </a-space>

      <!-- <a-space :size="size" v-if="config.visibleFlag == 'WhetherNo'">

        <span><span style="color: red;">*</span>供货价：</span>
            <a-input placeholder="供货价" style="width: 195px;" v-model:value="PriceSupply" />
      </a-space> -->

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherNo'">
        <span><span style="color: red;">*</span>商品库存：</span>
        <a-input style="width: 400px;" placeholder="请输入商品库存" v-model:value="huoStockCounts" />
      </a-space>

      <a-space :size="size">
        <span><span style="color: red;">*</span>商品单位：</span>
        <a-input style="width: 400px;" placeholder="请输入商品单位" v-model:value="commodity" />
      </a-space>

      <a-space :size="45" v-if="invoiceInterest == 1">
        <span><span style="color: red;">*</span>税率：</span>
        <div>
          <a-select ref="select" v-model:value="value1" style="width: 70px" placeholder="请选择税率" @focus="focus" mode="tags"
            :showSearch="true" @change="handleChange">
            <a-select-option value="0">0</a-select-option>
            <a-select-option value="3">3</a-select-option>
            <a-select-option value="6">6</a-select-option>
            <a-select-option value="9">9</a-select-option>
            <a-select-option value="11">11</a-select-option>
            <a-select-option value="13">13</a-select-option>
          </a-select>
          <span>%</span>
        </div>
      </a-space>

      <a-space :size="size">
        <span><span style="color: red;">*</span>是否含税：</span>
        <a-radio-group>
          <a-space :size="110">
            <a-radio-group v-model:value="invoiceInterest" @change="invoicetypeChange">
              <a-radio value="1">含税</a-radio>
              <a-radio value="2">不含税</a-radio>
            </a-radio-group>
          </a-space>
        </a-radio-group>
      </a-space>

      <a-space :size="size" align="conter">
        <span><span style="color: red;">*</span>商品图：</span>
        <draggable v-if="fileList.length > 0" style="  display: flex" v-model="fileList" :list="fileList" :animation="100"
          item-key="id" class="list-group" chosenClass="active" :forceFallback="true" ghost-class="ghost" @change="change"
          :move="getdata" @end="onEnd">
          <template #item="{ element, index }">
            <div>
              <a-image alt="example" style="width: 180px" :src="element.url" />
              <a-space>
                <CloseCircleOutlined @click="removeFn(index)" />
              </a-space>
            </div>

          </template>

        </draggable>
        <div v-if="uploading">
          上传中 {{ percent }}%
          <LoadingOutlined />
        </div>

        <a-upload v-if="fileList.length < 5" list-type="picture-card" :fileList="[]" :multiple="true"
          class="upload-list-inline" :before-upload="beforeUploadMains" @change="handleChangeImg"
          :customRequest="uploadImage">
          <div>
            <plus-outlined></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>

      </a-space>

      <span style="padding-left: 5px;">
        建议尺寸：640×640像素；您可以推拽图片调整图片顺序。您最多只能上传5张
      </span>


      <a-space :size="size" align="conter" style="padding-top: 20px;">

        <span style="white-space: nowrap;"><span style="color: red;">*</span>商品详情：</span>
        <div class="edit_container">
          <a-form-item label="" style="position: relative;z-index: 1;">
            <Editor :value.default="shoptextshow" @change="wangEditorChange"></Editor>
          </a-form-item>
        </div>

      </a-space>

      <a-space style="padding-left: 40%;" v-if="Buttontype">
        <a-popconfirm title="确定存入草稿吗?" ok-text="确定" cancel-text="取消" @confirm="addshop(1)">
          <a-button style="text-align: center;" :loading="loading">存入草稿</a-button>
        </a-popconfirm>
        <a-popconfirm title="确定发布吗?" ok-text="确定" cancel-text="取消" @confirm="addshop(2)">
          <a-button type="primary" style="text-align: center;" :loading="loading">马上发布</a-button>
        </a-popconfirm>
      </a-space>

      <a-space style="padding-left: 40%;" v-if="!Buttontype">
        <a-popconfirm title="确定存入草稿吗?" ok-text="确定" cancel-text="取消" @confirm="updateshop(1)">
          <a-button style="text-align: center;" :loading="loading">存入草稿</a-button>
        </a-popconfirm>
        <a-popconfirm title="确定发布吗?" ok-text="确定" cancel-text="取消" @confirm="updateshop(2)">
          <a-button type="primary" style="text-align: center;" :loading="loading">马上发布</a-button>
        </a-popconfirm>
      </a-space>
    </a-space>
  </div>
</template>
<script >
// import { addQuillTitle } from '../assets/js/quill-title.js'
import draggable from 'vuedraggable'
import { SearchOutlined } from '@ant-design/icons-vue'
import headerTopNav from '@/common/HeadSlot.vue'
import headerHead from '@/common/PublicHeader.vue'
import Editor from '@/common/EditorText.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import API from "@/assets/js/axiosInstance.js"
import moment from 'moment'
import oss from "@/utils/ossutils.js"
import axios from 'axios'
const columns = [
  {
    title: '零售价(元)',
    dataIndex: 'memberprice',
    key: 'memberprice',

  },
  {
    title: '供货价(元)',
    dataIndex: 'markrtprice',
    key: 'markrtprice',
    ellipsis: true,
  },
  {
    title: '集采价(元)',
    dataIndex: 'collectionprice',
    key: 'collectionprice',
    ellipsis: true,
  },
  {
    title: '库存',
    dataIndex: 'inventory',
    key: 'inventory',
    ellipsis: true,
  },
  {
    title: '货号',
    dataIndex: 'huo',
    key: 'huo',
    ellipsis: true,
  },
]

export default {
  name:'AddShop',
  props: {
    multiple: {
      type: Number,
      default: 1
    },
    img: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    headerTopNav,
    draggable,
    headerHead,
    Editor,
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup() {
    return {
      SearchOutlined,
      locale
    }
  },
  data() {
    return {
      size: "middle",//small | middle | large | number
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      loading: false,
      Probability: 0,
      oss,
      shippingMode: "shop",
      afterSaleInterest: "1",
      maxtype: [],
      mintype: [],
      stdata: [],
      texttitle: "",
      Timevalue: 1,
      uploadList: [],
      allindex: "",
      alltxt: "",
      uploading: false,
      codestore: false,
      stdatasize: 0,
      huoselect: 1,
      addmaxspecification: false,
      allContent: '',
      maxtypeindex: "",
      data: [
      ],
      config: {
        visibleFlag: "WhetherNo"
      },
      columns: columns,
      options1: [],
      children: [],
      storename: "",
      storeid: "",
      shoptype: undefined,
      visible: false,
      visiblea: false,
      visibleb: false,
      codestorevalue: "",
      shoptypeone: "",
      invoiceInterest: "1",
      isClear: false,//设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      commodity: "",
      tax: 0,
      shoptypetwo: "",
      shopname: '',
      huoStockCounts: "",
      specification: 1,
      Status: [0],
      number: "",
      value1: 0,
      shippingText: "",
      PriceSale: "",
      PriceSupply: "",
      PriceCollection: "",
      shoptext: "",
      shippingcode: "",
      imageUrl: '',
      selectsum: 0,
      previewImage: "",
      stdatamin: "",
      shoptextshow: "",
      stdatamax: "",
      stdatavalue: false,
      Buttontype: true,
      shippingstore: [],
      fileList: [

      ],
      huotime: true,
      dataSource: [],
      huocode: false,
      huoselecttype: false,
      shippingTime: "2"
    }

  },
  watch: {
    $route() {
      // 初始化数值
      Object.assign(this.$data, this.$options.data())
      this.getMenuList(),
        this.getstore()
    }
  },
  created() {
    this.getMenuList(),
      this.getstore()
  },
  methods: {
    // huoLevelOne类型1，huoLevelTwo类型二，huoName商品名称，huoNumber货号，huoPriceSupply商品价格，huoPriceSale零售价，
    // huoStockCounts库存，huoPic图片组，huoState状态，Status税率，specification规格，this.shippingMode物流送货，
    // this.shippingTime时间，this.shippingText发货说明，this.commodity商品单位，this.afterSaleInterest售后权益
    // this.shoptext商品详情,styledata多规格储存，stdatamin小规格，stdatamax大规格 ,huoid为货物id
    // invoiceInterest是否开发票1为是2为否，storename，storeid门店id和门店名称
    //这是修改ajax
    updateajax(huoLevelOne, huoLevelTwo, huoName, huoNumber, huoPriceSupply,
      huoPriceSale, huoStockCounts, huoPic, huoState, Status, specification, huoid) {
      //post请求往后传参数
      let data = new FormData()
      data.append("huoId", huoid)
      data.append('supplierId', JSON.parse(sessionStorage.getItem("user_info")).id)
      data.append('huoName', huoName)
      data.append('huoLevelOne', huoLevelOne)
      data.append('huoLevelTwo', huoLevelTwo)
      data.append('huoNumber', huoNumber)
      data.append('huoPriceSupply', huoPriceSupply)
      data.append('huoPriceSale', huoPriceSale)
      data.append('huoStockCounts', huoStockCounts)
      data.append('huoPic', huoPic)
      data.append('huoState', huoState)
      data.append('status', Status)
      data.append('specification', specification)
      data.append('shippingMode', this.shippingMode)
      data.append('shippingCode', this.shippingcode)
      if (this.shippingTime == "2" || this.shippingTime == "1") {
        data.append('shippingTime', this.shippingTime)
      } else {
        data.append('shippingTime', this.shippingTime[0].format('YYYY-MM-DD HH:mm:ss') + "," + this.shippingTime[1].format('YYYY-MM-DD HH:mm:ss'))
      }
      data.append('shippingText', this.shippingText)
      data.append('commodity', this.commodity)
      data.append('afterSaleInterest', this.afterSaleInterest)
      data.append('shopText', this.shoptext)
      data.append("stDataMax", this.stdatamax)
      data.append('stDataMin', this.stdatamin)
      data.append('invoiceInterest', this.invoiceInterest)
      data.append('storeName', this.storename)
      data.append('storeId', this.storeid)
      data.append('priceCollection', this.PriceCollection)
      axios.post(API + "/updateHouShop",
        data
      ).then(res => {
        // this.$router.push('/ShopManage')
        this.$router.back()
        this.$message.success('上传成功')
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
        this.loading = false
      })
    },//封装修改商品ajax上面
    //这是添加ajax
    addajax(huoLevelOne, huoLevelTwo, huoName, huoNumber, huoPriceSupply,
      huoPriceSale, huoStockCounts, huoPic, huoState, Status, specification) {
      //post请求往后传参数
      let data = new FormData()
      data.append('huoName', huoName)
      data.append('huoLevelOne', huoLevelOne)
      data.append('huoLevelTwo', huoLevelTwo)
      data.append('huoNumber', huoNumber)
      data.append('huoPriceSupply', huoPriceSupply)
      data.append('huoPriceSale', huoPriceSale)
      data.append('huoStockCounts', huoStockCounts)
      data.append('huoPic', huoPic)
      data.append('huoState', huoState)
      data.append('status', Status)
      data.append('specification', specification)
      data.append('shippingMode', this.shippingMode)
      data.append('shippingCode', this.shippingcode)
      if (this.shippingTime == "2" || this.shippingTime == "1") {
        data.append('shippingTime', this.shippingTime)
      } else {
        data.append('shippingTime', this.shippingTime[0].format('YYYY-MM-DD HH:mm:ss') + "," + this.shippingTime[1].format('YYYY-MM-DD HH:mm:ss'))
      }
      data.append('shippingText', this.shippingText)
      data.append('commodity', this.commodity)
      data.append('afterSaleInterest', this.afterSaleInterest)
      data.append('shopText', this.shoptext)
      data.append("stDataMax", this.stdatamax)
      data.append('stDataMin', this.stdatamin)
      data.append('invoiceInterest', this.invoiceInterest)
      data.append('storeName', this.storename)
      data.append('storeId', this.storeid)
      data.append('priceCollection', this.PriceCollection)
      data.append('supplierId', JSON.parse(sessionStorage.getItem("user_info")).id)
      axios.post(API + "/addHouShop",
        data
      )
        .then(res => {
          // this.$router.push('/ShopManage')
          this.$router.replace(this.$router.path)
          this.$message.success('上传成功')
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
          this.loading = false
        })
    },//封装添加商品ajax上面
    //商品分类value拿到的是选择的商品类型1和2
    styleChange(value, index) {
      if (value != undefined) {
        if (value[1] != undefined) {
          this.shoptypeone = value[0]
          this.shoptypetwo = value[1]
          // this.shoptype = value[0] + "," + value[1]
        } else {
          axios.get(API + "/levelTwoList", {
            params: {
              id: index[0].id,
            }
          }
          ).then(res => {
            this.children.splice(0, this.children.length)
            this.children
            for (var j = 0; j < res.data.length; j++) {
              this.children.push({ value: res.data[j].id, label: res.data[j].levelTwoName })
            }
            this.options1.splice(index[0].index, 1, {
              index: index[0].index, id: index[0].id, value: index[0].value, label: index[0].label,
              children: this.children
            })

          }).catch(error => {

          })
        }
      } else {
        this.shoptypeone = ""
        this.shoptypetwo = ""
      }
    },
    //这是取最小值
    minmath(math1, math2, minNumber) {

      if (math1 < math2) {
        if (math1 < minNumber || minNumber == 0) {
          minNumber = math1
        }
      } else {
        if (math2 < minNumber || minNumber == 0) {
          minNumber = math2
        }
      }
      return minNumber
    },
    //这是添加的确定按钮
    addshop(state) {
      // 判断此处为草稿则无限制储存
      if (state == 1) {
        this.$message.loading("提交中")
        this.loading = true
        //此处做多规格操作
        this.allstdata()
        //图片上传的同时上传商品信息
        this.addajax(this.shoptypeone, this.shoptypetwo, this.shopname, this.number, this.PriceSupply, this.PriceSale, this.huoStockCounts, this.imageUrl, state, this.Status, this.specification)
      } else {
        if (this.config.visibleFlag == "WhetherYes") {
          this.stdatavalue = true
          for (var i = 0; i < this.stdata.length; i++) {
            // 判断每个规格的供货价，零售价和库存是否为空,为空返回false，修改失败
            if (this.stdata[i].invtext == "" || this.stdata[i].pricetwotext == "" || this.stdata[i].priceonetext == ""
              || this.stdata[i].invtext == undefined || this.stdata[i].pricetwotext == undefined || this.stdata[i].priceonetext == undefined
              //  做判断是否有空格
              || /\s/g.test(this.stdata[i].invtext) || /\s/g.test(this.stdata[i].pricetwotext) || /\s/g.test(this.stdata[i].priceonetext)) {
              this.stdatavalue = false
            }
          }
        }

        if (this.shoptypeone != "" && this.shoptypetwo != "" && this.shopname != "" && this.fileList != ""
          && this.Status !== "" && this.specification != "" && (this.shippingMode != "" || this.shippingcode != "")
          && this.commodity != "" && this.shoptext != "" &&
          ((this.PriceSupply != "" && this.PriceSale != "" && this.huoStockCounts != "") ||
            (this.stdata != "" && this.stdatavalue))
        ) {
          this.$message.loading("提交中")
          this.loading = true
          //此处做多规格操作
          this.allstdata()
          //图片上传的同时上传商品信息
          this.addajax(this.shoptypeone, this.shoptypetwo, this.shopname, this.number, this.PriceSupply, this.PriceSale, this.huoStockCounts, this.imageUrl, state, this.Status, this.specification)
        } else {
          this.$message.warning('还有必要信息未填写')
        }
      }
    },
    //获取菜单的数据
    async getMenuList() {
      this.config.visibleFlag = "WhetherNo"
      if (this.$route.query.visibleFlag != undefined) {
        this.config.visibleFlag = this.$route.query.visibleFlag
      }


      //编辑进入此处获取已编辑的信息
      if (this.$route.query.shopinformation != undefined) {
        axios.get(API + "/redisUpdate", {
          params: {
            huoId: this.$route.query.shopinformation,
            supplierId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }).then(res => {
          //商品分类
          if (res.data.huoLevelTwo != "") {
            axios.get(API + "/levelOneAndTwo", {
              params: {
                id: res.data.huoLevelTwo
              },
            }).then(res => {
              this.shoptype = res.data
            })
          }

          this.shoptypeone = res.data.huoLevelOne
          this.shoptypetwo = res.data.huoLevelTwo
          // 商品名称
          this.shopname = res.data.huoName
          //库存
          if (res.data.huoStockCounts != undefined) {
            this.huoStockCounts = res.data.huoStockCounts
          }

          // 零售价
          if (res.data.huoPriceSale != undefined) {
            this.PriceSale = res.data.huoPriceSale
          }
          // 供货价
          if (res.data.huoPriceSupply != undefined) {
            this.PriceSupply = res.data.huoPriceSupply
          }

          // 发货说明
          this.shippingText = res.data.shippingText
          // 这是页面渲染的税率
          this.value1 = res.data.taxRate / 0.01
          // 这是往后端传的税率
          this.Status = res.data.taxRate
          // 商品单位
          this.commodity = res.data.commodity
          // 商品货号
          this.number = res.data.huoNumber
          // 集采价
          if (res.data.priceCollection != undefined) {
            this.PriceCollection = res.data.priceCollection
          }
          //判断是否发码
          if (res.data.shippingCode != "1") {
            // 编辑选择的门店进入页面
            this.storeid = res.data.storeId
            this.storename = res.data.storeName
            for (var i = 0; i < res.data.storeId.split(",").length; i++) {
              this.shippingstore.splice(0, 0, res.data.storeId.split(",")[i] + "," + res.data.storeName.split(",")[i])
            }
            // 这是判断是否发码的值1为不是支持发码
            this.shippingcode = "code"
            // 管理页面样式
            this.codestorevalue = "code"
            this.codestore = true
          }
          //判断是否发货
          if (res.data.shippingTime == "1") {
            this.shippingMode = ""
            this.huotime = false
          } else {
            if (res.data.shippingTime != "2") {
              // 时间转moment格式渲染到时间选择器上
              var alltime = res.data.shippingTime.split(",")
              const validTime = [moment(alltime[0], 'YYYY-MM-DD HH:mm:ss'),
              moment(alltime[1], 'YYYY-MM-DD HH:mm:ss')]
              this.shippingTime = validTime
              this.huoselecttype = true
              this.Timevalue = 2
            }
          }
          // 编辑选择的是否发票数据进入页面
          this.invoiceInterest = res.data.itInvoice
          // storename    storeid
          // 编辑的商品为多规格时进入
          if (res.data.specificationType == "2") {
            this.config.visibleFlag = "WhetherYes"
            //多规格初始化，防止出现大规格有缓存出现重复规格
            this.maxtype = []
            this.stdata = []
            this.columns = [
              {
                title: '零售价(元)',
                dataIndex: 'memberprice',
                key: 'memberprice',

              },
              {
                title: '供货价(元)',
                dataIndex: 'markrtprice',
                key: 'markrtprice',
                ellipsis: true,
              },
              {
                title: '集采价(元)',
                dataIndex: 'collectionprice',
                key: 'collectionprice',
                ellipsis: true,
              },
              {
                title: '库存',
                dataIndex: 'inventory',
                key: 'inventory',
                ellipsis: true,
              },
              {
                title: '货号',
                dataIndex: 'huo',
                key: 'huo',
                ellipsis: true,
              },
            ]
            //大规格
            for (var i = 0; i < res.data.stDataMax.split(",").length; i++) {
              this.maxtype.push({ mintext: [{ text: "" }], text: res.data.stDataMax.split(",")[i], minvalue: res.data.stDataMax.split(",")[i] })
              this.columns.splice(i, 0, {
                dataIndex: "text" + i,
                title: res.data.stDataMax.split(",")[i]
              })
            }
            console.log("--------------------")
            console.log(res.data)
            // 初始化判断重复的值
            var samestdata = ""
            // 获取到小规格中的基本信息遍历到页面data的stdata中
            for (var i = 0; i < res.data.stDataMin.split(",").length; i++) {
              var stdatamin = res.data.stDataMin.split(",")[i].split("?")
              // 新加的集采价和旧的没有加集采价
              if (stdatamin.length - 1 == 7) {
                if (stdatamin[1] == "undefined") {
                  this.stdata.splice(i, 0, { text0: stdatamin[0], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], pricethreetext: stdatamin[7], invtext: stdatamin[5], huotext: stdatamin[6] })
                  //  k判断有几个类型
                  var k = 1
                } else if (stdatamin[2] == "undefined") {
                  this.stdata.splice(i, 0, { text0: stdatamin[0], text1: stdatamin[1], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], pricethreetext: stdatamin[7], invtext: stdatamin[5], huotext: stdatamin[6] })
                  var k = 2
                } else {
                  var k = 3
                  this.stdata.splice(i, 0, { text0: stdatamin[0], text1: stdatamin[1], text2: stdatamin[2], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], pricethreetext: stdatamin[7], invtext: stdatamin[5], huotext: stdatamin[6] })
                }
              } else {
                if (stdatamin[1] == "undefined") {
                  this.stdata.splice(i, 0, { text0: stdatamin[0], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], invtext: stdatamin[5], huotext: stdatamin[6] })
                  //  k判断有几个类型
                  var k = 1
                } else if (stdatamin[2] == "undefined") {
                  this.stdata.splice(i, 0, { text0: stdatamin[0], text1: stdatamin[1], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], invtext: stdatamin[5], huotext: stdatamin[6] })
                  var k = 2
                } else {
                  var k = 3
                  this.stdata.splice(i, 0, { text0: stdatamin[0], text1: stdatamin[1], text2: stdatamin[2], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], invtext: stdatamin[5], huotext: stdatamin[6] })
                }
              }
              //通过有几种类型往添加规格小窗口中添加数值
              for (var j = 0; j < k; j++) {
                console.log(stdatamin[j])
                console.log(samestdata)
                console.log(samestdata.indexOf(stdatamin[j]))
                if (samestdata.indexOf(stdatamin[j]) == -1) {
                  samestdata += stdatamin[j] + ","
                  this.mintype = this.maxtype[j].mintext
                  this.mintype.push({ text: stdatamin[j] })
                  this.maxtype[j].mintext = this.mintype
                  this.mintype = ''
                }
              }
            }
            this.addmaxspecification = true
          }
          //售后权益改变
          this.afterSaleInterest = res.data.afterSaleInterest
          //编辑之后的图片做处理
          if (res.data.huoPic != "") {
            var fileList = res.data.huoPic.split(",")
            this.imageUrl = res.data.huoPic
            for (var i = 0; i < fileList.length; i++) {
              this.fileList.push({
                uid: '-' + i, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
                name: 'i' + i, // 文件名
                status: 'done', // 状态有：uploading done error removed
                url: fileList[i],
              })
            }
          }
          //  给富文本框赋值
          this.shoptextshow = res.data.shopText
          //变换成编辑按钮
          this.Buttontype = false
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
        })
        //首页点击修改之后传商品id进来
      }
      //一级类别
      axios.get(API + "/levelOneList"
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.options1.push({ index: i, id: res.data[i].id, value: res.data[i].id, label: res.data[i].levelOneName, children: [{}] })
        }
      }).catch(error => {
      })
    },
    //i为1时是添加规格按钮点开小窗口，i为2时是小窗口中的添加按钮
    showModal(i, index) {
      if (i == 1) {
        //控制小窗口true显示false隐藏
        this.visible = true
      } else {
        //判断是否写规格名称，写了才能添加规格
        if (this.maxtype[index].text != "") {
          this.maxtypeindex = index
          this.visiblea = true
        } else {
          this.$message.warning('请先填写规格名字')
        }

      }

    },
    invoicetypeChange() {
      if (this.invoiceInterest == 2) {
        this.Status = 0
      } else { 

      }
    },
    // 税率value1为渲染的值，Status为传入后端值
    handleChange(value) {
      if (value != "") {
        this.value1 = [value[value.length - 1]]
        this.Status = this.value1[0] * 0.01
      } else {
        this.Status = ""
      }
    },
    //发货方式选择，shop为支持物流，其他为支持发码
    huotype(value) {
      if (value.target.value == "shop") {
        // 往后端传的值
        if (value.target.checked) {
          this.shippingMode = "shop"
          this.shippingTime = "2"
          // 选择物流发货时隐藏发码的信息
          this.shippingcode = ""
          this.codestorevalue = ""
          this.codestore = false
        } else {
          this.shippingMode = ""
          this.shippingTime = "1"
        }
        //控制发货时间显示和隐藏
        this.huotime = value.target.checked
      } else {
        // 往后端传的值
        if (value.target.checked) {
          this.shippingcode = "code"
          // 选择发码时隐藏物流发货的信息
          this.shippingMode = ""
          this.shippingTime = "1"
          this.huotime = false
        } else {
          this.shippingcode = ""
        }
        this.codestore = value.target.checked
      }

    },
    //售后权益
    equitytype(value) {
      if (value.target.value == 1) {
        // alert("支持退货")
      } else {
        // alert("非质量不退货")
      }
    },
    //选择发货方式
    huoselecttypea(value) {
      if (value.target.value == 1) {
        //  立即发货
        this.shippingTime = "2"
        this.huoselecttype = false
      } else {
        this.shippingTime = ""
        this.huoselecttype = true
        // 按时间发货
      }
    },
    //多规格点击否的时候，清空多规格的数据
    WhetherNo() {
      this.stdata = []
    },
    //多规格点击是时清除单规格填写的数值
    WhetherYes() {
      this.PriceSupply = ""
      this.PriceSale = ""
      this.huoStockCounts = ""
      this.PriceCollection = ""
      this.confirmtype()
    },
    //多规格最后点击确定之后的逻辑
    confirmtype() {
      //判断如果添加时没有数据及做初始化操作this.stdata为小规格（红色），this.columns大规格（颜色）
      if (this.maxtype.length == 0) {
        this.stdata.splice(0, this.stdata.length)
        this.columns.splice(0, this.columns.length - 5)
      } else {
        //每次更改前先清除小规格然后重新获取
        this.stdata.splice(0, this.stdata.length)
        //如果有数据，即进入逻辑
        for (var i = 0; i < this.maxtype.length; i++) {
          //这是判断点击时没数据增加触发this.columns重新替换值
          if (this.maxtype.length == this.columns.length - 5) {
            this.columns.splice(i, 1, {
              dataIndex: "text" + i,
              title: this.maxtype[i].text
            })
          } else if (this.maxtype.length < this.columns.length - 5) {
            // "-------这是获取删除之后的数据---------"
            var delsum = this.columns.length - 5
            this.columns.splice(0, delsum)
            this.columns.splice(i, 0, {
              dataIndex: "text" + i,
              title: this.maxtype[i].text
            })
          } else {
            //这是添加之后的数据
            this.columns.splice(i, 0, {
              dataIndex: "text" + i,
              title: this.maxtype[i].text
            })
          }
          //这是小规格逻辑
          for (var j = 1; j < this.maxtype[i].mintext.length; j++) {
            //i为0时为第一大规格里的小规格，以此类推
            if (i == 0) {
              //获取到添加的数据text0为类型一
              this.stdata.splice(j, 0, { text0: this.maxtype[i].mintext[j].text, priceonetext: "", pricetwotext: "", pricethreetext: "", invtext: "", huotext: "" })
              //记录一下类型一的长度，后面需要     
              this.stdatasize = this.stdata.length
            } else if (i == 1) {
              if (this.stdatasize >= j) {
                // 判断类型一的长度大于等于类型二时做此操作（类型一×类型二）
                if (this.stdatasize >= this.maxtype[i].mintext.length - 1) {
                  for (var k = 0; k < this.stdatasize; k++) {
                    if (j == 1) {
                      //第一次进来时先赋值到已有的类型一中text1为类型二
                      this.stdata.splice(k, 1, { text0: this.stdata[k].text0, text1: this.maxtype[i].mintext[j].text, priceonetext: "", pricetwotext: "", pricethreetext: "", invtext: "", huotext: "" })
                    } else {
                      //其余新增类型库
                      this.stdata.push({ text0: this.stdata[k].text0, text1: this.maxtype[i].mintext[j].text, priceonetext: "", pricetwotext: "", pricethreetext: "", invtext: "", huotext: "" })
                    }
                  }
                } else {
                  //这是判断为类型2长度>类型一1的（2×1）
                  // this.stdata.splice(j - 1, 1, { text0: this.stdata[j - 1].text0, text1: this.maxtype[i].mintext[1].text, priceonetext: "", pricetwotext: "", invtext: "", huotext: "" })
                  for (var k = 0; k < this.maxtype[i].mintext.length - 1; k++) {
                    this.stdata.splice(this.stdata.length, 0, { text0: this.stdata[j - 1].text0, text1: this.maxtype[i].mintext[k + 1].text, priceonetext: "", pricetwotext: "", pricethreetext: "", invtext: "", huotext: "" })

                  }
                  // 通过判断删除掉原来小规格一添加的重新排序
                  if (j + 2 == this.maxtype[i].mintext.length) {
                    this.stdata.splice(0, j)
                  }
                }
              }

            } else {
              //这是类型三的逻辑（1×2×3）
              if (j == 1) {
                //首次进入将类型三加入到已有的规格库
                for (var k = 0; k < ((this.maxtype[i - 2].mintext.length - 1) * (this.maxtype[i - 1].mintext.length - 1)); k++) {
                  this.stdata.splice(k, 1, { text0: this.stdata[k].text0, text1: this.stdata[k].text1, text2: this.maxtype[i].mintext[j].text, priceonetext: "", pricetwotext: "", pricethreetext: "", invtext: "", huotext: "" })
                }
              } else {
                //其余新增
                for (var k = 0; k < ((this.maxtype[i - 2].mintext.length - 1) * (this.maxtype[i - 1].mintext.length - 1)); k++) {
                  this.stdata.push({ text0: this.stdata[k].text0, text1: this.stdata[k].text1, text2: this.maxtype[i].mintext[j].text, priceonetext: "", pricetwotext: "", pricethreetext: "", invtext: "", huotext: "" })
                }
              }
            }
          }
        }
      }
      this.visible = false
    },
    //控制规格弹窗1，2为确定，3，4为取消
    handleOk(value, i) {
      if (value == 1) {
        //具体在以上解释
        this.confirmtype()
      }
      if (value == 2) {
        //添加小规格，通过mintype接收到小规格中的值，然后在mintyep添加，在赋值回小规格所在的值
        this.mintype = this.maxtype[this.maxtypeindex].mintext
        this.mintype.push({ text: this.maxtype[this.maxtypeindex].minvalue })
        this.maxtype[this.maxtypeindex].mintext = this.mintype
        this.maxtype[this.maxtypeindex].minvalue = ""
        this.mintype = ''
        this.visiblea = false
      }
      if (value == 3) {

        this.visible = false
      }
      if (value == 4) {
        //清除添加小规格窗口的input的值
        this.maxtype[this.maxtypeindex].minvalue = ""
        this.visiblea = false
      }
    },
    //add为添加规格项目显示/del为×删除
    addspecification(text, del) {
      if (text == "del") {
        this.maxtype.splice(del, 1)
      } else {
        //首次新增一个数组框架
        if (this.maxtype.length == 0) {
          this.maxtype.push({ mintext: [{ text: "" }], text: "", minvalue: '' })
          //第一个填值时往下走
        } else if (this.maxtype[0].mintext.length != 1 && this.maxtype.length == 1) {
          this.maxtype.push({ mintext: [{ text: "" }], text: "", minvalue: '' })

        }
        //第二个填值时往下走
        else if (this.maxtype.length == 2 && this.maxtype[1].mintext.length != 1) {
          this.maxtype.push({ mintext: [{ text: "" }], text: "", minvalue: '' })
          //判断上一个规格信息是否完善
        }
        else {
          this.$message.warning('请完善上一个规格')
        }
        //控制小窗口显示隐藏
        this.addmaxspecification = true
      }
    },
    //核销门店
    async getstore() {
      axios.get(API + "/store", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //获取核销门店信息将它凭借成字符串形式
    storetype(text) {
      for (var i = 0; i < text.length; i++) {
        if (text.length == 1) {
          this.storename = text[i].split(",")[1]
          this.storeid = text[i].split(",")[0]
        } else {
          if (this.storename.indexOf(text[i].split(",")[1]) == -1) {
            this.storename == undefined ? this.storename = text[i].split(",")[1] :
              this.storename += "," + text[i].split(",")[1]
            this.storeid == undefined ? this.storeid = text[i].split(",")[0] :
              this.storeid += "," + text[i].split(",")[0]
          }
        }
      }
    },
    //删除
    delmintype(del1, index) {
      this.mintype = this.maxtype[index].mintext
      this.mintype.splice(del1, 1)
      this.maxtype[index].mintext = this.mintype
    },
    //统一会员价,市场价，库存
    samemenberprice() {
      for (var i = 0; i < this.stdata.length; i++) {
        //统一零售价
        if (this.alltext == "priceonetext") {
          this.stdata[i].priceonetext = this.allindex
        }//统一市场价
        if (this.alltext == "pricetwotext") {
          this.stdata[i].pricetwotext = this.allindex
          //统一集采价
        } else if (this.alltext == 'pricethreetext') {
          this.stdata[i].pricethreetext = this.allindex
          //统一库存
        } else if (this.alltext == "invtext") {
          this.stdata[i].invtext = this.allindex
        }

      }
      //初始化input值
      this.allindex = ""
      //小窗口
      this.visibleb = false
    },
    sameindex(text) {
      if (text == "priceonetext") {
        this.texttitle = "统一零售价"
      } else if (text == "pricetwotext") {
        this.texttitle = "统一供货价"
      } else if (text == "invtext") {
        this.texttitle = "统一库存"
      } else (
        this.texttitle = "统一集采价"
      )
      //统一按钮，text判断统一的值的key
      this.alltext = text
      this.visibleb = true
    },
    //上传前判断是否是这些格式
    beforeUploadMains(file, e) {
      const isJPG = file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/bmp'
      if (!isJPG) {
        this.$message.error('请上传图片文件')
      }
      //这是限制上传大小的
      const isLt2M = file.size / 1024 < 15360
      if (!isLt2M) {
        this.$message.error('文件大小应在15m以下')
      }
      return isJPG && isLt2M
    },
    //这是选择图片之后存入数据中做回显和上传
    uploadImage: function (e) {
      this.uploading = true
      this.confirmLoading = true
      // let formdata = new FormData()
      // formdata.append('uploads', e.file)
      // 将图片上传至OSS
      // 命名多样性防止重复被覆盖
      oss().put("/ouyueHuo/" + `${new Date().getTime()}` + "_" + this.$uuid.v1() + "." + e.file.name.split(".")[e.file.name.split(".").length - 1], e.file).then(res => {
        if (this.imageUrl == "") {
          this.imageUrl = res.url
        } else {
          this.imageUrl += "," + res.url
        }
        this.fileList.push({
          uid: '-' + e.file.uid, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: '' + e.file.name, // 文件名
          status: 'done', // 状态有：uploading done error removed
          url: res.url,
        })
        this.uploading = false
      })

      // axios({
      //   url: API + '/uploadFile',
      //   method: 'post',
      //   data: formdata,
      //   onUploadProgress: ({ total, loaded }) => {
      //     this.onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, e.file);
      //   },
      // }).then((res) => {
      //   if (this.imageUrl == "") {
      //     this.imageUrl = res.data.data.url
      //   } else {
      //     this.imageUrl += "," + res.data.data.url
      //   }
      //   this.fileList.push({
      //     uid: '-' + e.file.uid, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
      //     name: '' + e.file.name, // 文件名
      //     status: 'done', // 状态有：uploading done error removed
      //     url: res.data.data.url,
      //   })
      // })
    },
    // 加载上传
    onProgress({ percent }, file) {
      if (percent == 100) {
        // this.uploading = true;
        this.uploading = false
      } else {
      }
    },
    //删除图片
    removeFn(imgindex) {
      // 填写Object完整路径。Object完整路径中不能包含Bucket名称，例:upload/2023-02/b35aae063be1e198207bc8dc778.jpg
      oss().delete("ouyueHuo/" + this.imageUrl.split(",")[imgindex].split("/")[this.imageUrl.split(",")[imgindex].split("/").length - 1])
      this.$message.success('删除成功')
      this.url = ''
      // 删除指定坐标的数组中的值
      this.fileList.splice(imgindex, 1)
      // 通过把拼接的字符串转换成数组删除对应下标的值
      var imageUrl = this.imageUrl.split(",")
      imageUrl.splice(imgindex, 1)
      this.imageUrl = imageUrl.join(",")
      // 这是往后的跑的
      // axios.get(API + "/file/delete", {
      //   params: {
      //     objectName: this.imageUrl.split(",")[imgindex]
      //   }
      // }
      // ).then(res => {
      //   // 删除指定坐标的数组中的值
      //   this.fileList.splice(imgindex, 1)
      //   // 通过把拼接的字符串转换成数组删除对应下标的值
      //   var imageUrl = this.imageUrl.split(",")
      //   imageUrl.splice(imgindex, 1)
      //   this.imageUrl = imageUrl.join(",")
      // }).catch(error => {
      //   if(error!="520"){
      //    this.$message.error("后端接口连接异常")
      //   }
      // })
    },
    //图片拖拽之后进行排序新下标的数组和就下标的数组替换
    change(evt) {
      // 转换图片信息
      var oldfile = this.fileList[evt.moved.newIndex]
      var newfile = this.fileList[evt.moved.oldIndex]
      this.fileList.splice(evt.moved.newIndex, 1, oldfile)
      this.fileList.splice(evt.moved.oldIndex, 1, newfile)
      // 转换拼装的图片字符串
      var imageUrl = this.imageUrl.split(",")
      var oldfileurl = imageUrl[evt.moved.newIndex]
      var newfileurl = imageUrl[evt.moved.oldIndex]
      imageUrl.splice(evt.moved.newIndex, 1, newfileurl)
      imageUrl.splice(evt.moved.oldIndex, 1, oldfileurl)
      this.imageUrl = imageUrl.join(",")
    },
    // 商品详情
    wangEditorChange(val) {
      this.shoptext = val
    },
    //按钮点击确定时做多规格处理
    allstdata() {
      if (this.config.visibleFlag == "WhetherYes") {
        // 多规格进入此做数据操作
        //做初始化数值this.PriceSupply为供货价，this.PriceSale零售价，this.huoStockCounts库存，this.number货号，
        this.PriceSupply = 0
        this.PriceSale = 0
        this.PriceCollection = 0
        this.huoStockCounts = 0

        for (var i = 0; i < this.stdata.length - 1; i++) {

          //价格做最小值处理，库存做增加处理
          this.PriceSale = this.minmath(this.stdata[i].priceonetext, this.stdata[i + 1].priceonetext, this.PriceSale)
          this.PriceSupply = this.minmath(this.stdata[i].pricetwotext, this.stdata[i + 1].pricetwotext, this.PriceSupply)
          this.PriceCollection = this.minmath(this.stdata[i].pricethreetext, this.stdata[i + 1].pricethreetext, this.PriceCollection)
          this.huoStockCounts += Number(this.stdata[i].invtext)
          //把小规格和所带的属性窜成一组字符串，方便日后调用
          //这是每个组中的类，text0为第一大类的一个小类
          var stdata = this.stdata[i].text0 + "?" + this.stdata[i].text1 + "?" + this.stdata[i].text2
          //每个类组所带的值
          var allstdata = stdata
            + "?" + this.stdata[i].priceonetext + "?" + this.stdata[i].pricetwotext
            + "?" + this.stdata[i].invtext + "?" + this.stdata[i].huotext + "?" + this.stdata[i].pricethreetext
          var stdatamin = this.stdatamin == "" ? allstdata : "," + allstdata
          this.stdatamin += stdatamin
        }
        // 最后循环少一组加上endlenght为最后一组下标
        var endlenght = this.stdata.length - 1
        var endstdata = this.stdata[endlenght].text0 + "?" + this.stdata[endlenght].text1 + "?" + this.stdata[endlenght].text2
          + "?" + this.stdata[endlenght].priceonetext + "?" + this.stdata[endlenght].pricetwotext
          + "?" + this.stdata[endlenght].invtext + "?" + this.stdata[endlenght].huotext + "?" + this.stdata[endlenght].pricethreetext
        var stdatamin = this.stdatamin == "" ? endstdata : "," + endstdata
        this.stdatamin += stdatamin
        //库存做添加操作，价格取最小
        this.huoStockCounts += Number(this.stdata[this.stdata.length - 1].invtext)
        //做判断如果多规格只有一行规格则直接取
        if (this.stdata.length == 1) {
          this.PriceSale = this.stdata[0].priceonetext
          this.PriceSupply = this.stdata[0].pricetwotext
          this.PriceCollection = this.stdata[0].pricethreetext
        }
        this.number = this.stdata[0].huotext
        this.specification = 2
        // 处理多规格分解成大规格，和小规格的参数
        for (var i = 0; i < this.columns.length - 5; i++) {
          var stdatamax = this.stdatamax == "" ? this.columns[i].title : "," + this.columns[i].title
          this.stdatamax += stdatamax
        }
      }
    },
    //这是修改按钮
    updateshop(state) {
      // 判断此处为草稿则无限制储存
      if (state == 1) {
        this.$message.loading("提交中")
        this.loading = true
        //此处做多规格操作
        this.allstdata()
        //上传商品信息
        this.updateajax(this.shoptypeone, this.shoptypetwo, this.shopname, this.number, this.PriceSupply,
          this.PriceSale, this.huoStockCounts, this.imageUrl, state, this.Status, this.specification, this.$route.query.shopinformation)
      } else {
        if (this.config.visibleFlag == "WhetherYes") {
          this.stdatavalue = true
          for (var i = 0; i < this.stdata.length; i++) {
            // 判断每个规格的供货价，零售价和库存是否为空,为空返回false，修改失败
            if (this.stdata[i].invtext == "" || this.stdata[i].pricetwotext == "" || this.stdata[i].priceonetext == ""
              || this.stdata[i].invtext == undefined || this.stdata[i].pricetwotext == undefined || this.stdata[i].priceonetext == undefined
              //  做判断是否有空格
              || /\s/g.test(this.stdata[i].invtext) || /\s/g.test(this.stdata[i].pricetwotext) || /\s/g.test(this.stdata[i].priceonetext)) {
              this.stdatavalue = false
            }
          }
        }
        if (this.shoptypeone != "" && this.shoptypetwo != "" && this.shopname != "" && this.fileList != ""
          && this.Status !== "" && this.specification != "" && (this.shippingMode != "" || this.shippingcode != "")
          && this.commodity != "" && this.shoptext != "" &&
          ((this.PriceSupply != "" && this.PriceSale != "" && this.huoStockCounts != "") ||
            (this.stdata != "" && this.stdatavalue))
        ) {
          this.$message.loading("提交中")
          this.loading = true
          //此处做多规格操作
          this.allstdata()

          //上传商品信息
          this.updateajax(this.shoptypeone, this.shoptypetwo, this.shopname, this.number, this.PriceSupply,
            this.PriceSale, this.huoStockCounts, this.imageUrl, state, this.Status, this.specification, this.$route.query.shopinformation)

        } else {
          this.$message.warning('还有必要信息未填写')
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.AcascaderMenu .ant-cascader-menu {
  min-height: 500px;
}

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.span {
  font-size: 16px;
}


.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}


.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/* // 因为我们用的ant的组件的class，会有些默认样式要去掉，如果样式是你自己写的，那就不需要这块了
  //这个样式特别重要，否则偶尔会出现不显示的bug */
.ant-upload-list {
  display: block !important
}
</style>

