<template>
  <div class="header">
    <a-space direction="vertical" :size="20">
      <a-space style="padding: 20px 0 0 5%;" :size="size">
        <span><span style="color: red;">*</span>手机号：</span>
        <a-input placeholder="请输入账号" style="width: 400px;" v-model:value="username" :disabled="buttontype" />
      </a-space>

      <a-space style="padding: 20px 0 0 7%;" :size="size">
        <span><span style="color: red;">*</span>密码：</span>
        <a-input-password placeholder="请输入密码" style="width: 400px;" v-model:value="password" />
      </a-space>

      <a-space style="padding: 20px 0 0 0;" :size="size">
        <span><span style="color: red;">*</span>再输入密码：</span>
        <a-input-password placeholder="请输入密码" style="width: 400px;" v-model:value="yespassword" />
      </a-space>

      <a-space style="padding: 20px 0 0 7%;" :size="size">
        <span><span style="color: red;">*</span>姓名：</span>
        <a-input placeholder="请输入姓名" style="width: 400px;" v-model:value="name" />
      </a-space>

      <a-space :size="size" style="padding: 20px 0 0 2%;width: 600px;white-space: nowrap;">
        <span><span style="color: red;">*</span>归属门店：</span>
        <a-space :size="120">

          <a-checkbox-group v-model:value="shippingMode" @change="huotype">
            <a-checkbox :value="data.storeId + ',' + data.storeName" style="flex-wrap: wrap;"
              v-for="data in dataSource">{{ data.storeName }}</a-checkbox>
          </a-checkbox-group>
        </a-space>
      </a-space>

      <a-space :size="size" style="padding: 10px 0 0 7%;">
        <span><span style="color: red;">*</span>状态：</span>
        <a-radio-group>
          <a-space :size="120">
            <a-radio-group v-model:value="verificationstate" @change="equitytype">
              <a-radio value="1">启用</a-radio>
              <a-radio value="2">禁用</a-radio>
            </a-radio-group>
          </a-space>
        </a-radio-group>
      </a-space>

      <a-space style="display: flex;padding: 10px 0 0 7%;">
        <a-button size="large" @click="reset">重置</a-button>
        <a-popconfirm title="确定建立吗?" ok-text="确定" cancel-text="取消" @confirm="yusok" @cancel="cancel">
          <a-button type="primary" v-if="!buttontype" size="large">立即建立</a-button>
        </a-popconfirm>
        <a-popconfirm title="确定修改吗?" ok-text="确定" cancel-text="取消" @confirm="updateok" @cancel="cancel">
          <a-button type="primary" v-if="buttontype" size="large">立即修改</a-button>
        </a-popconfirm>
      </a-space>

    </a-space>
  </div>
</template>
 
<script>
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  data () {
    return {
      username: "",
      password: "",
      yespassword: "",
      dataSource: [],
      shippingMode: [],
      cancelid: 0,
      name: "",
      buttontype: false,
      verificationstate: "1"
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    //获取菜单的数据
    async getMenuList () {
      if (this.$route.query.cancelinformation != undefined) {
        //修改按钮样式
        this.buttontype = true
        //获得编辑页面传来的数值
        var cancel = JSON.parse(this.$route.query.cancelinformation)
        this.cancelid = cancel.id
        this.username = cancel.verificationUsername
        this.password = cancel.verificationPassword
        this.yespassword = cancel.verificationPassword
        this.name = cancel.verificationOfficerName
        this.verificationstate = cancel.verificationStatus
        this.storename = cancel.storeName
        this.storeid = cancel.storeId
        for (var i = 0; i < cancel.storeId.split(",").length; i++) {
          this.shippingMode.splice(0, 0, cancel.storeId.split(",")[i] + "," + cancel.storeName.split(",")[i])
        }
      }
      axios.get(API + "/store", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //重置按钮，初始化数值
    reset () {
      this.username = ""
      this.password = ""
      this.yespassword = ""
      this.verificationstate = "1"
      this.shippingMode = []
      this.storename = undefined
      this.storeid = undefined
    },
    //获取归属门店信息将它id和名称拼接成字符串形式
    huotype (text) {
      if (text.length != 0) {
        this.shippingMode = [text[text.length - 1]]
        this.storename = this.shippingMode[0].split(",")[1]
        this.storeid = this.shippingMode[0].split(",")[0]
      } else {
        this.storename = undefined
        this.storeid = undefined
      }
    },
    //确认提交
    yusok () {
      //  判断填的信息是否为空
      if (this.username != "" && this.yespassword != "" && this.name != '' && this.verificationstate != ""
        && this.storename != undefined && this.storeid != undefined) {
        if (this.password == this.yespassword) {
          axios.get(API + "/addVerification", {
            params: {
              userName: this.username,
              password: this.yespassword,
              name: this.name,
              state: this.verificationstate,
              storeName: this.storename,
              storeId: this.storeid,
              supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
            }
          }).then((res) => {
            if (res.data) {
              this.$message.success("提交成功")
              this.$router.push('/CancelManagement')
            } else {
              this.$message.error("该账户已被注册")
            }

          }).catch(error => {
            if (error != "520") {
              this.$message.error("后端接口连接异常")
            }
          })
        } else {
          this.$message.error("两次输入密码不正确")
        }
      } else {
        this.$message.error("信息未填写完整")
      }
    },
    //修改按钮
    updateok () {
      if (this.yespassword != "" && this.name != '' && this.verificationstate != ""
        && this.storename != undefined && this.storeid != undefined) {
        if (this.password == this.yespassword) {
          axios.get(API + "/updateVerification", {
            params: {
              cancelId: this.cancelid,
              password: this.yespassword,
              name: this.name,
              state: this.verificationstate,
              storeName: this.storename,
              storeId: this.storeid
            }
          }).then((res) => {
            this.$message.success("修改成功")
            this.$router.push('/CancelManagement')
          }).catch(error => {
            if (error != "520") {
              this.$message.error("后端接口连接异常")
            }
          })
        } else {
          this.$message.error("两次输入密码不正确")
        }
      } else {
        this.$message.error("信息未填写完整")
      }
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
.header {
  padding: 18px 48px 0 400px;
}
</style>

