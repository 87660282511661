<template>
  <div class="headerac">
    <a-space direction="vertical">
      <h2>核销员登录</h2>
      <a-space direction="vertical">
        <a-input class="inputBox" v-model:value="form.username" placeholder="请输入手机号">
          <template #prefix>
            <user-outlined type="user" />
          </template>
        </a-input>
        <a-input-password class="inputBox" v-model:value="form.password" placeholder="请输入密码">
          <template #prefix>
            <lock-outlined />
          </template>
        </a-input-password>
      </a-space>
      <a-space direction="vertical" style="padding: 120px 0 0 0;">
        <a-button type="primary" style="width: 270px;" @click="login" :loading="loading">{{ loading ? "登录中" : "登录"
        }}</a-button>
      </a-space>
    </a-space>
  </div>
</template>
  
<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      storerelation: "",
      value: "small",
      valuetime: "",
      loading: false,
      form: {
        username: "",
        password: "",
        show: true,
        count: '',
        timer: null,
      },
    }

  },
  created () {

  },
  methods: {
    login () {
      //登录中加载
      this.loading = true
      axios.get(API + "/PhoneLogin", {

        params: {
          userName: this.form.username,
          password: this.form.password
        }
      }
      ).then(res => {
        if (res.data.verificationStatus == "2") {
          this.$message.error("该账户已被禁用")
          // 加载取消
          this.loading = false
        } else {
          if (res.data != "") {
            this.$message.success("登录成功")
            sessionStorage.setItem("cancel_info", JSON.stringify(res.data))
            this.$router.push('/PhoneCancel')
          } else {
            this.$message.error("密码/账户错误")
            // 加载取消
            this.loading = false
          }
        }

      }).catch(error => {
        this.$message.error("后端接口连接异常")
      })
    },
    //获取菜单的数据
    async getMenuList () {

    },
  },

}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.headerac {
  text-align: center;
  padding: 120px 0 0 0;
}
</style>
  
  