<template>
  <div class="header">

    <a-space>
      <a-row type="flex" style="display: flex;flex-wrap: wrap;">
        <a-col flex="1 1 200px">
          <a-space>
            <span>门店名称：</span>
            <a-input placeholder="请输入门店名称" v-model:value="storename" />
            <a-button type="primary" @click="seekname">查询</a-button>
          </a-space>
        </a-col>
        <a-col flex="0 1 300px" style="position: absolute;right: 6%;">
          <a-button type="primary" @click="addstore">新建门店</a-button>
        </a-col>
      </a-row>
    </a-space>

    <a-table style="padding-top: 20px;width:99%;text-align: center;font-size: 14px;" :columns="columns"
      :data-source="dataSource" size="middle" :pagination="pagination" @change="tablePaginationChange">
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>

      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex == 'index'">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-if="column.dataIndex == 'storeAddress'">
          <span>{{ record.storeAddressProvince }}{{ record.storeAddressCity }}
            {{ record.storeAddressArea }}{{ record.storeAddress }}</span>
        </template>
        <template v-if="column.dataIndex == 'controller'">
          <a-space>
            <a style="font-size: 12px;" @click="updatestore(record)">编辑</a>
            <a-popconfirm title="确定删除吗?" ok-text="确定" cancel-text="取消" @confirm="delstore(record.storeId)">
              <a style="font-size: 12px;">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </a-table>

  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      storename: "",
      width: 0,
      dataSource: [{
        name: '1'
      }],
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // 搜索页数回车跳转
        showQuickJumper: true,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 4 // 每页条数，所有页设置统一的条数
      },
      columns: [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
      }, {
        title: '门店名称',
        dataIndex: 'storeName',
        key: 'storeName',
      }, {
        title: '关联商品数量',
        dataIndex: 'relatedCommodityQuantity',
        key: 'relatedCommodityQuantity',
      }, {
        title: '门店地址',
        dataIndex: 'storeAddress',
        key: 'storeAddress',
      }, {
        title: '门店电话',
        dataIndex: 'storePhone',
        key: 'storePhone',
      }, {
        title: '操作',
        dataIndex: 'controller',
        key: 'controller',
      },]
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    handleResize () {
      this.width = this.$el.clientWidth  // 更新宽度
    },//获取当前页面大小

    handleMenuClick () {

    },
    //获取菜单的数据
    async getMenuList () {
      axios.get(API + "/store", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //添加门店页面
    addstore () {
      this.$router.push('/addstore')
    },
    // 删除门店 id门店id
    delstore (id) {
      axios.get(API + "/delStore", {
        params: {
          id: id
        }
      }).then(res => {
        this.getMenuList()
        this.$message.success("删除成功")
      }).catch(error => {
      })


    },
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页
    //搜索栏查询名称
    seekname () {
      axios.get(API + "/store", {
        params: {
          storeName: this.storename,
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
      })
    },
    //修改id中的门店
    updatestore (store) {
      this.$router.push({
        path: '/addstore', query: { storeinformation: JSON.stringify(store) }
      })
    }
  },

  mounted () {
    this.width = this.$el.clientWidth  // 获取父元素的宽度
    window.addEventListener('resize', this.handleResize)  // 监听窗口大小变化
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)  // 取消监听
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
  padding: 18px 0 0 48px;
}
</style>

