<template>
  <a-layout>
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div
        :style="collapsed ? 'background-color: #ffffff;border-top: #fff solid 20px;padding-left: 29%;border-right: #F0F0F0 solid 1px;' : 'background-color: #ffffff;border-top: #fff solid 20px;padding-left: 10%;border-right: #F0F0F0 solid 1px;'">
        <a-space>
          <img style="width: 20px;" alt="example" src="../assets/logo.png" />
          <span style="font-size: 17px;font-weight: bold;margin-top: 15px;" v-if="!collapsed">瓯越通供应链</span>
        </a-space>
      </div>
      <a-modal :open="visible" title="提示！" @ok="handleOk" @cancel="visible = false" ok-text="修改密码" cancel-text="取消">
        <span>初始密码过于简单，请点击修改密码前往修改</span>
      </a-modal>
      <a-modal :open="visible1" title="提示！" @ok="overOk" @cancel="visible1 = false" ok-text="确定" cancel-text="取消">
        <span>确定退出吗</span>
      </a-modal>
      <a-menu mode="inline" style="height: 100%;font-size: 15px" :openKeys="openKeys" v-model:selectedKeys="selectedKeys"
        @openChange="onOpenChange">
        <a-sub-menu key="sub1">
          <template #title>
            <appstore-outlined />
            <span>
              商品管理
            </span>
          </template>
          <a-menu-item key="1" @click="shopmanage(2)">
            <FormOutlined />
            商品录入
          </a-menu-item>
          <a-menu-item key="2" @click="shopmanage(1)">
            <WalletOutlined />
            商品库
          </a-menu-item>
          <a-menu-item key="3" @click="store()">
            <BankOutlined />
            门店管理
          </a-menu-item>
          <a-menu-item key="4" @click="cancel(1)">
            <SolutionOutlined />
            核销管理
          </a-menu-item>
          <a-menu-item key="5" @click="area()">
            <EnvironmentOutlined />
            区域限制
          </a-menu-item>
          <a-menu-item key="7" @click="audit()">
            <user-outlined />
            审核记录
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub2">
          <template #title>
            <profile-outlined />
            <span>
              订单管理
            </span>
          </template>
          <a-menu-item key="6" @click="orderlist(1)">
            <FileSearchOutlined />
            订单列表
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub3">
          <template #title>
            <SettingOutlined />
            <span>
              设置
            </span>
          </template>
          <a-menu-item key="12" @click="handleMenuClick({ key: '2' })">
            <EditOutlined />
            修改密码
          </a-menu-item>
          <a-menu-item key="11" @click="returnaddress()">
            <AimOutlined />
            退货地址设置
          </a-menu-item>
        </a-sub-menu>
      </a-menu>

    </a-layout-sider>
    <a-layout style="display: flex;">

      <a-layout-header style="background: #fff; padding: 0;display: flex;">
        <div>
          <menu-unfold-outlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
          <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
        </div>
        <div style="position: absolute;right: 0;">
          <ul class="layui-nav right" lay-filter="">
            <li class="layui-nav-item">
              <a-dropdown>
                <template #overlay>
                  <a-menu @click="handleMenuClick">
                    <a-menu-item key="1">
                      切换用户
                    </a-menu-item>
                    <a-menu-item key="2">
                      修改密码
                    </a-menu-item>
                    <a-menu-item key="3">
                      退出登录
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button type="link" size="large" style="color: #000000;text-align: right;padding-right: 25px ;">
                  {{ suppliername }}
                  <DownOutlined />
                </a-button>
              </a-dropdown>
            </li>
          </ul>
        </div>

      </a-layout-header>
      <a-layout-content
        :style="{ margin: '2px 0 0 0', padding: '5px 5px 5px 5px', background: '#fff', minHeight: '280px' }">
        <!-- <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view> -->
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
export default {
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup() {
    

    return {
      SearchOutlined,
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  data() {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      page: 'shop1',
      rootSubmenuKeys: ['sub1', 'sub2', "sub3"],
      openKeys: ['sub1'],
      selectedKeys: ["2"],
      collapsed: false,
      qwe: "sub1",
      suppliername: "",
      visible: false,
      visible1: false
    }

  },
  created() {
    this.getMenuList()
  },
  methods: {
    //侧边栏商品管理
    shopmanage(i) {
      if (i == 1) {
        this.$router.push('/ShopManage')
      } else if (i == 2) {
        this.$router.push({
          path: '/AddShop', query: { visibleFlag: 'WhetherNo' }
        })
      } else {
        this.$router.push({
          path: '/AddShop', query: { visibleFlag: 'WhetherYes' }
        })
      }
    },
    //侧边栏订单管理
    orderlist(i) {
      if (i == 1) {
        this.$router.push({
          path: '/OrderList', query: { selectorder: "all" }
        })
      } else if (i == 2) {
        this.$router.push({
          path: '/OrderList', query: { selectorder: "sale" }
        })
      } else if (i == 3) {
        this.$router.push('/AfterSaleDetails')
      } else {
        this.$router.push('/AfterSaleProcess')
      }
    },
    //侧边栏审核记录
    audit() {
      this.$router.push('/AuditLog')
    },
    //侧边栏门店管理
    store() {
      this.$router.push('/StoreManagement')
    },
    //核销管理
    cancel(i) {
      if (i == 1) {
        this.$router.push('/CancelManagement')
      } else if (i == 2) {
        this.$router.push('/PhoneCancel')
      } else {
        this.$router.push('/CancelLog')
      }
    },
    //区域限制
    area() {
      this.$router.push('/AreaManagemenet')
    },

    //退货地址设置
    returnaddress() {
      this.$router.push('/ReturnAddress')
    },
    //首次进入获取菜单的数据
    async getMenuList() {
      // 获取用户姓名
      this.suppliername = JSON.parse(sessionStorage.getItem("user_info")).operatorName
      // 判断用户密码是否简单
      if (JSON.parse(sessionStorage.getItem("user_info")).password == "123456") {
        this.visible = true
      }
    },
    //这是控制侧边栏的
    onOpenChange(value) {
      const latestOpenKey = value.find(key => this.openKeys.indexOf(key) == -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) != -1) {
        //  进来为开启
        this.openKeys = [value[value.length - 1]]
      } else {
        //  进来为关闭
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    // 这是跳转到修改或者登录页面（切换账户密码）
    handleMenuClick(key) {
      if (key.key == '1') {
        this.visible1 = true
      } else if (key.key == '2') {
        this.$router.push('/ChangePassWord')
      } else {
        this.visible1 = true
      }
    },
    // 退出账户
    overOk() {
      // 清除用户信息
      sessionStorage.removeItem("user_info")
      sessionStorage.removeItem("token")
      this.visible1 = false
      this.$router.push('/login')
    },
    // 跳转到修改密码
    handleOk() {
      this.$router.push('/ChangePassWord')
      this.visible = false
    },
    //退出提示
    handleBeforeUnload(event) {
      // 兼容火狐的做法
      event.preventDefault()
      event.returnValue = ''
      // 展示提示消息 
      // （这里其实修改默认提示语是不生效的，不过我的业务场景不需要修改默认提示语
      // 我也没找到能修改的方法，有大佬知道的话麻烦告知）
      const message = '确定要离开吗？'
      event.returnValue = message
      return message
    },
  },
  // 退出提示
  mounted() {
    //window.addEventListener('beforeunload', this.handleBeforeUnload)
    //window.addEventListener('unload', this.handleBeforeUnload)
  },
  unmounted() {
    //window.removeEventListener('beforeunload', this.handleBeforeUnload)
    //window.removeEventListener('unload', this.handleBeforeUnload)
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.trigger {

  font-size: 13px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;

}

.trigger:hover {
  color: #1890ff;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
</style>

