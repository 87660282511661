<template>
  <div class="header">
    <a-space direction="vertical" :size="50">
      <h2>物流信息</h2>
      <a-space :size="50" style="padding-left: 40px;">
        <a-card size="small" title="快递公司" style="width: 550px">
          <p>{{ logisticsCompany }}</p>
        </a-card>
        <a-card size="small" title="运单号" style="width: 550px">
          <p>{{ logisticsNumber }}</p>
        </a-card>
      </a-space>
    </a-space>
    <a-list style="padding-top: 50px;" item-layout="horizontal" :data-source="data">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta :description="item.acceptTime">
            <template #title>
              <span>{{ item.acceptStation }}</span>
            </template>
            <template #avatar>
              .
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>
  
<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      logisticsCompany: "",
      logisticsNumber: "",
      data: [
        {
          title: '"您的快件已经到达【深圳转运中心公司】"',
          text: "888"
        },
        {
          title: '"您的快件被【广东省深圳市龙岗区园山】揽收，揽收人: 王亚红 (15019231357)"',
          text: "9999"
        },
        {
          title: '"您的快件离开【广东省深圳市龙岗区园山】，已发往【深圳转运中心公司】"',
          text: "99999"
        },
        {
          title: '"您的快件离开【深圳转运中心】，已发往【哈尔滨转运中心公司】"',
          text: "0000"
        },
      ]
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    handleMenuClick () {

    },
    //获取菜单的数据
    async getMenuList () {
      // 查询的订单物流
      var order = JSON.parse(this.$route.query.order)
      //  物流公司和运单号
      axios.get(API + "/sysExpress", {
        params: {
          expressCode: order.logisticsCompany,
        }
      }
      ).then(res => {
        console.log(res.data)
        this.logisticsCompany = res.data.expressName
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
      // 将运单号渲染到页面上
      this.logisticsNumber = order.logisticsNumber
      //  物流信息
      axios.get(API + "/getLogisticBy", {
        params: {
          shipperCode: order.logisticsCompany,
          logisticCode: order.logisticsNumber
        }
      }
      ).then(res => {
        this.data = res.data.traces.reverse()
      }).catch(error => {
        this.$message.error("此运单号无物流信息")
      })
    },
  },

}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
  padding: 18px 0 0 48px;
}
</style>
  
  