//axiosInstance.js
//导入axios
// import axios from 'axios'

//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
// const API = 'http://localhost:9003' //dev
// const API = 'http://test.apis.ouyuetech.com/supply'
const API = 'https://apis.ouyuetech.com/supply'
// const API = '/api'
// const API = process.env.VUE_APP_BASE_API
//导出我们建立的axios实例模块，ES6 export用法
export default API
