<template>
  <div class="header">

    <a-space direction="vertical" :size="20">
      <a-space>
        <span><span style="color: red;">*</span>门店名称：</span>
        <a-input placeholder="请输入门店名称" v-model:value="storeName" />
      </a-space>

      <!-- <a-space>
                <span><span style="color: red;">*</span>门店分店：</span>
                <a-input placeholder="单行输入" v-model:value="subbrance" />
            </a-space> -->

      <a-space>
        <span><span style="color: red;">*</span>门店主图：</span>
        <div v-if="fileList != []">
          <a-image :src="fileList[0].url" style="width: 100px" alt="avatar" v-show="!uploading" />
          <a-space>
            <CloseCircleOutlined @click="removeFn(index)" v-show="!uploading" />
          </a-space>
        </div>
        <a-upload v-else :fileList="[]" :multiple="true" list-type="picture-card" :customRequest="uploadImage"
          :on-progress="handleProgress" :before-upload="beforeUpload">
          <div>
            <loading-outlined v-if="loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        <div v-show="uploading">
          上传中 {{ percent }}%
          <LoadingOutlined />
        </div>
      </a-space>

      <a-space direction="vertical">
        <a-space>
          <span><span style="color: red;">*</span>门店地址：</span>
          <span>省份：</span>
          <a-select :default-value="province" placeholder="请选择省" :options="provinces" @change="handleChange"
            class="selectCss" ref="provinceSelect" v-model:value="provinceName">
          </a-select>
          <span>市区：</span>
          <a-select :default-value="city" placeholder="请选择市" :options="cities" @change="handleChange2" class="selectCss"
            ref="citySelect" v-model:value="cityName">
          </a-select>
          <span>区县：</span>
          <a-select :default-value="area" placeholder="请选择区县" :options="areas" @change="handleChange3" class="selectCss"
            ref="areaSelect" v-model:value="areaName"></a-select>
        </a-space>
        <a-space>
          <a-input placeholder="请输入详细地址" v-model:value="twon" @change="getLocations(0)" />
        </a-space>
      </a-space>

      <a-space>
        <span><span style="color: red;">*</span>精准定位：</span>
        <div id="amp-container" style="width:500px;height:400px;"></div>
      </a-space>

      <a-space>
        <span><span style="color: red;">*</span>门店电话：</span>
        <a-space direction="vertical">
          <a-space>
            <a-input placeholder="请输入电话" v-model:value="phone1number" />
            <a-button @click="mostphone">添加</a-button>
          </a-space>
          <a-space v-if="phone2">
            <a-input placeholder="请输入电话" v-model:value="phone2number" />
            <a-button @click="delphone(2)">删除</a-button>
          </a-space>
          <a-space v-if="phone3">
            <a-input placeholder="请输入电话" v-model:value="phone3number" />
            <a-button @click="delphone(3)">删除</a-button>
          </a-space>
        </a-space>
      </a-space>

      <a-space style="padding: 30px 0 0 50%;">
        <a-popconfirm title="确定保存吗?" ok-text="确定" cancel-text="取消" @confirm="submit()" @cancel="cancel"
          v-if="!buttontype">
          <a-button style="width: 180px;text-align: center;height: 30px;">保存</a-button>
        </a-popconfirm>
        <a-popconfirm title="确定修改吗?" ok-text="确定" cancel-text="取消" @confirm="updatesubmit()" @cancel="cancel"
          v-if="buttontype">
          <a-button style="width: 180px;text-align: center;height: 30px;">保存</a-button>
        </a-popconfirm>
      </a-space>
    </a-space>
  </div>
</template>
  
<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import oss from "@/utils/ossutils.js"
import { toRaw } from "@vue/reactivity"
import pcaa from 'china-area-data'
export default {
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      dataSource: [{
        name: '1'
      }],
      provinces: [],
      province: undefined,
      city: undefined,
      area: undefined,
      provinceName: undefined,
      cityName: undefined,
      areaName: undefined,
      provinceCode: "",
      cityCode: "",
      areaCode: "",
      cities: [],
      areas: [],
      twon: "",
      fileList: "",
      buttontype: false,
      phone2: false,
      phone3: false,
      phone1number: "",
      subbrance: "",
      storeId: undefined,
      phone2number: "",
      phone3number: "",
      storeName: "",
      longitude: "",
      latitude: "",
      percent: 0,
      uploading: false,
      phone: "",
      map: {},
      markerLayers: {}
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    handleResize () {
      this.width = this.$el.clientWidth  // 更新宽度

    },//获取当前页面大小

    handleMenuClick () {

    },
    //获取菜单的数据
    async getMenuList () {
      //编辑进来给赋值
      if (this.$route.query.storeinformation != undefined) {
        //修改按钮样式
        this.buttontype = true
        //获得编辑页面传来的数值
        var store = JSON.parse(this.$route.query.storeinformation)
        // 门店名称
        this.storeName = store.storeName
        // 门店电话
        this.phone1number = store.storePhone
        // 门店id
        this.storeId = store.storeId
        //    省份代码和昵称
        this.province = store.storeAddressProvince
        this.provinceCode = store.addressProvinceCode
        // 市代码和昵称
        this.getCity(store.addressProvinceCode)
        this.city = store.storeAddressCity
        this.cityCode = store.addressCityCode
        // 区代码和昵称
        this.getArea(store.addressCityCode)
        this.area = store.storeAddressArea
        this.areaCode = store.addressAreaCode
        // 详细地址
        this.twon = store.storeAddress
        // 门店图片
        this.fileList = [{
          url: store.storePic,
          file: ""
        }]
        // 精准定位
        this.getLocations(store.storeAddressProvince + store.storeAddressCity + store.storeAddressArea + store.storeAddress)
        //多电话时的操作
        if (store.storePhone.indexOf(",") != -1) {
          var phonelist = store.storePhone.split(",")
          for (var i = 0; i < phonelist.length; i++) {
            if (i == 0) {
              this.phone1number = phonelist[0]
            } else if (i == 1) {
              this.phone2number = phonelist[1]
              this.phone2 = true
            } else {
              this.phone3number = phonelist[2]
              this.phone3 = true
            }
          }
        }
      }
      let arr = []
      const province = pcaa['86']
      Object.keys(province).map(key => {
        arr.push({
          value: key,
          label: province[key],
        })
      })
      this.provinces = arr

    },
    // 获取城市
    getCity (province) {
      let arr = []
      const city = pcaa[province]
      Object.keys(city).map(key => {
        arr.push({
          value: key,
          label: city[key],
        })
      })
      this.cities = arr
      return arr
    },
    // 获取区县
    getArea (city) {
      let arr = []
      const area = pcaa[city]
      Object.keys(area).map(key => {
        arr.push({
          value: key,
          label: area[key],
        })
      })
      this.areas = arr
      return arr
    },
    // 根据选择的省，更新市的下拉选项
    handleChange (value, label) {
      // 记录省份名称和代码
      this.provinceName = label.label
      this.provinceCode = label.value
      this.getCity(value)
    },
    // 选择市
    handleChange2 (value, label) {
      // 记录市份名称和代码
      this.cityName = label.label
      this.cityCode = label.value
      this.getArea(value)
    },
    // 选择区
    handleChange3 (value, label) {
      // 记录区份名称和代码
      this.areaName = label.label
      this.areaCode = label.value
      this.getLocations(this.provinceName + this.cityName + label.label)
    },
    //上传前判断是否是这些格式
    beforeUploadMains (file, e) {
      const isJPG = file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/bmp'
      if (!isJPG) {
        this.$message.error('请上传图片文件')
      }
      //这是限制上传大小的
      const isLt2M = file.size / 1024 < 15360
      if (!isLt2M) {
        this.$message.error('文件大小应在15m以下')
      }
      return isJPG && isLt2M
    },
    //删除图片
    removeFn (imgindex) {
      oss().delete("/ouyueHuo/" + this.fileList[0].url.split("/")[this.fileList[0].url.split("/").length - 1])
      this.fileList = ""
      this.$message.success('删除成功')
      // axios.get(API + "/file/delete", {
      //     params: {
      //         objectName: this.fileList[0].url
      //     }
      // }
      // ).then(res => {
      //     this.fileList = ""
      // })
    },
    //上传图片后
    uploadImage: function (e) {
      this.uploading = true
      this.confirmLoading = true
      let formdata = new FormData()
      formdata.append('file', e.file)
      // 将图片上传至OSS
      // 命名多样性防止重复被覆盖
      oss().put("/ouyueHuo/" + `${new Date().getTime()}` + "_" + this.$uuid.v4() + "." + e.file.name.split(".")[e.file.name.split(".").length - 1], e.file).then(res => {
        this.fileList = [{
          uid: '-' + e.file.uid, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: '' + e.file.name, // 文件名
          status: 'done', // 状态有：uploading done error removed
          url: res.url,
        }]
        this.uploading = false
      })
      // axios({
      //     url: API + '/uploadFile',
      //     method: 'post',
      //     data: formdata,
      //     onUploadProgress: ({ total, loaded }) => {
      //         this.onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, e.file);
      //     },
      // }).then((res) => {
      //     this.fileList = [{
      //         uid: '-' + e.file.uid, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
      //         name: '' + e.file.name, // 文件名
      //         status: 'uploading', // 状态有：uploading done error removed
      //         url: res.data.data.url,
      //     }]

      // })
    },
    // 加载上传
    // onProgress({ percent }, file) {
    //     if (percent == 100) {
    //         this.uploading = false;
    //     } else {
    //         this.uploading = true;
    //     }
    // },
    // handleProgress(event, file, fileList) {

    // },
    //提交按钮
    submit () {
      //判断数据是否填写完整 storeName 门店名称 ，areaName区县，phone1number 第一个手机号  subbrance门店分店
      if (this.storeName != "" && this.areaName != "" && this.phone1number != "" && this.fileList != ""
      ) {
        //多个电话拼接
        if (this.phone3number != "") {
          this.phone1number += "," + this.phone2number
          this.phone1number += "," + this.phone3number

        } else if (this.phone2number != "") {
          this.phone1number += "," + this.phone2number
        }
        // 添加
        axios.get(API + "/addStore", {
          // provinceName省，cityName 城市
          params: {
            storeName: this.storeName,
            pic: this.fileList[0].url,
            phone: this.phone1number,
            province: this.provinceName,
            city: this.cityName,
            area: this.areaName,
            address: this.twon,
            provinceCode: this.provinceCode,
            cityCode: this.cityCode,
            areaCode: this.areaCode,
            // JSON.parse(sessionStorage.getItem("user_info")).id供货商id
            supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }
        ).then((res) => {
          this.$message.success("提交成功")
          this.$router.push('/StoreManagement')

        })

      } else {
        this.$message.warning('还有信息未填写')
      }
    },
    //修改按钮
    updatesubmit () {
      //判断数据是否填写完整 storeName 门店名称 ，areaName区县，phone1number 第一个手机号
      if (this.storeName != "" && this.areaName != "" && this.phone1number != "" && this.fileList != ""
      ) {
        //判断有多少个电话
        if (this.phone3number != "") {
          this.phone1number += "," + this.phone2number
          this.phone1number += "," + this.phone3number

        } else if (this.phone2number != "") {
          this.phone1number += "," + this.phone2number
        }
        // 修改
        axios.get(API + "/updateStore", {
          // provinceName省，cityName 城市
          params: {
            storeId: this.storeId,
            storeName: this.storeName,
            pic: this.fileList[0].url,
            phone: this.phone1number,
            province: this.provinceName,
            city: this.cityName,
            area: this.areaName,
            address: this.twon,
            provinceCode: this.provinceCode,
            cityCode: this.cityCode,
            areaCode: this.areaCode,
            supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }
        ).then((res) => {
          this.$message.success("提交成功")
          this.$router.push('/StoreManagement')
        })

      } else {
        this.$message.warning('还有信息未填写')
      }
    },
    // 初始化地图
    init () {
      var center = new TMap.LatLng(34.754152, 113.667636)
      var map = new TMap.Map('amp-container', {
        center: center,
        zoom: 13,
      })
      this.map = map
      this.markerLayer(34.754152, 113.667636)
      map.on('click', this.clickHandler) // 绑定点击地图获取地理位置的事件
    },
    // 点击获取经纬度
    clickHandler (evt) {
      const latb = parseFloat(evt.latLng.lat.toFixed(6))
      const lngb = parseFloat(evt.latLng.lng.toFixed(6))
      toRaw(this.map).setCenter(new TMap.LatLng(latb, lngb))
      this.reloadMap(latb, lngb)
    },
    // 重载地图
    reloadMap (latitude, lngitude) {

      document.getElementById('amp-container').innerHTML = ''
      this.markerLayers = ''
      let center = new TMap.LatLng(latitude, lngitude)
      //销毁之前的图片mod重新形成新的
      this.map.destroy()
      this.map = new TMap.Map('amp-container', {
        center: center,//设置地图中心点坐标
        zoom: 13,
      })
      toRaw(this.map).on('click', this.clickHandler) // 绑定点击地图获取地理位置的事件
      this.markerLayer(latitude, lngitude)
    },
    //标记地图 
    markerLayer (latitude, lngitude) {
      this.markerLayers = new TMap.MultiMarker({
        map: toRaw(this.map),  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            // "src": '../../assets/logo.png',  //图片路径
            'background': 'pink',
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 16, y: 32 }
          })
        },
        // 点标记数据数组
        geometries: [
          {
            "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            "styleId": 'myStyle',  //指定样式id
            "position": new TMap.LatLng(latitude, lngitude),  //点标记坐标位置
          },
        ]
      })
    },
    // 通过选择的数值获取到当前定位
    async getLocations (text) {
      if (text == 0) {
        if (this.provinceName != "", this.cityName != "", this.areaName != "") {
          text = this.provinceName + this.cityName + this.areaName + this.twon
        } else {
          return ""
        }

      }
      const suggest = new TMap.service.Suggestion({
        pageSize: 10, // 返回结果每页条目数
        regionFix: false, // 搜索无结果时是否固定在当前城市
        servicesk: "XFQBZ-DUACL-V7BP5-ES6CA-MCQMK-LXFXA", // key
      })

      // 请求腾讯地图接口
      suggest.getSuggestions({ keyword: text, servicesk: "ipA7Lt9MgjlPzGv0JmLIWKLBUPMs90Ml" }).then((result) => {
        if (result.data.length != 0) {
          var lat = result.data[0].location.lat
          var lng = result.data[0].location.lng
          this.reloadMap(lat, lng)
        }

        if (typeof this.searchList == 'object') {
          this.souShow = true
        }
      })

    },
    //添加多电话
    mostphone () {
      if (!this.phone2) {
        this.phone2 = true
      } else if (this.phone2) {
        this.phone3 = true
      }
    },
    //删除多电话
    delphone (i) {
      if (i == 2) {
        this.phone2number = ""
        this.phone2 = false
      } else {
        this.phone3number = ""
        this.phone3 = false
      }

    }
  },

  mounted () {
    this.init()
    this.width = this.$el.clientWidth  // 获取父元素的宽度
    window.addEventListener('resize', this.handleResize)  // 监听窗口大小变化

  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)  // 取消监听
  },
}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  padding: 18px 0 0 60px;
}

.pos-monitor {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
  
  