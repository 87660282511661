<template>
  <div class="headerab">
    <a-space direction="vertical">
      <a-button type="primary" style="width: 100px;height: 100px;" @click="QRcode">扫一扫</a-button>

      <a-space style="padding: 120px 0 0 0;" :size="size">
        <span>核销码：</span>
        <a-input placeholder="请输入" style="width: 240px;" v-model:value="code" />
      </a-space>

      <a-space direction="vertical" style="padding: 120px 0 0 0;">
        <a-popconfirm title="确定要核销吗?" ok-text="确定" cancel-text="取消" @confirm="yueis" >
          <a-button type="primary" style="width: 270px;" :loading="loading">确认核销</a-button>
        </a-popconfirm>
        <a-button style="width: 270px;" @click="cancellog">核销记录</a-button>
      </a-space>
      <a-space style="padding: 120px 0 0 0;">
        <a-popconfirm title="确定要退出吗?" ok-text="确定" cancel-text="取消" @confirm="quit" >
          <a-button type="link">退出登录</a-button>
        </a-popconfirm>
      </a-space>
    </a-space>
  </div>
</template>
  
<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      loading: false,
      storerelation: "",
      value: "small",
      valuetime: "",
      code: ""
    }

  },
  created () {

  },
  methods: {
    //商品核销API
    butshop (code) {
      if (code != "") {
        axios.get(API + "/butShop", {
          params: {
            code: code,
            cancelId: JSON.parse(sessionStorage.getItem("cancel_info")).id
          }
        }).then(res => {
          // 点击之后进入加载防止多次点击
          this.loading = true
          if (res.data == "1") {
            this.$message.success("核销成功")
            this.$router.push('/PhoneCanCelLog')
          } else if (res.data == "2") {
            this.$message.error('此商品已被核销')
            this.loading = false
          } else if (res.data == "3") {
            this.$message.error('无效核销码')
            this.loading = false
          } else {
            this.$message.error('此商品不在此店铺核销')
            this.loading = false
          }

          this.code = ""
        }).catch(error => {
          this.$message.error("后端接口连接异常")
        })
      } else {
        this.$message.error('请填写核销码')
      }

    },
    // 核销记录
    cancellog () {
      this.$router.push('/PhoneCanCelLog')
    },
    // 登录界面
    quit () {
      this.$router.push('/PhoneLogin')
    },
    // 二维码扫描
    QRcode () {
      this.$router.push('/QRcode')
    },
    //确认核销
    yueis () {
      // 判断是否为空
      if (this.code != "") {
        // 判断是否格式正确
        this.butshop(this.code)

      } else {
        this.$message.error("未填写核销码")
      }

    },
    //获取菜单的数据
    async getMenuList () {

    },
  },

}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.headerab {
  text-align: center;
  padding: 120px 0 0 0;
}
</style>
  
  