<template>
  <div class="header">
    <a-space direction="vertical">
      <a-space style="padding: 20px 0 0 5%">
        <span><span style="color: red">*</span>用户名：</span>
        <a-input placeholder="请输入用户名" style="width: 400px; height: 35px" v-model:value="username"
          :disabled="buttontype" />
      </a-space>

      <a-space style="padding: 20px 0 0 5%">
        <span><span style="color: red">*</span>手机号：</span>
        <a-input placeholder="请输入手机号" style="width: 400px; height: 35px" v-model:value="phone" :disabled="buttontype" />
      </a-space>

      <a-space style="padding: 20px 0 0 8%">
        <span><span style="color: red">*</span>密码：</span>
        <a-input-password placeholder="请输入密码" style="width: 400px; height: 35px" v-model:value="password" />
      </a-space>

      <a-space style="padding: 20px 0 0 0">
        <span><span style="color: red">*</span>再输入密码：</span>
        <a-input-password placeholder="请输入密码" style="width: 400px; height: 35px" v-model:value="yespassword" />
      </a-space>
      <a-space style="padding: 20px 0 0 50%">
        <a-popconfirm style="margin-left: 400px" title="确定修改吗?" ok-text="确定" cancel-text="取消" @confirm="yusok">
          <a-button type="primary">保存</a-button>
        </a-popconfirm>
      </a-space>
    </a-space>
  </div>
</template>
   
<script>
import API from "@/assets/js/axiosInstance.js"
import axios from "axios"
export default {
  data () {
    return {
      username: "",
      password: "",
      phone: '',
      yespassword: "",
      buttontype: true,
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    //获取菜单的数据
    async getMenuList () {
      //把session中的用户信息遍历出来
      this.phone = JSON.parse(
        sessionStorage.getItem("user_info")
      ).phoneNumber
      this.username = JSON.parse(
        sessionStorage.getItem("user_info")
      ).loginName
      // this.password=JSON.parse(sessionStorage.getItem("user_info")).password
      // this.yespassword=JSON.parse(sessionStorage.getItem("user_info")).password
    },
    //确认提交
    yusok () {
      if (this.yespassword == this.password) {
        axios
          .get(API + "/updatePassword", {
            params: {
              supplierId: JSON.parse(sessionStorage.getItem("user_info")).id,
              password: this.yespassword,
            },
          })
          .then((res) => {
            this.$message.success("提交成功")
            // 清除用户信息
            sessionStorage.removeItem("user_info")
            sessionStorage.removeItem("token")
            this.$router.push("/login")
          })
          .catch((error) => {
            if (error != "520") {
              this.$message.error("后端接口连接异常")
            }
          })
      } else {
        this.$message.warning("两次输入密码不正确")
      }
    },
  },
  mounted () { },
}
</script>
<style scoped>
.header {
  /* padding: 18px 48px 0 400px; */
}
</style>
  
  