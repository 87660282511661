<template>
  <div>
    <input type="text" v-model="phoneNumber" placeholder="手机号" />
    <button @click="sendCaptcha">发送验证码</button>
  </div>
</template>
  
<script>
import axios from 'axios'
import API from "@/assets/js/axiosInstance.js"
export default {
  data () {
    return {
      phoneNumber: ""
    }
  },
  methods: {
    sendCaptcha () {
      // 在此处发送请求生成手机验证码
      const requestPayload = {
        phoneNumber: this.phoneNumber
      }
      let data = new FormData()
      // data.append('request', requestPayload)
      // 发送请求到后端生成手机验证码的接口
      axios.post(API + "/api/captcha",
        // data
      )
        .then(response => {
          // 处理请求成功的逻辑
          alert("手机验证码已发送")
          alert(response.data)
        })
        .catch(error => {
          // 处理请求失败的逻辑
          alert("发送手机验证码失败", error)
        })
    }
  }
};
</script>
  