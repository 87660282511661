
<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>
<script setup>
// const debounce = (fn, delay) => {
//   let timer = null;
//   return function () {
//     let context = this;
//     let args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   }
// }

// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 16);
//     super(callback);
//   }
// } 
//注意这里引用中文包的路径与上方不同，这里不需要做其他操作，直接复制代码即可
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import ZoomRatio from '@/utils/zoom'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
new ZoomRatio().init()

</script>
<style scoped>
.ant-cascader-menu {
  max-height: 620px;
}
</style>