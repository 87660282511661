import { createRouter, createWebHistory } from "vue-router"
import ShopManage from "../components/shop/ShopManage"
import AddShop from "../components/shop/addshop/AddShop"
import headerHead from '@/common/PublicHeader.vue'
import OrderList from "../components/order/OrderList"
import AfterSaleDetails from "../components/order/aftersale/AfterSaleDetails"
import OrderDetails from "../components/order/aftersale/OrderDetails"
import Logistics from "../components/order/aftersale/Logistics"
import AuditLog from "../components/audit/AuditLog.vue"
import AuditDetails from "../components/audit/auditdetails/AuditDetails.vue"
import StoreManagement from "../components/store/StoreManagement.vue"
import CancelManagement from "../components/cancel/CancelManagement.vue"
import CancelLog from "../components/cancel/restcancel/CancelLog.vue"
import AddCancel from "../components/cancel/restcancel/AddCancel.vue"
import AreaManagemenet from "../components/area/AreaManagemenet.vue"
import ReturnAddress from "../components/return/ReturnAddress.vue"
import login from "@/components/login/login.vue"
import Index from '@/components/PublicIndex.vue'
import addstore from '@/components/store/addstore/addstore.vue'
import ChangePassWord from '@/components/login/ChangePassWord.vue'
import PhoneLogin from '@/components/cancel/phonecancel/PhoneLogin.vue'
import PhoneCancel from '@/components/cancel/phonecancel/PhoneCancel.vue'
import PhoneCancelLog from '@/components/cancel/phonecancel/PhoneCanCelLog.vue'
import QRcode from '@/common/QRcode.vue'
const routes = [
  {
    path: "/login",
    name: "login",
    component: login

  },
  {
    path: "/",
    name: "index",
    component: Index,
    redirect: "/ShopManage",
    children: [{
      path: "/ShopManage",
      name: "ShopManage",
      component: ShopManage,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/headerHead",
      name: "headerHead",
      component: headerHead,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/AddShop",
      name: "AddShop",
      component: AddShop,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/OrderList",
      name: "OrderList",
      component: OrderList,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/Logistics",
      name: "Logistics",
      component: Logistics,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/AfterSaleDetails",
      name: "AfterSaleDetails",
      component: AfterSaleDetails,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/OrderDetails",
      name: "OrderDetails",
      component: OrderDetails,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/AuditLog",
      name: "AuditLog",
      component: AuditLog,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/AuditDetails",
      name: "AuditDetails",
      component: AuditDetails,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/StoreManagement",
      name: "StoreManagement",
      component: StoreManagement,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/CancelManagement",
      name: "CancelManagement",
      component: CancelManagement,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/AddCancel",
      name: "AddCancel",
      component: AddCancel,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/CancelLog",
      name: "CancelLog",
      component: CancelLog,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/AreaManagemenet",
      name: "AreaManagemenet",
      component: AreaManagemenet,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/ReturnAddress",
      name: "ReturnAddress",
      component: ReturnAddress,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/addstore",
      name: "addstore",
      component: addstore,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    {
      path: "/ChangePassWord",
      name: "ChangePassWord",
      component: ChangePassWord,
      meta: {
        requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      },
    },
    ]
  },
  {
    path: "/PhoneLogin",
    name: "PhoneLogin",
    component: PhoneLogin
  },
  {
    path: "/PhoneCancel",
    name: "PhoneCancel",
    component: PhoneCancel
  },
  {
    path: "/PhoneCancelLog",
    name: "PhoneCancelLog",
    component: PhoneCancelLog
  },
  {
    path: "/QRcode",
    name: "QRcode",
    component: QRcode
  },


]
const router = createRouter({
  // process.env.BASE_URL
  history: createWebHistory("/"),
  routes
})
//拦截器
router.beforeEach((to, from, next) => {
  //核销端拦截器
  if (to.fullPath == "/PhoneLogin" || to.fullPath == "/PhoneCancel" || to.fullPath == "PhoneCancelLog") {
    var cancel = sessionStorage.getItem("cancel_info")
    if (cancel != undefined) {
      next()
    } else {
      next({
        path: '/PhoneLogin',
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    // 经销商拦截器
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
      var user = sessionStorage.getItem("user_info")
      if (user != undefined) { // 通过vuex state获取当前的token是否存在
        next()
      }
      else {
        next({
          path: '/login',
          query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    }
    else {
      next()
    }
  }
})
export default router
