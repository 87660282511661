<template>
  <div :class="result == '' ? 'sys' : ''">
    <!-- <van-nav-bar @click-left="onClickLeft" :safe-area-inset-top="true" title="扫一扫" left-arrow :placeholder="true"
			:fixed="true"></van-nav-bar> -->
    <BarScan v-if="result == ''" ref="qrcode" @ok="getResult" @err="geterror"></BarScan>
    <span v-if="result != ''">核销码：{{ result }}</span>
  </div>
</template>
 
<script>
// import { setStore } from "@/utils/storage";
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import BarScan from './qrCodeHtml.vue'
export default {
  components: {
    BarScan
  },
  data () {
    return {
      result: ''
    }
  },
  created () {
  },
  methods: {
    onClickLeft () {

      this.$router.back()
    },
    getResult (result) {
      axios.get(API + "/butShop", {
        params: {
          code: result,
          cancelId: JSON.parse(sessionStorage.getItem("cancel_info")).id
        }
      }).then(res => {
        if (res.data == "1") {
          setTimeout(() => {
            this.$message.success('核销成功')
            this.$router.push('/PhoneCanCelLog')
          }, 0)
        } else if (res.data == "2") {
          setTimeout(() => {
            this.$message.error('此商品已被核销')
            this.$router.back()
          }, 0)
        } else {
          setTimeout(() => {
            this.$message.error('此商品不在此店铺核销')
            this.$router.back()
          }, 0)
        }
      }).catch(error => {
        this.$message.error("后端接口连接异常")
      })
      this.result = result
      // setStore('scanCodeVal', this.result);
    },
    geterror (e) {
      this.scancode = false
      this.$toast(e)
    },
  },
  unmounted () {

  }
}
</script>
 
<style  scoped>
.sys {
  width: 100vw;
  height: 100vh;
  background: #111;
  overflow: hidden;
}
</style>
