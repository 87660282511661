<template>
  <div class="header">
    <div style="display: flex;">
      <a-space style="flex-wrap: wrap;">
        <a-button :type="controlleddistri == 1 ? 'primary' : 'dashed'" @click="controlleddistr">{{ controlleddistri == 1 ?
          '关闭配送限制' : '开启配送限制' }}</a-button>
        <span>
          当前状态：
          <a-tag :color="controlleddistri == 1 ? '#1aff00' : '#ff0000'">
            {{ controlleddistri == 1 ? '已开启' : '已关闭' }}
          </a-tag>
        </span>
      </a-space>
      <a-button style="position: absolute;right: 4%;" type="primary" @click="addarea">新增区域</a-button>
    </div>
    <a-modal title="添加限制区域" :open="visible" @ok="handleOk" @cancel="visible = false">
      <a-space>
        <a-cascader v-model:value="locationtype" ref="selectStyle" style="padding-left: 10px;min-width: 420px;"
          :options="options1" popupClassName="AcascaderMenu" placeholder="请选择区域" change-on-select @change="styleChange" />
      </a-space>
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-popconfirm title="确定添加吗?" ok-text="确定" cancel-text="取消" @confirm="handleOk">
          <a-button key="submit" type="primary" :loading="loading">确定</a-button>
        </a-popconfirm>
      </template>
    </a-modal>
    <a-table class="tablewidth" :dataSource="dataSource" :columns="columns" :pagination="pagination"
      @change="tablePaginationChange">
      <template #bodyCell="{ column, record }">

        <template v-if="column.dataIndex == 'erea'">
          <span>{{ record.areaProvince }}{{ record.areaCity }}{{ record.areaCounty }}{{ record.areaTown }}</span>
        </template>
        <template v-if="column.dataIndex == 'controller'">
          <a-popconfirm title="确定删除吗?" ok-text="确定" cancel-text="取消" @confirm="delarea(record.id)">
            <a style="font-size: 14px;">删除</a>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import pcaa from 'china-area-data'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      index: 0,
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      location: [],
      locationtype: "",
      controlleddistri: "",
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // 搜索页数回车跳转
        showQuickJumper: true,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      dataSource: [
      ],
      options1: [],
      children: [],
      columns: [
        {
          title: '不可配送区域',
          dataIndex: 'erea',
          key: 'erea',
        },
        {
          title: '操作',
          dataIndex: 'controller',
          key: 'controller',
        },

      ],
      visible: false,
      width: 500
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    handleResize () {
      this.width = this.$el.clientWidth  // 更新宽度

    },//获取当前页面大小
    handleMenuClick () {

    },
    //进入页面时启动
    async getMenuList () {
      axios.get(API + "/DistributionAreas", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
        this.controlleddistri = res.data[0].controlledDistribution
      }).catch(error => {
      })
      //获取所有省份信息
      let arr = []
      const province = pcaa['86']
      Object.keys(province).map(key => {
        arr.push({
          value: key,
          label: province[key],
          children: [{}]
        })
      })
      this.options1 = arr
      return arr
    },
    // 获取城市province为省份代码，index为省份下标
    getCity (province, index) {
      let arr = []
      const city = pcaa[province]
      Object.keys(city).map(key => {
        this.index += 1
        arr.push({
          value: key,
          label: city[key],
          children: [{}]
        })
      })
      //重新构造选中的数据，重新赋值
      this.options1.splice(index, 1, { value: province[0], label: this.options1[index].label, children: arr })
      return arr
    },
    // 获取区县city为地区代码集合，index为选择省下标，index1为选择市的下标
    getArea (city, index, index1) {
      let arr = []
      const area = pcaa[city[1]]
      Object.keys(area).map(key => {
        arr.push({
          value: key,
          label: area[key],
        })
      })
      this.areas = arr
      //  重新赋值，因为第二层使用不了替换方法所以设了个中间值this.children接收
      this.children = this.options1[index].children
      this.children.splice(index1, 1, { value: city[1], label: this.options1[index].children[index1].label, children: arr })
      this.options1.splice(index, 1, { value: city[0], label: this.options1[index].label, children: this.children })
      return arr
    },
    // 根据选择的省市，更新市县的下拉选项，选择时做存储数据处理
    styleChange (value, index) {
      // 叉掉时触发
      if (index == undefined) {
        this.location = []
      } else {
        // 选择省时为1，选择市时为2，选择区时为3
        if (index.length == 1) {
          this.getCity(value, this.options1.indexOf(index[0]))
          this.location = index
        } else if (index.length == 2) {
          this.getArea(value, this.options1.indexOf(index[0]), this.options1[this.options1.indexOf(index[0])].children.indexOf(index[1]))
          this.location = index
        } else {
          this.location = index
        }
      }


    },
    //删除指定id的区域
    delarea (id) {
      axios.get(API + "/delArea", {
        params: {
          id: id
        }
      }
      ).then(res => {
        this.getMenuList()
        this.$message.success("删除成功")
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //开启添加窗口
    addarea () {
      this.visible = true
    },
    //取消小窗口
    handleCancel () {
      this.location = []
      this.locationtype = ""
      this.visible = false
    },
    //确定添加
    handleOk () {
      // 判断是否选择
      if (this.location.length > 0) {
        axios.get(API + "/addArea", {
          params: {
            cityName: this.location.length >= 2 ? this.location[1].label : "",
            countyName: this.location.length == 3 ? this.location[2].label : "",
            provinceName: this.location[0].label,
            townName: "",
            cityCode: this.location.length >= 2 ? this.location[1].value : "",
            countyCode: this.location.length == 3 ? this.location[2].value : "",
            provinceCode: this.location[0].value,
            supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }
        ).then(res => {
          this.locationtype = []
          this.visible = false
          this.getMenuList()
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
        })
        this.$message.success("添加成功")

      } else {
        this.$message.error("请选择地址")
      }

    },
    // 修改是否开启配置controlleddistri 1为开启，2为关闭
    controlleddistr () {
      if (this.controlleddistri == 1) {
        this.controlleddistri = 2
        this.$message.error("已关闭")
      } else {
        this.controlleddistri = 1
        this.$message.success("已开启")
      }
      axios.get(API + "/controlledDistribution", {
        params: {
          state: this.controlleddistri,
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.controlleddistri = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })

    },
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页
  },
  mounted () {
    this.width = this.$el.clientWidth  // 获取父元素的宽度
    window.addEventListener('resize', this.handleResize)  // 监听窗口大小变化
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)  // 取消监听
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
  padding: 18px 48px 0 48px;
}

.AcascaderMenu .ant-cascader-menu {
  min-height: 500px;
}
</style>

