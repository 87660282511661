<template>
  <div class="headera">
    <a-table :columns="columns" :data-source="cancellog" :pagination="pagination" :loading="loading"
      @change="tablePaginationChange">
      <template #name="{ text }">{{ text.first }} {{ text.last }}</template>

      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex == 'writeOffTime'">
          <span>{{ record.writeOffTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}</span>
        </template>
      </template>

    </a-table>
    <a-space direction="vertical">
      <a-space style="padding: 120px 0 0 0;">
        <a-button style="width: 270px;" type="primary" @click="quit">返回</a-button>
      </a-space>
    </a-space>
  </div>
</template>
  
<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      storerelation: "",
      value: "small",
      valuetime: "",
      columns: [
        {
          title: '核销商品',
          dataIndex: 'huoName',
          key: 'huoName',
          slots: { customRender: 'huoName' },
        },
        {
          title: '核销时间',
          dataIndex: 'writeOffTime',
          key: 'writeOffTime',
        },
      ],
      cancellog: [],
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 4 // 每页条数，所有页设置统一的条数
      },
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    quit () {
      this.$router.push('/PhoneCanCel')
    },
    //获取菜单的数据
    async getMenuList () {
      axios.get(API + "/writeOffRecords", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("cancel_info")).supplyId,
          verificationOfficerId: JSON.parse(sessionStorage.getItem("cancel_info")).verificationOfficerId,
        }
      }
      ).then(res => {
        this.cancellog = res.data
      }).catch(error => {
        this.$message.error("后端接口连接异常")
      })
    },
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页
  },

}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.headera {
  text-align: center;
  padding: 10px 0 0 0;
}
</style>
  
  