
export const tabTypeList = [
  {
    value: '0',
    label: '全部订单',
    show: true
  },
  {
    value: '1',
    label: '待付款',
    show: true
  },
  {
    value: '2',
    label: '待发货',
    show: true
  },
  {
    value: '3',
    label: '已发货',
    show: true
  },
  // {
  //   value: '5',
  //   label: '已结算',
  //   show: true
  // },
  {
    value: '4',
    label: '已完成',
    show: true
  },
  {
    value: '7',
    label: '已关闭',
    show: true
  },
  {
    value: '6',
    label: '售后',
    show: true
  }
]

export function getTabTypeName (tabType = '0') {
  const tab = tabTypeList.find(v => v.value === tabType)
  return tab.label
}