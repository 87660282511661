<template>
  <div class="orderheader">
    <a-space direction="vertical">
      <a-row type="flex" style="display: flex;flex-wrap: wrap;">
        <a-col flex="1 1 200px">
          <a-radio-group size="middle" v-model:value="selectorder" @change="changestate">
            <a-radio-button :value="item.value" v-for="(item, kdx) in tabTypeList" :key="kdx" v-show="item.show">{{
              item.label }}</a-radio-button>
          </a-radio-group>
        </a-col>
        <a-col flex="0 1 300px" :style="'width:' + (width / 2.3) + 'px;text-align: right;padding-right: 20px ;'">
        </a-col>
      </a-row>

      <a-space direction="vertical" size="middle" style="padding-top: 10px;flex-wrap: wrap;">
        <a-space size="large" style="flex-wrap: wrap;">

          <a-space align="baseline">
            <span v-if="selectorder != '6'">物流状态：</span>
            <a-cascader style="width: 195px;" v-model:value="shopingtype1" v-if="selectorder != '6'" ref="selectStyle"
              :options="options1" placeholder="待取件" change-on-select @change="styleChange" />
            <span v-if="selectorder == '6'">售后类型：</span>
            <a-cascader style="width: 195px;" v-if="selectorder == '6'" v-model:value="saletype" ref="selectStyle"
              :options="options2" placeholder="仅退货" change-on-select @change="styleChange" />
          </a-space>

          <a-space style="padding-left: 22px;">
            <span>订单编号：</span>
            <a-input style="width: 190px;" placeholder="请输入订单编号" v-model:value="ordernumber" />
          </a-space>

          <a-space>
            <span>选择时间：</span>
            <a-range-picker style="width: 200px" v-model:value="sendTimevalue" format="YYYY-MM-DD HH:mm:ss"
              :placeholder="['开始时间', '结束时间']" />
          </a-space>
        </a-space>

        <a-space size="large" style="flex-wrap: wrap;">
          <a-space>
            <span>收货人姓名：</span>
            <a-input placeholder="请输入收货人名称" v-model:value="receivername" />
          </a-space>

          <a-space align="baseline">
            <span>收货人手机号：</span>
            <a-input placeholder="请输入收货人手机号" v-model:value="receiverphone" />
          </a-space>

          <a-space style="padding-left: 15px;">
            <a-button @click="reset">重置</a-button>
            <a-button type="primary" @click="select">筛选</a-button>
          </a-space>
          <div style="position: absolute;right: 30px;top: 190px;">
            <a-space>
              <!-- <a-upload v-if="selectorder != '6'" :fileList="[]" name="file" :customRequest="importData"
                accept=".xls,xlsx" :auto-upload="false">
                <a-button type="primary">运单导入</a-button>
              </a-upload> -->
              <a-button type="primary" style="padding-right: 20px;" @click="getorder">订单导出</a-button>
            </a-space>
          </div>
        </a-space>
      </a-space>
    </a-space>
    <a-table size="middle" :columns="columns" style="padding-top: 1%;" :dataSource="orderdata" :pagination="pagination"
      @change="tablePaginationChange">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'huoName'">
          <a-space direction="vertical">
            <span>{{ record.huoName }}</span>
            <span style="color: blue;">{{ (record.te && record.te.sizeName && record.te.sizeId) ? (record.te.sizeName +
              ': ' +
              record.te.sizeId) : '' }}</span>
          </a-space>
        </template>
        <template v-if="column.dataIndex == 'huoSinglePrice'">
          ￥{{ record.costPrice }}
        </template>
        <template v-if="column.dataIndex == 'huoPic'">
          <a-image :width="100" :src="record.huoPic" />
        </template>
        <template v-if="column.dataIndex == 'receiverNameAndPhone'">
          <span>{{ record.receiverName }}
            <span style="font-weight: 700;">/</span>
            {{ record.receiverPhone }}</span>
        </template>
        <template v-if="column.dataIndex == 'createTime' && record.createTime != null">
          <span>{{ record.createTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}</span>
        </template>
        <template v-if="column.dataIndex == 'sendTime' && record.sendTime == null">
          <span>{{ record.sendTime }}</span>
        </template>
        <template v-if="column.dataIndex == 'logisticsStatus'">
          <span color="#87d068" v-if="record.orderStatus == 7">-</span>
          <a-tag color="#87d068" v-else-if="record.logisticsStatus == 1 && record.isAfterSale != 1">待取件</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.logisticsStatus == 2 && record.isAfterSale != 1">已取件</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.logisticsStatus == 3 && record.isAfterSale != 1">运输中</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.logisticsStatus == 4 && record.isAfterSale != 1">已签收</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.logisticsStatus == 9 && record.isAfterSale != 1">问题件</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.afterSaleStatus == 1 && record.isAfterSale == 1">待平台审核</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.afterSaleStatus == 2 && record.isAfterSale == 1">退款成功</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.afterSaleStatus == 3 && record.isAfterSale == 1">退款失败</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.afterSaleStatus == 4 && record.isAfterSale == 1">待寄回货品</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.afterSaleStatus == 5 && record.isAfterSale == 1">待商家收货</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.afterSaleStatus == 6 && record.isAfterSale == 1">售后取消</a-tag>
        </template>

        <template v-else-if="column.dataIndex == 'orderStatus'">
          <span color="#87d068" v-if="record.isAfterSale == 1">-</span>
          <a-tag color="#A9A9A9" v-else-if="record.orderStatus == 1 && record.isSettled != 1">未付款</a-tag>
          <a-tag color="blue" v-else-if="record.orderStatus == 2 && record.isSettled != 1">已付款</a-tag>
          <a-tag color="#87d068" v-else-if="record.orderStatus == 3 && record.isSettled != 1">已发货</a-tag>
          <a-tag color="#87d068" v-else-if="record.isSettled == 1">已结算</a-tag>
          <a-tag color="#87d068" v-else-if="record.orderStatus == 4 && record.isSettled != 1">已完成</a-tag>
          <a-tag color="red" v-else-if="record.orderStatus == 7 && record.isSettled != 1">已关闭</a-tag>
        </template>

        <template v-else-if="column.dataIndex == 'controller'">
          <a-space direction="vertical">
            <a-space>
              <a v-if="record.isAfterSale == '1'" style="margin-left: auto;padding-right: 10px;"
                @click="seeinfor(record)">查看详情</a>
              <!-- <a v-if="record.isAfterSale == '1' && (record.afterSaleStatus == '1' || record.afterSaleStatus == '4')"
                style="margin-left: auto;padding-right: 10px;" @click="seeinfor(record)">处理</a> -->
              <a v-if="record.isAfterSale == '2'" style="margin-left: auto;padding-right: 10px;"
                @click="seeorderinfor(record)">查看详情</a>
            </a-space>
            <a-space>
              <a style="font-size: 14px;padding-right: 20px" @click="giveshop(record.id)"
                v-if="record.orderStatus == 2 && record.isAfterSale != 1 && record.afterSaleStatus != 2">发货</a>
              <!-- <a style="font-size: 14px;" v-if="record.orderStatus == 3 && record.isAfterSale != 1"
                @click="logistics(record)">查看物流</a> -->
              <!-- <a-popconfirm title="确定收货吗?" ok-text="确定" cancel-text="取消" @confirm="takeshop(record.orderNumber)">
                <a style="font-size: 14px;" v-if="record.isAfterSale == 1 && record.afterSaleStatus == 5">确认收货</a>
              </a-popconfirm> -->
            </a-space>
          </a-space>
        </template>
      </template>
    </a-table>
    <a-modal :open="giveshopvalue" title="确认发货" ok-text="确认" cancel-text="取消" style="width: 50%;"
      @cancel="giveshopvalue = false">
      <a-space style="padding: 20px 0 0 0;">
        <span><span style="color: red;">*</span>快递公司：</span>
        <a-cascader v-model:value="logisticsCompany" ref="selectStyle" :options="expressoptions" placeholder="请选择"
          change-on-select @change="styleChange" />
      </a-space>
      <a-space style="padding: 20px 0 0 0;">
        <span><span style="color: red;">*</span>运单号：</span>
        <a-input placeholder="请输入" style="width: 400px;" v-model:value="logisticsNumber" />
      </a-space>
      <template #footer>
        <a-popconfirm title="确定发货吗?" ok-text="确定" cancel-text="取消" @confirm="whethershop()">
          <a-button key="submit" type="primary">发货</a-button>
        </a-popconfirm>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import * as XLSX from "xlsx"
import { Empty } from 'ant-design-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import moment from 'moment'
import { tabTypeList, getTabTypeName } from './orderMixins'
import wuliu from './wj'
// 初始化表格数值
const loadData = []
export default {
  setup() {
    return {
      SearchOutlined
    }
  },
  data() {
    return {
      tabTypeList,
      selectorder: "0",
      width: "500",
      loadData,
      orderId: "",
      shopingtype1: "",
      logisticsCompany: "",
      logisticsNumber: "",
      total: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      giveshopvalue: false,
      json_fields: {
        'Complete name': 'name',
        'Date': 'date',
      },
      ordergroud: [],
      orderdata: [],
      orderinfodata: 0,
      fileTemp: "",
      expressoptions: [],
      options1: [{
        value: 1,
        label: "待取件"
      }, {
        value: 2,
        label: "已取件"
      },
      {
        value: 3,
        label: "运输中"
      },
      {
        value: 4,
        label: "已签收"
      },
      {
        value: 9,
        label: "问题件"
      },],
      options2: [{
        value: 1,
        label: "仅退款"
      }, {
        value: 2,
        label: "退款退货"
      },],
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        // 搜索页数回车跳转
        showQuickJumper: true,
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 "
          }
        ]
      ],
      orderlist: [

      ],
      xlsxcolumns: [],
      xlsxList: [],
      columns: [
      ],
      sendTimevalue: [],
      startsendTime: "",
      visible: false,
      endsendTime: "",
      logisticsstatus: "",
      ordernumber: "",
      saletype: "",
      receivername: "",
      receiverphone: "",
      orderindex: -1,
      ordervalue: -1,
      txt_order: {
        1: '待付款',
        2: '待发货',
        3: '待收货',
        4: '已完成',
      },
      txt_wuliu: {
        1: "待取件",
        2: "已取件",
        3: "运输中",
        4: "已签收",
        9: "问题件"
      },
      txt_after: {
        1: '待平台审核',
        2: '退款成功',
        3: '退款失败',
        4: '待寄回货品',
        5: '待商家收货',
        6: '售后取消',
      }
    }

  },

  created() {
    this.getMenuList(),
      this.express()
  },
  watch: {
    '$route'(to, from) {
      this.selectorder = "0"
    }
  },
  methods: {
    handleResize() {
      this.width = this.$el.clientWidth  // 更新宽度

    },//获取当前页面大小
    async express() {
      // axios.get(API + "/sysExpresses", {

      // }).then(res => {

      let res = { data: wuliu };
      for (var i = 0; i < res.data.length; i++) {
        // this.expressoptions.push({ index: i, id: res.data[i].id, value: res.data[i].expressCode, label: res.data[i].expressName })
        this.expressoptions.push({ index: i, id: i, value: res.data[i].value, label: res.data[i].label })
      }
      // }).catch(error => {
      // if (error != "520") {
      // this.$message.error("后端接口连接异常")
      // }
      // })
    },
    //首次进入获取菜单的数据
    async getMenuList(state) {
      // 判断时间是否为空
      if (this.sendTimevalue != undefined && this.sendTimevalue != "") {
        this.startsendTime = this.sendTimevalue[0].format('YYYY-MM-DD 00:00:00')
        this.endsendTime = this.sendTimevalue[1].format('YYYY-MM-DD 23:59:59')
      }
      //订单列表
      axios.get(API + "/OrderInfoDeta", {
        params: {
          orderNumber: this.ordernumber,
          startSendTime: this.startsendTime,
          endSendTime: this.endsendTime,
          logisticsStatus: this.shopingtype1[0],
          receiverName: this.receivername,
          receiverPhone: this.receiverphone,
          orderStatus: this.selectorder,
          saleType: this.saletype[0],
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id,
        }
      }
      ).then(res => {
        // axios.get(API + "/OrderInfo", {
        //   params: {
        //     supplyId: JSON.parse(sessionStorage.getItem("user_info")).id,
        //   }
        // }).then(rese => {
        // 获取到每个订单组的个数
        // this.ordergroud = rese.data
        // 每个订单
        let orders = res.data.map(x => {
          try {
            x.te = JSON.parse(x.specialFields)
          } catch (e) {

          }
          // console.log(x.te)
          return x
        })
        this.orderdata = orders
        this.columns = [
          {
            title: '订单编号',
            dataIndex: 'orderNumber',
            // customCell: (customCell, rowIndex) => {
            //   for (var i = 0; i < this.ordergroud.length; i++) {
            //     if (this.ordergroud[i].orderNumber == customCell.orderNumber) {
            //       if (this.ordergroud[i].orderCount != "1") {
            //         if (i != this.ordervalue) {
            //           this.orderindex = rowIndex
            //           this.ordervalue = i
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         } else if (this.orderindex != rowIndex) {
            //           return { rowSpan: 0 }
            //         } else {
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         }
            //       }
            //     }
            //   }
            // },
            className: 'column-center',
          },
          {
            title: '下单时间',
            dataIndex: 'createTime',
            // customCell: (customCell, rowIndex) => {
            //   for (var i = 0; i < this.ordergroud.length; i++) {
            //     if (this.ordergroud[i].orderNumber == customCell.orderNumber) {
            //       if (this.ordergroud[i].orderCount != "1") {
            //         if (i != this.ordervalue) {
            //           this.orderindex = rowIndex
            //           this.ordervalue = i
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         } else if (this.orderindex != rowIndex) {
            //           return { rowSpan: 0 }
            //         } else {
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         }
            //       }
            //     }
            //   }
            // },
            className: 'column-center',
          },
          {
            title: '商品图片',
            dataIndex: 'huoPic',
            className: 'column-center',
          },
          {
            title: '商品名称',
            dataIndex: 'huoName',
            className: 'column-center',
          },
          {
            title: '数量',
            width: 80,
            // colSpan: 2,
            dataIndex: 'huoQty',
          },
          {
            title: '价格',
            // colSpan: 0,
            dataIndex: 'huoSinglePrice',
            className: 'column-center',
          },
          {
            title: '收货人/手机号',
            dataIndex: 'receiverNameAndPhone',
            // customCell: (customCell, rowIndex) => {
            //   for (var i = 0; i < this.ordergroud.length; i++) {
            //     if (this.ordergroud[i].orderNumber == customCell.orderNumber) {
            //       if (this.ordergroud[i].orderCount != "1") {
            //         if (i != this.ordervalue) {
            //           this.orderindex = rowIndex
            //           this.ordervalue = i
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         } else if (this.orderindex != rowIndex) {
            //           return { rowSpan: 0 }
            //         } else {
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         }
            //       }
            //     }
            //   }
            // },
            className: 'column-center',
          },
          {
            title: '订单状态',
            dataIndex: 'orderStatus',
            // customCell: (customCell, rowIndex) => {
            //   for (var i = 0; i < this.ordergroud.length; i++) {
            //     if (this.ordergroud[i].orderNumber == customCell.orderNumber) {
            //       if (this.ordergroud[i].orderCount != "1") {
            //         if (i != this.ordervalue) {
            //           this.orderindex = rowIndex
            //           this.ordervalue = i
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         } else if (this.orderindex != rowIndex) {
            //           return { rowSpan: 0 }
            //         } else {
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         }
            //       }
            //     }
            //   }
            // },
            className: 'column-center',
          },
          {
            title: this.selectorder == 6 ? "售后状态" : '物流状态',
            dataIndex: 'logisticsStatus',
            // customCell: (customCell, rowIndex) => {
            //   for (var i = 0; i < this.ordergroud.length; i++) {
            //     if (this.ordergroud[i].orderNumber == customCell.orderNumber) {
            //       if (this.ordergroud[i].orderCount != "1") {
            //         if (i != this.ordervalue) {
            //           this.orderindex = rowIndex
            //           this.ordervalue = i
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         } else if (this.orderindex != rowIndex) {
            //           return { rowSpan: 0 }
            //         } else {
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         }
            //       }
            //     }
            //   }
            // },
            className: 'column-center',
          },
          {
            title: '操作',
            width: 200,
            dataIndex: 'controller',
            // customCell: (customCell, rowIndex) => {
            //   for (var i = 0; i < this.ordergroud.length; i++) {
            //     if (this.ordergroud[i].orderNumber == customCell.orderNumber) {
            //       if (this.ordergroud[i].orderCount != "1") {
            //         if (i != this.ordervalue) {
            //           this.orderindex = rowIndex
            //           this.ordervalue = i
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         } else if (this.orderindex != rowIndex) {
            //           return { rowSpan: 0 }
            //         } else {
            //           return { rowSpan: this.ordergroud[i].orderCount }
            //         }
            //       }
            //     }
            //   }
            // },
            className: 'column-center',
          },
        ]
        // })

      })
    },
    //筛选
    select() {
      this.pages = 1
      this.getMenuList()
    },
    //查看物流 order订单信息
    logistics(order) {
      this.$router.push({
        path: '/Logistics', query: { order: JSON.stringify(order) }
      })
    },
    // 确认发货更改状态buttonstate为1时发货，为2时送货，orderid为订单id
    whethershop() {
      if (this.logisticsNumber != "" && this.logisticsCompany != "" && this.logisticsCompany != undefined) {
        axios.get(API + "/whetherShop", {
          params: {
            orderId: this.orderId,
            buttonState: 1,
            logisticsNumber: this.logisticsNumber,
            logisticsCompany: this.logisticsCompany[0],
            supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }
        ).then(resone => {
          this.getMenuList()
          this.$message.success("发货成功")
          // 小窗口隐藏
          this.giveshopvalue = false
          // 初始化运单号和快递公司的输入值
          this.logisticsNumber = ""
          this.logisticsCompany = ""
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
        })
      } else {
        this.$message.error("还有信息未填写")
      }

    },
    // 调出发货小窗口orderid订单id
    giveshop(orderId) {
      this.orderId = orderId
      this.giveshopvalue = true
    },
    //经销商收货成功
    takeshop(orderId) {
      axios.get(API + "/whetherShop", {
        params: {
          orderId: orderId,
          buttonState: 2,
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(resone => {
        this.getMenuList()
        this.$message.success("收货成功")
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    styleChange(value) {
      if (value == undefined) {
        this.shopingtype1 = ""
        this.saletype = ""
      }
    },
    // 重置
    reset() {
      this.sendTimevalue = []
      this.startsendTime = ""
      this.endsendTime = ""
      this.logisticsstatus = ""
      this.ordernumber = ""
      this.receivername = ""
      this.receiverphone = ""
      this.shopingtype1 = ""
      this.saletype = ""
      this.getMenuList()
    },
    changestate() {
      this.pages = 1
      //清除选择的数据
      this.reset()
    },
    //售后详情
    seeinfor(orderlist) {

      this.$router.push({
        path: '/AfterSaleDetails', query: { orderNumber: orderlist.orderNumber }
      })
    },
    //订单详情
    seeorderinfor(orderlist) {
      this.$router.push({
        path: '/OrderDetails', query: { orderNumber: orderlist.orderNumber }
      })
    },
    //这是导出xlsx表格
    getorder() {
      let arr = []
      // console.log(this.orderdata)
      // return
      // console.log(this.expressoptions)
      arr = this.orderdata.map(v => {
        console.log(JSON.stringify(v))
        let logisticsCompanyName = ''
        const logisticsCompany = this.expressoptions.find(item => item.value === v.logisticsCompany)
        if (logisticsCompany) {
          logisticsCompanyName = logisticsCompany.label
        }
        let te = null
        try {
          te = JSON.parse(v.specialFields)
        } catch (e) { }

        return {
          "订单编号": v.orderNumber,
          "订单状态": this.txt_order[v.orderStatus] || '-',
          "商品名称": v.huoName,
          "商品规格": te && te.sizeName && te.sizeId ? te.sizeName + ': ' + te.sizeId : '',
          "商品价格": v.huoSinglePrice,
          "供货价格": v.huoPriceSupply,
          "商品数量": v.huoQty,
          "订单总价": v.huoPriceAmount,//v.huoQty * v.huoPriceSupply,
          "运费": v.freight,
          "收件人姓名": v.receiverName,
          "收件人地址": v.receiverAddress,
          "收件人电话": v.receiverPhone,
          "物流状态": this.txt_wuliu[v.logisticsStatus] || '-',
          "物流公司": logisticsCompanyName,
          "物流公司Key": v.logisticsCompany,
          "物流单号": v.logisticsNumber,
          "下单时间": moment(v.createTime).format('YYYY-MM-DD HH:mm:ss'),
          "订单类型": v.createBy == '数字卡券' ? '数字卡券' : '积分商城',//getTabTypeName(this.selectorder),
          "售后状态": this.txt_after[v.afterSaleStatus] || '-'
        }
      })
      // return
      // 创建一个新的工作簿
      const workbook = XLSX.utils.book_new()
      // 创建一个新的工作表
      const worksheet = XLSX.utils.json_to_sheet(arr)
      // 将工作表附加到工作簿，并将工作表命名为students
      XLSX.utils.book_append_sheet(workbook, worksheet, getTabTypeName(this.selectorder))
      // 导出工作簿，并命名导出文件名为Presidents.xlsx
      XLSX.writeFile(workbook, "allorder.xls")
    },
    // xlsx表格导入数据
    importData(file, fileList) {
      let reader = new FileReader()
      /*  
      reader.readAsBinaryString(file.file) 注意这个是传入的是file.file，
      刚开始传的是file、不可以、ant框架是file.file
      */
      reader.readAsBinaryString(file.file) //这个是file.file文件，可不是file……
      reader.onload = (ev) => {
        let res = ev.target.result
        const worker = XLSX.read(res, { type: "binary" })
        // 将返回的数据转换为json对象的数据
        reader = XLSX.utils.sheet_to_json(worker.Sheets[worker.SheetNames[0]])
        let getdate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        //日期
        this.$message.loading("上传中")
        for (let i = 0; i < reader.length; i++) {
          axios.get(API + "/insetXlsx", {
            params: {
              huoName: reader[i]["商品名称"],
              logisticsCompany: reader[i]["快递公司"],
              logisticsNumber: reader[i]["快递单号"],
              receiverName: reader[i]["收件人姓名"],
              receiverPhone: reader[i]["收件人联系电话"],
              orderNumber: reader[i]["订单编号"],
              supplyId: JSON.parse(sessionStorage.getItem("user_info")).id,
              getDate: getdate
            }
          }
          ).then((res) => {
            if (i == reader.length - 1) {
              if (res.data != "") {
                this.$message.error(res.data + "导入失败，没有此商品")
              } else {
                this.$message.success("上传成功")
              }
              this.getMenuList()
            }
          })
        }
        // data.visible = true;
        // this.visible=true
      }
    },
    //分页
    tablePaginationChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },
  },

  //这是获取页面宽度
  mounted() {
    this.width = this.$el.clientWidth  // 获取父元素的宽度
    window.addEventListener('resize', this.handleResize)  // 监听窗口大小变化
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)  // 取消监听
  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.orderheader {
  padding: 2% 0 0 2%;
}

.tablehear {
  width: 100%;
  font-size: 16px;

}

.tablehear1 {
  width: 100%;
  font-size: 14px;
  padding: 5px 0 0 30px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: flex
}

.tablehear1conter {
  width: 50%;
  height: 60px;
  border: solid black 1px
}

th.column-center,
td.column-center {
  text-align: center !important;
}

.column-right {
  text-align: center;
  vertical-align: top
}

.column-top {
  vertical-align: top
}
</style>
