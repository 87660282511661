export default [
  { label: '百世快递', value: 'HTKY' },
  { label: '中通快递', value: 'ZTO' },
  { label: '申通快递', value: 'STO' },
  { label: '圆通速递', value: 'YTO' },
  { label: '韵达速递', value: 'YD' },
  { label: '邮政快递包裹', value: 'YZPY' },
  { label: 'EMS', value: 'EMS' },
  { label: '顺丰速运', value: 'SF' },
  { label: '京东快递', value: 'JD' },
  { label: '优速快递', value: 'UC' },
  { label: '德邦快递', value: 'DBL' },
  { label: '极兔速递', value: 'JTSD' },
  { label: '菜鸟直送', value: 'CNSD' },
  { label: '菜鸟橙运', value: 'CNCY' },
  { label: '宅急送', value: 'ZJS' },
  { label: '丰网速运', value: 'FWX' },
  { label: '安能快递', value: 'ANE' },
  { label: '安能快运/物流', value: 'ANEKY' },
  { label: '安捷快递', value: 'AJ' },
  { label: '安迅物流', value: 'AX' },
  { label: '安邮美国', value: 'AYUS' },
  { label: '亚马逊物流', value: 'AMAZON' },
  { label: '澳门邮政', value: 'AOMENYZ' },
  { label: '澳多多', value: 'ADD' },
  { label: '澳邮专线', value: 'AYCA' },
  { label: '安鲜达', value: 'AXD' },
  { label: '安敏物流', value: 'ANMIN56' },
  { label: '安得物流', value: 'ANNTO' },
  { label: '澳德物流', value: 'AUODEXPRESS' },
  { label: '转运四方', value: 'A4PX' },
  { label: 'A2U速递', value: 'A2U' },
  { label: 'AAE全球专递', value: 'AAE' },
  { label: '明大快递', value: 'ADAEXPRESS' },
  { label: '德国雄鹰速递', value: 'ADLERLOGI' },
  { label: '安达信', value: 'ADVANCING' },
  { label: '全程快递', value: 'AGOPOST' },
  { label: '德方物流', value: 'AHDF' },
  { label: '卡邦配送', value: 'AHKBPS' },
  { label: '加拿大民航快递', value: 'AIRGTC' },
  { label: '捷记方舟', value: 'AJEXPRESS' },
  { label: 'AlfaTrex', value: 'ALFATREX' },
  { label: '无忧物流', value: 'ALIEXPRESS' },
  { label: 'ALLIED', value: 'ALLIEDEXPRESS' },
  { label: '心怡物流', value: 'ALOG' },
  { label: '亚马逊中国订单', value: 'AMAZONCNORDER' },
  { label: 'amazon-国际订单', value: 'AMUSORDER' },
  { label: '安家同城快运', value: 'ANJIATONGCHENG' },
  { label: '青岛安捷快递', value: 'ANJIEKUAIDI' },
  { label: 'Anteraja', value: 'ANTERAJA' },
  { label: '荷属安的列斯荷兰邮政', value: 'ANTILLESPOST' },
  { label: '安信达', value: 'ANXINDAKUAIXI' },
  { label: '澳速物流', value: 'AOSU' },
  { label: 'Aramex', value: 'ARAMEX' },
  { label: 'ARC', value: 'ARC' },
  { label: '澳达国际物流', value: 'AUADEXPRESS' },
  { label: '澳货通', value: 'AUEX' },
  { label: '奥地利邮政', value: 'AUSTRIAPOST' },
  { label: 'AUV国际快递', value: 'AUVEXPRESS' },
  { label: '澳新物流', value: 'AXEXPRESS' },
  { label: '安鹿物流', value: 'ALWL' },
  { label: '八达通', value: 'BDT' },
  { label: '百腾物流', value: 'BETWL' },
  { label: '奔腾物流', value: 'BNTWL' },
  { label: '八方安运', value: 'BFAY' },
  { label: '百世国际', value: 'BAISHIGUOJI' },
  { label: '百世云配', value: 'BAISHIYP' },
  { label: '宝通快递', value: 'BAOTONGKD' },
  { label: '8+1物流', value: 'BJY' },
  { label: '报通快递', value: 'BAOXIANDA' },
  { label: 'BDC快递', value: 'BDCGCC' },
  { label: '北京丰越供应链', value: 'BEIJINGFENGYUE' },
  { label: '飛斯特', value: 'BESTER' },
  { label: '败欧洲', value: 'BEUROPE' },
  { label: '百福东方物流', value: 'BFDF' },
  { label: '上海缤纷物流', value: 'BFLG' },
  { label: '彪记快递', value: 'BIAOJIKUAIDI' },
  { label: '青云物流', value: 'BJQYWL' },
  { label: '鑫锐达', value: 'BJXSRD' },
  { label: '远通盛源', value: 'BJYTSYWL' },
  { label: '佰乐捷通', value: 'BLJT56' },
  { label: '比利时邮政', value: 'BLSYZ' },
  { label: '蓝天国际航空快递', value: 'BLUESKYEXPRESS' },
  { label: '巴伦支快递', value: 'BLZ' },
  { label: '标杆物流', value: 'BMLCHINA' },
  { label: '笨鸟国际', value: 'BN' },
  { label: '堡昕德速递', value: 'BOSIND' },
  { label: '速方(Sufast)', value: 'BPHCHINA' },
  { label: '百千诚物流', value: 'BQCWL' },
  { label: '宏桥国际物流', value: 'BRIEMS' },
  { label: '百事亨通速递', value: 'BSHT' },
  { label: '邦泰快运', value: 'BTEXPRESS' },
  { label: '展勤快递', value: 'BYHT' },
  { label: '百世快运', value: 'BTWL' },
  { label: '帮帮发转运', value: 'BBFZY' },
  { label: '百城通物流', value: 'BCTWL' },
  { label: '百米快运', value: 'BAIMI' },
  { label: '北京渡石', value: 'DS' },
  { label: '重庆博卡联物流', value: 'BKL' },
  { label: '春风物流', value: 'CFWL' },
  { label: '菜鸟橙运', value: 'CNCY' },
  { label: '广东诚通物流', value: 'CHTWL' },
  { label: '传喜物流', value: 'CXHY' },
  { label: '城市100', value: 'CITY100' },
  { label: 'CEVA物流', value: 'CEVA' },
  { label: '城际快递', value: 'CJKD' },
  { label: '长通物流', value: 'CTWL' },
  { label: 'CNPEX中邮快递', value: 'CNPEX' },
  { label: 'COE东方快递', value: 'COE' },
  { label: '长沙创一', value: 'CSCY' },
  { label: '成都善途速运', value: 'CDSTKY' },
  { label: '加拿大邮政', value: 'CA' },
  { label: '能装能送', value: 'CANHOLD' },
  { label: '卢森堡航空', value: 'CARGOLUX' },
  { label: '广州信邦', value: 'CBLLOGISTICS' },
  { label: '钏博物流', value: 'CBO56' },
  { label: '中集冷云', value: 'CCCC58' },
  { label: '中迅三方', value: 'CD3FWL' },
  { label: '捷祥物流', value: 'CDJX56' },
  { label: '鑫宸物流', value: 'CDXINCHEN56' },
  { label: '银雁专送', value: 'CFSS' },
  { label: '河北橙配', value: 'CHENGPEI' },
  { label: '城通物流', value: 'CHENGTONG' },
  { label: '荣通国际', value: 'CHINAQINGGUAN' },
  { label: 'SQK国际速递', value: 'CHINASQK' },
  { label: '嘉荣物流', value: 'CHLLOG' },
  { label: 'CHS中环国际快递', value: 'CHSZHONGHUANGUOJI' },
  { label: '诚和通', value: 'CHT361' },
  { label: '中国香港骏辉物流', value: 'CHUNFAI' },
  { label: '城市映急', value: 'CITY56' },
  { label: '大韩通运', value: 'CJKOREAEXPRESS' },
  { label: '城晓国际快递', value: 'CKEEX' },
  { label: '出口易', value: 'CKY' },
  { label: '澳通华人物流', value: 'CLLEXPRESS' },
  { label: '群航国际物流', value: 'CLOUDLOGISTICS365' },
  { label: 'CNAIR', value: 'CNAIR' },
  { label: '中澳速递', value: 'CNAUSU' },
  { label: '马珂博逻', value: 'CNMCPL' },
  { label: '速舟物流', value: 'CNSPEEDSTER' },
  { label: '中国翼', value: 'CNWS' },
  { label: '华中快递', value: 'CPSAIR' },
  { label: '重庆星程快递', value: 'CQXINGCHENG' },
  { label: '环旅快运', value: 'CROSSBOX' },
  { label: '新时速物流', value: 'CSXSS' },
  { label: '村通快递', value: 'CUNTO' },
  { label: '小飞侠速递', value: 'CYXFX' },
  { label: '云南诚中物流', value: 'CZWLYN' },
  { label: '联合运通', value: 'CTG' },
  { label: 'CBO钏博物流', value: 'CBO' },
  { label: '佳吉快运', value: 'CNEX' },
  { label: '畅顺通达', value: 'CSTD' },
  { label: 'D速物流', value: 'DSWL' },
  { label: '到了港', value: 'DLG' },
  { label: '大田物流', value: 'DTWL' },
  { label: '大众佐川急便', value: 'DAZHONG' },
  { label: '叮当同城', value: 'DDOTBASE' },
  { label: '多道物流', value: 'DDWL' },
  { label: '深圳德创物流', value: 'DECHUANGWULIU' },
  { label: '德豪驿', value: 'DEHAOYI' },
  { label: '德坤物流', value: 'DEKUN' },
  { label: '澳行快递', value: 'DESWORKS' },
  { label: '东风全球速递', value: 'DFGLOBALEX' },
  { label: '达发物流', value: 'DFWL' },
  { label: '递五方云仓', value: 'DI5PLL' },
  { label: '云南滇驿物流', value: 'DIANYI' },
  { label: '递达速运', value: 'DIDASUYUN' },
  { label: '叮咚澳洲转运', value: 'DINDON' },
  { label: '叮咚快递', value: 'DINGDONG' },
  { label: '递四方澳洲', value: 'DISIFANGAU' },
  { label: '递四方美国', value: 'DISIFANGUS' },
  { label: '吉布提', value: 'DJIBOUTI' },
  { label: '东瀚物流', value: 'DONGHANWL' },
  { label: 'DPD', value: 'DPD' },
  { label: '老司机国际快递', value: 'DRIVERFASTGO' },
  { label: '德淘邦', value: 'DT8ANG' },
  { label: '德邦快运/德邦物流', value: 'DBLKY' },
  { label: '大马鹿', value: 'DML' },
  { label: '丹鸟物流', value: 'DNWL' },
  { label: '东方汇', value: 'EST365' },
  { label: '递易家', value: 'DYJ' },
  { label: 'E特快', value: 'ETK' },
  { label: '东方航空物流', value: 'EALCEAIR' },
  { label: '东西E全运', value: 'ECOTRANSITE' },
  { label: '易联通达', value: 'EL56' },
  { label: '澳州顺风快递', value: 'EMMS' },
  { label: 'EMS包裹', value: 'EMSBG' },
  { label: 'EMS物流', value: 'EMSWULIU' },
  { label: '贰仟家物流', value: 'ERQIANJIA56' },
  { label: 'europeanecom', value: 'EUROPEANECOM' },
  { label: '可可树美中速运', value: 'EXCOCOTREE' },
  { label: '7号速递', value: 'EXPRESS7TH' },
  { label: '易转运', value: 'EZHUANYUAN' },
  { label: 'EMS国内', value: 'EMS2' },
  { label: 'EWE', value: 'EWE' },
  { label: 'E通速递', value: 'ETONG' },
  { label: '飞康达', value: 'FKD' },
  { label: '凡宇货的', value: 'FYKD' },
  { label: '速派快递', value: 'FASTGO' },
  { label: '丰程物流', value: 'FCWL' },
  { label: 'FEDEX联邦（国内）', value: 'FEDEX' },
  { label: '飞快达', value: 'FEIKUAIDA' },
  { label: '凤凰快递', value: 'FENGHUANGKUAIDI' },
  { label: '奉天物流', value: 'FENGTIANEXPRESS' },
  { label: '四方格', value: 'FOURPXUS' },
  { label: '全速快递', value: 'FSEXP' },
  { label: '富吉速运', value: 'FUJISUYUN' },
  { label: '法翔速运', value: 'FX' },
  { label: '飞豹快递', value: 'FBKD' },
  { label: '泛球物流', value: 'FANQIU' },
  { label: '丰巢', value: 'FBOX' },
  { label: '飞狐快递', value: 'FHKD' },
  { label: '复融供应链', value: 'FRGYL' },
  { label: '飞远配送', value: 'FYPS' },
  { label: '凡宇速递', value: 'FYSD' },
  { label: '丰通快运', value: 'FT' },
  { label: '冠达', value: 'GD' },
  { label: '广东邮政', value: 'GDEMS' },
  { label: '共速达', value: 'GSD' },
  { label: '快捷快物流', value: 'GDKJK56' },
  { label: '全网物流', value: 'GDQWWL' },
  { label: '容智快运', value: 'GDRZ58' },
  { label: '国际邮政包裹', value: 'GJYZBG' },
  { label: '时安达速递', value: 'GOEX' },
  { label: '万通快递', value: 'GSWTKD' },
  { label: '冠泰', value: 'GT' },
  { label: 'GTS快递（广通速递）', value: 'GTS' },
  { label: '广东通路', value: 'GUANGDONGTONGLU' },
  { label: '永邦快递', value: 'GUANGDONGYONGBANG' },
  { label: '全联速运', value: 'GUEXP' },
  { label: '天天快物流', value: 'GUOERYUE' },
  { label: '广州安能聚创物流', value: 'GZANJCWL' },
  { label: '贵州星程快递', value: 'GZXINGCHENG' },
  { label: '广通', value: 'GTONG' },
  { label: '冠达快递', value: 'GDKD' },
  { label: '广通速递', value: 'GTKD' },
  { label: '高铁快运', value: 'GTKY' },
  { label: '迦递快递', value: 'GAI' },
  { label: '港快速递', value: 'GKSD' },
  { label: '高铁速递', value: 'GTSD' },
  { label: '广东易代收', value: 'GDYDS' },
  { label: '黑狗冷链', value: 'HGLL' },
  { label: '恒路物流', value: 'HLWL' },
  { label: '天地华宇', value: 'HOAU' },
  { label: '鸿桥供应链', value: 'HOTSCM' },
  { label: '海派通物流公司', value: 'HPTEX' },
  { label: '华强物流', value: 'hq568' },
  { label: '华夏龙物流', value: 'HXLWL' },
  { label: '河北建华', value: 'HBJH' },
  { label: '汇达快递', value: 'HDKD' },
  { label: '汇丰物流', value: 'HF' },
  { label: '华航快递', value: 'HHKD' },
  { label: '华翰物流', value: 'HHWL' },
  { label: '海盟速递', value: 'HMSD' },
  { label: '华企快运', value: 'HQKY' },
  { label: '华兴物流', value: 'HXWL' },
  { label: '昊盛物流', value: 'HSWL' },
  { label: '鸿泰物流', value: 'HTWL' },
  { label: '合肥汇文', value: 'HFHW' },
  { label: '辉隆物流', value: 'HLONGWL' },
  { label: '天津宏利德物流', value: 'HLD' },
  { label: '海红网送', value: 'HAIHONGWANGSONG' },
  { label: '航宇快递', value: 'HANGYU' },
  { label: '豪翔物流', value: 'HAOXIANGWULIU' },
  { label: '开心快递', value: 'HAPPYLINK' },
  { label: '宏递快运', value: 'HDWL' },
  { label: '黑猫速运', value: 'HEIMAO56' },
  { label: '合心速递', value: 'HEXINEXPRESS' },
  { label: '环国运物流', value: 'HGY56' },
  { label: '华瀚快递', value: 'HHAIR56' },
  { label: '海欣斯快递', value: 'HIGHSINCE' },
  { label: 'Hi淘易快递', value: 'HITAOE' },
  { label: '猴急送物流', value: 'HJS' },
  { label: '飞豹速递', value: 'HKEEX' },
  { label: '香港邮政', value: 'HKPOST' },
  { label: '互联快运', value: 'HLKYTJ' },
  { label: '共联配', value: 'HLPGYL' },
  { label: '海联快递', value: 'HLTOP' },
  { label: '顺时达物流', value: 'HNSSD56' },
  { label: '中强物流', value: 'HNZQWL' },
  { label: '如家国际快递', value: 'HOMECOURIER' },
  { label: '居家通', value: 'HOMEXPRESS' },
  { label: '洪都拉斯', value: 'HONDURAS' },
  { label: '宏捷国际物流', value: 'HONGJIE' },
  { label: '宏品物流', value: 'HONGPINWULIU' },
  { label: '海硕高铁速递', value: 'HSGTSD' },
  { label: '户尚冷链物流', value: 'HSLL' },
  { label: '恒通快递', value: 'HTKD' },
  { label: '环东物流', value: 'HUANDONGLG' },
  { label: '华夏货运', value: 'HUAXIAHUOYUN' },
  { label: '汇峰物流', value: 'HUIF56' },
  { label: '汇霖大货网', value: 'HUILIN56' },
  { label: '货拉拉物流', value: 'HUOLALAWULIU' },
  { label: '户通物流', value: 'HUTONGWULIU' },
  { label: '鸿远物流', value: 'HYESHIP' },
  { label: '上海昊宏国际货物', value: 'HYK' },
  { label: '恒宇运通', value: 'HYYTES' },
  { label: '华企快递', value: 'HQKD' },
  { label: '韩润物流', value: 'HRWL' },
  { label: '货运皇物流', value: 'HYH' },
  { label: '好来运快递', value: 'HLYSD' },
  { label: '皇家物流', value: 'HJWL' },
  { label: '海信物流', value: 'HISENSE' },
  { label: '汇森速运', value: 'HSSY' },
  { label: '徽托邦物流', value: 'HTB56' },
  { label: '爱拜物流', value: 'IBUY8' },
  { label: '泛太优达', value: 'IEX' },
  { label: '无限速递', value: 'IGCAEXPRESS' },
  { label: '捷安达', value: 'JAD' },
  { label: '京广速递', value: 'JGSD' },
  { label: '九曳供应链', value: 'JIUYE' },
  { label: '急先达', value: 'JXD' },
  { label: '晋越快递', value: 'JYKD' },
  { label: '佳成国际', value: 'JCEX' },
  { label: '嘉诚速达', value: 'JCSUDA' },
  { label: '急递', value: 'JDPPLUS' },
  { label: '捷仕', value: 'JETSTAREXP' },
  { label: '佳辰国际速递', value: 'JIACHENEXPRESS' },
  { label: '佳家通货运', value: 'JIAJIATONG56' },
  { label: '加佳物流', value: 'JIAJIAWL' },
  { label: '佳吉快递', value: 'JIAJIKUAIDI' },
  { label: '嘉贤物流', value: 'JIAXIANWULIU_HEZUO' },
  { label: '捷邦物流', value: 'JIEBORNE' },
  { label: '极光转运', value: 'JIGUANG' },
  { label: '金岸物流', value: 'JINAN' },
  { label: '锦程物流', value: 'JINCHENGWULIU' },
  { label: '近端', value: 'JINDUAN' },
  { label: '景顺物流', value: 'JSWL' },
  { label: '劲通快递', value: 'JINTONGKD' },
  { label: '冀速物流', value: 'JISU' },
  { label: '久久物流', value: 'JIUJIUWL' },
  { label: '佳捷翔物流', value: 'JJX888' },
  { label: '极速达', value: 'JSDKY' },
  { label: '绝配国际速递', value: 'JPKD' },
  { label: '金润荷役物流', value: 'JRHYWL' },
  { label: '骏绅物流', value: 'JSEXPRESS' },
  { label: '时达通', value: 'JSSDT56' },
  { label: '聚鼎物流', value: 'JUDING' },
  { label: '聚盟共建', value: 'JUMSTC' },
  { label: '骏丰国际速递', value: 'JUNFENGGUOJI' },
  { label: '聚物物流', value: 'JUWU' },
  { label: '捷特快递', value: 'JTKD' },
  { label: '精英速运', value: 'JYSY' },
  { label: '加运美', value: 'JYM' },
  { label: '景光物流', value: 'JGWL' },
  { label: '佳怡物流', value: 'JYWL' },
  { label: '京东快运', value: 'JDKY' },
  { label: '金大物流', value: 'JDWL' },
  { label: '跨越速运', value: 'KYSY' },
  { label: '快服务', value: 'KFW' },
  { label: '快速递物流', value: 'KSDWL' },
  { label: '康力物流', value: 'KLWL' },
  { label: '快淘快递', value: 'KTKD' },
  { label: '考拉国际速递', value: 'KAOLAEXPRESS' },
  { label: '科捷物流', value: 'KEJIE' },
  { label: '嘉里大荣物流', value: 'KERRYTJ' },
  { label: '启邦国际物流', value: 'KEYPON' },
  { label: '科紫物流', value: 'KZWL' },
  { label: '快航飞递', value: 'KHFD' },
  { label: '考拉速递', value: 'KOALAEXP' },
  { label: '韩国邮政韩文', value: 'KOREAPOSTKR' },
  { label: '淘韩国际快递', value: 'KRTAO' },
  { label: '四川快优达速递', value: 'KUAIYOUDA' },
  { label: '凯信达', value: 'KXDA' },
  { label: '快优达速递', value: 'KYDSD' },
  { label: '快8速运', value: 'KBSY' },
  { label: '龙邦快递', value: 'LB' },
  { label: '蓝弧快递', value: 'LHKD' },
  { label: '拉火速运', value: 'LAHUOEX' },
  { label: '蓝镖快递', value: 'LANBIAOKUAIDI' },
  { label: '立白宝凯物流', value: 'LBBK' },
  { label: '两点之间', value: 'LDZY168' },
  { label: '楽道物流', value: 'LEDAOWULIU' },
  { label: '乐递供应链', value: 'LEDII' },
  { label: '云豹国际货运', value: 'LEOPARD' },
  { label: '美联快递', value: 'LETSEML' },
  { label: '联合速递', value: 'LHEXPRESSUS' },
  { label: '联合快递', value: 'LHKDS' },
  { label: '联邦快递-英文', value: 'LIANBANGKUAIDIEN' },
  { label: '成都立即送', value: 'LIJISONG' },
  { label: '领送送', value: 'LINGSONG' },
  { label: '小熊物流', value: 'LITTLEBEARBEAR' },
  { label: '新易泰', value: 'LNET' },
  { label: '龙邦速递', value: 'LONGBANWULIU' },
  { label: '加拿大龙行速运', value: 'LONGCPS' },
  { label: '长风物流', value: 'LONGVAST' },
  { label: '6LSEXPRESS', value: 'LSEXPRESS' },
  { label: '乐天速递', value: 'LTEXP' },
  { label: '联通快递', value: 'LTPARCEL' },
  { label: '陆本速递', value: 'LUBEN' },
  { label: '论道国际物流', value: 'LUNDAO' },
  { label: '绿卡城配', value: 'LVKAR' },
  { label: '联运通物流', value: 'LYT' },
  { label: '乐捷递', value: 'LJD' },
  { label: '立即送', value: 'LJS' },
  { label: '联昊通速递', value: 'LHT' },
  { label: '民邦快递', value: 'MB' },
  { label: '民航快递', value: 'MHKD' },
  { label: '美快', value: 'MK' },
  { label: '门对门快递', value: 'MDM' },
  { label: '今枫国际快运', value: 'MAPLEEXPRESS' },
  { label: '迈达物流', value: 'MD' },
  { label: '美泰物流', value: 'MEITAI' },
  { label: '美西快递', value: 'MEIXI' },
  { label: '魔速达', value: 'MOSUDA' },
  { label: '闽盛快递', value: 'MSKD' },
  { label: '密尔克卫', value: 'MEKW' },
  { label: '迈隆递运', value: 'MRDY' },
  { label: '明亮物流', value: 'MLWL' },
  { label: '美乐维冷链物流', value: 'MEILEWEI' },
  { label: '南方传媒物流', value: 'NFCM' },
  { label: '星云速递', value: 'NEBUEX' },
  { label: '尼尔物流', value: 'NEWL' },
  { label: '速尔哪吒', value: 'NEZHA' },
  { label: '云链航空', value: 'NHACEH' },
  { label: '南天物流', value: 'NT56' },
  { label: '腾达速递', value: 'NNTENGDA' },
  { label: '诺尔国际物流', value: 'NUOER' },
  { label: '偌亚奥国际快递', value: 'NUOYAAO' },
  { label: '新西兰中通', value: 'NZZTO' },
  { label: '能达速递', value: 'NEDA' },
  { label: 'ONTRAC', value: 'ONTRAC' },
  { label: '平安达腾飞快递', value: 'PADTF' },
  { label: '泛捷快递', value: 'PANEX' },
  { label: '品骏快递', value: 'PJ' },
  { label: '陪行物流', value: 'PXWL' },
  { label: '诚一物流', value: 'PARCELCHINA' },
  { label: '英国邮政大包EMS', value: 'PARCELFORCECN' },
  { label: '急顺通', value: 'PZHJST' },
  { label: '普畅物流', value: 'PCWL56' },
  { label: '全球速递', value: 'PDSTOW' },
  { label: '派尔快递', value: 'PEEX' },
  { label: '配思货运', value: 'PEISIHUOYUNKUAIDI' },
  { label: '鹏程快递', value: 'PENGCHENG' },
  { label: '品信快递', value: 'PINXINKUAIDI' },
  { label: '龙行天下', value: 'PMT0704BE' },
  { label: 'PostElbe', value: 'POSTELBE' },
  { label: '荷兰邮政-中国件', value: 'POSTNLCHINA' },
  { label: 'PCAExpress', value: 'PCA' },
  { label: '皮牙子快递', value: 'BAZIRIM' },
  { label: '全晨快递', value: 'QCKD' },
  { label: '全日通快递', value: 'QRT' },
  { label: '快客快递', value: 'QUICK' },
  { label: '全信通', value: 'QXT' },
  { label: '秦邦快运', value: 'QBEXPRESS' },
  { label: '雪域快递', value: 'QHXYKD' },
  { label: '雪域易购', value: 'QHXYYG' },
  { label: '全川摩运', value: 'QUANCHUAN56' },
  { label: '千里速递', value: 'QIANLI' },
  { label: '秦岭智能速运', value: 'QINLING' },
  { label: '全球邮政', value: 'QQYZ' },
  { label: '全际通', value: 'QUANJITONG' },
  { label: '全速通', value: 'QUANSUTONG' },
  { label: '全通快运', value: 'QUANTWL' },
  { label: '七曜中邮', value: 'QYZY' },
  { label: '如风达', value: 'RFD' },
  { label: '荣庆物流', value: 'RQ' },
  { label: '日日顺物流', value: 'RRS' },
  { label: '日昱物流', value: 'RLWL' },
  { label: '人人转运', value: 'RENRENEX' },
  { label: '睿和泰速运', value: 'RHT' },
  { label: '锐界同城', value: 'RJTCSD' },
  { label: '日日顺快线', value: 'RRSKX' },
  { label: '日日顺智慧物联', value: 'RRSZHWL' },
  { label: '瑞丰速递', value: 'RFEX' },
  { label: '赛澳递', value: 'SAD' },
  { label: '苏宁物流', value: 'SNWL' },
  { label: '圣安物流', value: 'SAWL' },
  { label: '上大物流', value: 'SDWL' },
  { label: '盛丰物流', value: 'SFWL' },
  { label: '速通物流', value: 'ST' },
  { label: '速腾快递', value: 'STWL' },
  { label: '速必达物流', value: 'SUBIDA' },
  { label: '速递e站', value: 'SDEZ' },
  { label: '速呈宅配', value: 'SCZPDS' },
  { label: '速尔快递', value: 'SURE' },
  { label: '山东海红', value: 'SDHH' },
  { label: '盛辉物流', value: 'SHWL' },
  { label: '穗佳物流', value: 'SJWL' },
  { label: '三态速递', value: 'STSD' },
  { label: '山西红马甲', value: 'SXHMJ' },
  { label: '世运快递', value: 'SYKD' },
  { label: '盛通快递', value: 'STKD' },
  { label: '郑州速捷', value: 'SJ' },
  { label: '赛澳递for买卖宝', value: 'SAIAODIMMB' },
  { label: '叁虎物流', value: 'SANHUWULIU' },
  { label: '中加国际快递', value: 'SCIC' },
  { label: '四川星程快递', value: 'SCXINGCHENG' },
  { label: '药淘齐物流', value: 'SCYTQ' },
  { label: '速达速递', value: 'SDSD' },
  { label: '尚橙物流', value: 'SHANGCHENG' },
  { label: '上海快通', value: 'SHANGHAIKUAITONG' },
  { label: '山西建华', value: 'SHANXIJIANHUA' },
  { label: '捎客物流', value: 'SHAOKE' },
  { label: '杰响物流', value: 'SHBWCH' },
  { label: '闪货极速达', value: 'SHJSD' },
  { label: '上海林道货运', value: 'SHLDHY' },
  { label: '同城快寄', value: 'SHPOST' },
  { label: '顺丰-繁体', value: 'SHUNFENGHK' },
  { label: '顺丰快运', value: 'SHUNFENGKUAIYUN' },
  { label: '顺丰冷链', value: 'SHUNFENGLENGYUN' },
  { label: '顺捷达', value: 'SHUNJIEDA' },
  { label: '顺捷丰达', value: 'SHUNJIEFENGDA' },
  { label: '顺士达速运', value: 'SHUNSHID' },
  { label: '签收快递', value: 'SIGNEDEXPRESS' },
  { label: '中外运空运', value: 'SINOAIRINEX' },
  { label: '中外运速递-中文', value: 'SINOEX' },
  { label: '易普递', value: 'SIXROAD' },
  { label: '四季安物流', value: 'SJA56' },
  { label: '行必达', value: 'SPEEDA' },
  { label: '申必达', value: 'SPEEDOEX' },
  { label: '速速达', value: 'SSD' },
  { label: '星速递', value: 'STAREX' },
  { label: '星运快递', value: 'STARYVR' },
  { label: '美国申通', value: 'STOEXPRESS' },
  { label: '申通新西兰', value: 'STONEWZEALAND' },
  { label: '智德物流', value: 'STZD56' },
  { label: '速豹', value: 'SUBAOEX' },
  { label: '特急便物流', value: 'SUCMJ' },
  { label: '速风快递', value: 'SUFENGKUAIDI' },
  { label: '速品快递', value: 'SUPINEXPRESS' },
  { label: '苏里南', value: 'SURPOST' },
  { label: '瑞士邮政', value: 'SWCH' },
  { label: '瑞典邮政', value: 'SWEDENPOST' },
  { label: '三象速递', value: 'SXEXPRESS' },
  { label: '红马甲物流', value: 'SXHONGMAJIA' },
  { label: '沈阳佳惠尔', value: 'SYJHE' },
  { label: '深圳DPEX', value: 'SZDPEX' },
  { label: '世华通物流', value: 'SZSHIHUATONG56' },
  { label: '深圳邮政', value: 'SZYOUZHENG' },
  { label: '顺心捷达', value: 'SX' },
  { label: '商桥物流', value: 'SQWL' },
  { label: '佳旺达物流', value: 'SYJWDX' },
  { label: '台湾邮政', value: 'TAIWANYZ' },
  { label: '泰联物流', value: 'HNTLWL' },
  { label: '唐山申通', value: 'TSSTO' },
  { label: '特急送', value: 'TJS' },
  { label: '通和天下', value: 'TONGHETIANXIA' },
  { label: '通用物流', value: 'TYWL' },
  { label: '泰进物流', value: 'TAIJIN' },
  { label: '特宝专送', value: 'TBZS' },
  { label: '天翔快递', value: 'TIANXIANG' },
  { label: '万家通快递', value: 'TIMEDG' },
  { label: '泰实货运', value: 'TJKJWL' },
  { label: '老扬州物流', value: 'TJLYZ56' },
  { label: '明通国际快递', value: 'TNJEX' },
  { label: '通达兴物流', value: 'TONGDAXING' },
  { label: '运联科技', value: 'TU' },
  { label: '铁中快运', value: 'TZKY' },
  { label: '华宇物流', value: 'TDHY' },
  { label: '天玺物流', value: 'TXWL' },
  { label: '泰捷达物流', value: 'TJDGJWL' },
  { label: '优联吉运', value: 'ULUCKEX' },
  { label: 'UTAO优到', value: 'UTAOSCM' },
  { label: '全一快递', value: 'UAPEX' },
  { label: '万家康', value: 'WJK' },
  { label: '万家物流', value: 'WJWL' },
  { label: '中粮我买网', value: 'WM' },
  { label: '万象物流', value: 'WXWL' },
  { label: '微特派', value: 'WTP' },
  { label: '宁夏万家通', value: 'WANJIATONG' },
  { label: '万达美', value: 'WDM' },
  { label: '威盛快递', value: 'WHEREXPESS' },
  { label: '凡仕特物流', value: 'WLFAST' },
  { label: '沃埃家', value: 'WOWVIP' },
  { label: '伍圆速递', value: 'WUYUANSUDI' },
  { label: '51跨境通', value: 'WYKJT' },
  { label: '温通物流', value: 'WTWL' },
  { label: '武汉晓毕物流', value: 'WHXBWL' },
  { label: '迅驰物流', value: 'XCWL' },
  { label: '信丰物流', value: 'XFEX' },
  { label: '希优特', value: 'XYT' },
  { label: '祥龙运通', value: 'XLYT' },
  { label: '新杰物流', value: 'XJ' },
  { label: '鑫梦成', value: 'XINMENGCHENG' },
  { label: '新宁物流', value: 'XINNING' },
  { label: '鑫通宝物流', value: 'XTB' },
  { label: '国晶物流', value: 'XDSHIPPING' },
  { label: '祥龙运通物流', value: 'XIANGLONGYUNTONG' },
  { label: '翔腾物流', value: 'XIANGTENG' },
  { label: '喜来快递', value: 'XLKD' },
  { label: '迅选物流', value: 'XUNXUAN' },
  { label: '行云物流', value: 'XYB2B' },
  { label: '鑫远东速运', value: 'XYD666' },
  { label: '西邮寄', value: 'XYJ' },
  { label: '晓毕物流', value: 'XIAOBI' },
  { label: '永昌物流', value: 'YC56' },
  { label: '宇达物流', value: 'YDWL' },
  { label: '源安达快递', value: 'YAD' },
  { label: '远成物流', value: 'YCWL' },
  { label: '远成快运', value: 'YCSY' },
  { label: '义达国际物流', value: 'YDH' },
  { label: '药康达快递', value: 'YKD' },
  { label: '原飞航物流', value: 'YFHEX' },
  { label: '耀奇物流', value: 'YAOQI' },
  { label: '亿领速运', value: 'YILINGSUYUN' },
  { label: '亿顺航', value: 'YISHUNHANG' },
  { label: '易通达', value: 'YITONGDA' },
  { label: '鼹鼠快送', value: 'YSEXPRESS' },
  { label: '运通快运', value: 'YTKY168' },
  { label: '亚风快递', value: 'YFSD' },
  { label: '运通快递', value: 'YTKD' },
  { label: '亿翔快递', value: 'YXKD' },
  { label: '运东西网', value: 'YUNDX' },
  { label: '壹米滴答', value: 'YMDD' },
  { label: '一站通速运', value: 'YZTSY' },
  { label: '驭丰速运', value: 'YFSUYUN' },
  { label: '余氏东风', value: 'YSDF' },
  { label: '耀飞快递', value: 'YF' },
  { label: '韵达快运', value: 'YDKY' },
  { label: '云路', value: 'YL' },
  { label: '邮必佳', value: 'YBJ' },
  { label: '越丰物流', value: 'YFEX' },
  { label: '顺捷丰达/银捷速递', value: 'YJSD' },
  { label: '英脉物流', value: 'YMWL' },
  { label: '音素快运', value: 'YSKY' },
  { label: '一统飞鸿', value: 'YTFH' },
  { label: '亚马逊中国', value: 'YAMAXUNWULIU' },
  { label: '一辉物流', value: 'YATFAI' },
  { label: '易达丰国际速递', value: 'YDFEXPRESS' },
  { label: '易航物流', value: 'YIHANGMALL' },
  { label: '英超物流', value: 'YINGCHAO' },
  { label: '一起送', value: 'YIQISONG' },
  { label: '一正达速运', value: 'YIZHENGDASUYUN' },
  { label: '武汉优进汇', value: 'YJHGO' },
  { label: '洋口岸', value: 'YKOUAN' },
  { label: '黑猫同城送', value: 'YNZTSY' },
  { label: '永邦国际物流', value: 'YONGBANGWULIU' },
  { label: '永昌物流', value: 'YONGCHANG' },
  { label: '邮必佳快递', value: 'YOUBIJIA' },
  { label: '邮畅快运', value: 'YOUCWL' },
  { label: '邮来速递', value: 'YOULAI' },
  { label: '壹品速递', value: 'YPSD' },
  { label: '远盾物流', value: 'YUANDUN' },
  { label: '圆通快运', value: 'YUANTONGKUAIYUN' },
  { label: '元智捷诚', value: 'YUANZHIJIECHENG' },
  { label: '宇捷通', value: 'YUJTONG' },
  { label: '美国云达', value: 'YUNDAEXUS' },
  { label: '韵丰物流', value: 'YUNFENG56' },
  { label: '运通速运', value: 'YUNTONG' },
  { label: '邮驿帮速运', value: 'YYBSY' },
  { label: '西安运逸快递', value: 'YYEXP' },
  { label: '远洋国际快递', value: 'YYEXPRESS' },
  { label: '鹰运', value: 'YYSDCN' },
  { label: '圆通国际', value: 'YTOGJ' },
  { label: '宇鑫物流', value: 'YXWL' },
  { label: '包裹/平邮/挂号信', value: 'YZGN' },
  { label: '一智通', value: 'YZT' },
  { label: '一路发物流', value: 'YLFWL' },
  { label: '云聚物流', value: 'YJWL' },
  { label: '宇佳物流', value: 'YUJIAWL' },
  { label: '众邮快递', value: 'ZYE' },
  { label: '中铁快运', value: 'ZTKY' },
  { label: '中天万运', value: 'ZHONGTIANWANYUN' },
  { label: '中外运速递', value: 'ZWYSD' },
  { label: '澳转运', value: 'ZY_AZY' },
  { label: '八达网', value: 'ZY_BDA' },
  { label: '贝易购', value: 'ZY_BYECO' },
  { label: '赤兔马转运', value: 'ZY_CTM' },
  { label: '浩运物流', value: 'ZBHY56' },
  { label: '逐电快运', value: 'ZHUDIANKUAIYUN' },
  { label: '卓实快运', value: 'ZHUOSHIKUAIYUN' },
  { label: 'CUL中美速递', value: 'ZY_CUL' },
  { label: 'ETD', value: 'ZY_ETD' },
  { label: '风驰快递', value: 'ZY_FCKD' },
  { label: '风雷速递', value: 'ZY_FLSD' },
  { label: '皓晨优递', value: 'ZY_HCYD' },
  { label: '海带宝', value: 'ZY_HDB' },
  { label: '汇丰美中速递', value: 'ZY_HFMZ' },
  { label: '豪杰速递', value: 'ZY_HJSD' },
  { label: '华美快递', value: 'ZY_HMKD' },
  { label: '360hitao转运', value: 'ZY_HTAO' },
  { label: '海淘村', value: 'ZY_HTCUN' },
  { label: '365海淘客', value: 'ZY_HTKE' },
  { label: '华通快运', value: 'ZY_HTONG' },
  { label: '海星桥快递', value: 'ZY_HXKD' },
  { label: '华兴速运', value: 'ZY_HXSY' },
  { label: 'LogisticsY', value: 'ZY_IHERB' },
  { label: '领跑者快递', value: 'ZY_LPZ' },
  { label: '量子物流', value: 'ZY_LZWL' },
  { label: '明邦转运', value: 'ZY_MBZY' },
  { label: '168美中快递', value: 'ZY_MZ' },
  { label: '欧e捷', value: 'ZY_OEJ' },
  { label: '欧洲疯', value: 'ZY_OZF' },
  { label: '欧洲GO', value: 'ZY_OZGO' },
  { label: '全美通', value: 'ZY_QMT' },
  { label: '直德邮', value: 'ZDEPOST' },
  { label: '三三国际物流', value: 'ZENZEN' },
  { label: '招金精炼', value: 'ZHAOJIN' },
  { label: '鑫正一快递', value: 'ZHENGYIKUAIDI' },
  { label: '中宏物流', value: 'ZHONGHONGWL' },
  { label: '中环转运', value: 'ZHONGHUANUS' },
  { label: '中速快件', value: 'ZHONGSUKUAIDI' },
  { label: '中铁飞豹', value: 'ZHONGTIEWULIU' },
  { label: '忠信达', value: 'ZHONGXINDA' },
  { label: '众派速递', value: 'ZHPEX' },
  { label: '创运物流', value: 'ZJCY56' },
  { label: '振捷国际货运', value: 'ZJGJ56' },
  { label: '苏通快运', value: 'ZJSTKY' },
  { label: '三真驿道', value: 'ZLINK' },
  { label: '中粮鲜到家物流', value: 'ZLXDJWL' },
  { label: '中通智运', value: 'ZTOZY' },
  { label: '中融泰隆', value: 'ZRTL' },
  { label: '准实快运', value: 'ZSKY' },
  { label: '明辉物流', value: 'ZSMHWL' },
  { label: '中途速递', value: 'ZTCCE' },
  { label: '纵通速运', value: 'ZTSY' },
  { label: '中网物流', value: 'ZWWL' },
  { label: '爱购转运', value: 'ZY_AG' },
  { label: '澳世速递', value: 'ZY_AUSE' },
  { label: '斑马物流', value: 'ZY_BM' },
  { label: '百通物流', value: 'ZY_BT' },
  { label: '策马速递', value: 'ZY_CM' },
  { label: '宜送转运', value: 'ZY_ESONG' },
  { label: '飞碟快递', value: 'ZY_FD' },
  { label: '飞鸽快递', value: 'ZY_FG' },
  { label: '风行快递', value: 'ZY_FX' },
  { label: '皓晨快递', value: 'ZY_HC' },
  { label: '君安快递', value: 'ZY_JA' },
  { label: '骏达快递', value: 'ZY_JD' },
  { label: '久禾快运', value: 'ZY_JH' },
  { label: '金海淘', value: 'ZY_JHT' },
  { label: '龙象快递', value: 'ZY_LX' },
  { label: '美速通', value: 'ZY_MST' },
  { label: 'QQ-EX', value: 'ZY_QQEX' },
  { label: '瑞天快递', value: 'ZY_RT' },
  { label: '四方转运', value: 'ZY_SFZY' },
  { label: '上腾快递', value: 'ZY_ST' },
  { label: '滕牛快递', value: 'ZY_TN' },
  { label: '唐三藏转运', value: 'ZY_TSZ' },
  { label: '同心快递', value: 'ZY_TX' },
  { label: '天翼快递', value: 'ZY_TY' },
  { label: '星辰快递', value: 'ZY_XC' },
  { label: '先锋快递', value: 'ZY_XF' },
  { label: '云骑快递', value: 'ZY_YQ' },
  { label: '运淘美国', value: 'ZY_YTUSA' },
  { label: '中远快运', value: 'ZY100' },
  { label: '增速海淘', value: 'ZYZOOM' },
  { label: '郑州建华', value: 'ZZJH' },
  { label: 'SCS国际物流', value: 'ZY_SCS' },
  { label: 'SOHO苏豪国际', value: 'ZY_SOHO' },
  { label: 'Sonic-Ex速递', value: 'ZY_SONIC' },
  { label: '通诚美中快递', value: 'ZY_TCM' },
  { label: 'TrakPak', value: 'ZY_TPAK' },
  { label: '天天海淘', value: 'ZY_TTHT' },
  { label: '天泽快递', value: 'ZY_TZKD' },
  { label: '迅达快递', value: 'ZY_XDKD' },
  { label: '信达速运', value: 'ZY_XDSY' },
  { label: '新干线快递', value: 'ZY_XGX' },
  { label: '信捷转运', value: 'ZY_XJ' },
  { label: '优购快递', value: 'ZY_YGKD' },
  { label: '友家速递(UCS)', value: 'ZY_YJSD' },
  { label: '云畔网', value: 'ZY_YPW' },
  { label: '易送网', value: 'ZY_YSW' },
  { label: '中运全速', value: 'ZYQS' },
  { label: '中邮物流', value: 'ZYWL' },
  { label: '汇强快递', value: 'ZHQKD' },
  { label: '众通快递', value: 'ZTE' },
  { label: '中通快运', value: 'ZTOKY' },
  { label: '中通冷链', value: 'ZTOCC' },
  { label: '中骅物流', value: 'ZHWL' },
  { label: '中铁物流', value: 'ZTWL' },
  { label: '智汇鸟', value: 'ZHN' },
  { label: '中原快运', value: 'ZYKY' }
]