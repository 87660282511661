<template>
  <div class="header">
    <a-space direction="vertical" :size="30">

      <a-space direction="vertical" size="large">
        <h3>核销管理</h3>
        <a-radio-group v-model:value="value" @change="changepage">
          <a-radio-button value="large" style="width:160px;text-align: center">手机核销</a-radio-button>
          <a-radio-button value="small" style="width:160px;text-align: center">核销记录</a-radio-button>
        </a-radio-group>
      </a-space>
      <a-space :size="60">
        <a-space style="flex-wrap: wrap;">
          <a-space align="baseline" >
            <span>关联门店：</span>
            <a-cascader v-model:value="storerelation" ref="selectStyle" :options="options1" placeholder="门店名称"
              change-on-select @change="styleChange" />
          </a-space>
          <a-space style="padding-left: 15px;">
            <a-button @click="empty">重置</a-button>
            <a-button type="primary" @click="seekstore">筛选</a-button>
          </a-space>
        </a-space>
        <a-space direction="vertical">
          <a-space style="flex-wrap: wrap;">
            <span>手机端核销地址：</span> <a-input :disabled="true" type="text" :value="text" style="width: 290px;" />
            <a-button style="font-size:12px" @click="copy">复制链接</a-button>
            <a-button type="primary" style="font-size:12px" @Click="addcancel">添加核销员</a-button>
          </a-space>
        </a-space>
      </a-space>
    </a-space>

    <a-table class="tablewidth" @change="tablePaginationChange" :dataSource="dataSource" :columns="columnsb" size="middle"
      :pagination="pagination">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'verificationStatus' && record.verificationStatus == 1">
          <a-tag color="#87d068">启用</a-tag>
        </template>
        <template v-if="column.dataIndex == 'verificationStatus' && record.verificationStatus == 2">
          <a-tag color="#A9A9A9">关闭</a-tag>
        </template>
        <template v-if="column.dataIndex == 'createTime'">
          {{ record.createTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}
        </template>
        <template v-if="column.dataIndex == 'controller'">

          <a style="font-size: 14px;" @click="cancelog(record.id)">核销记录</a>
          <span>-</span>
          <a style="font-size: 14px;" @click="updatecancel(record)">编辑</a>

        </template>
      </template>
    </a-table>
  </div>
</template>
 
<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      storerelation: "",
      openKeys: 'sub1',
      value: "large",
      text: "https://supply.ouyuetech.com/PhoneLogin",
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        // 搜索页数回车跳转
        showQuickJumper: true,
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 4 // 每页条数，所有页设置统一的条数
      },
      dataSource: [],
      columnsb: [
        {
          title: '归属门店',
          dataIndex: 'storeName',
          key: 'storeName',
        },
        {
          title: '账号',
          dataIndex: 'verificationUsername',
          key: 'verificationUsername',
        },
        {
          title: '已核销码数',
          dataIndex: 'verificationSheet',
          key: 'verificationSheet',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: '状态',
          dataIndex: 'verificationStatus',
          key: 'verificationStatus',
        },
        {
          title: '操作',
          dataIndex: 'controller',
          key: 'controller',
        },
      ],
      options1: []
    }

  },
  created () {
    this.getMenuList(),
      this.getstore()
  },
  methods: {
    handleMenuClick () {

    },
    //获取菜单的数据
    async getMenuList () {
      axios.get(API + "/Verifications", {
        params: {
          storeRelation: this.storerelation[0],
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    async getstore () {
      axios.get(API + "/store", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.options1.push({ index: i, id: res.data[i].id, value: res.data[i].storeName, label: res.data[i].storeName })

        }

      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //点击改变页面（电脑核销，手机核销，核销记录）
    changepage (value) {
      if (value.target.value == 'large') {
        this.$router.push('/CancelManagement')
      } else {
        this.$router.push('/CancelLog')
      }
    },
    addcancel () {
      this.$router.push('/AddCancel')
    },
    //定义的方法
    copy () {
      //参数是复制的内容
      //.then()中的函数是复制成功和复制失败的两个回调函数
      this.$copyText(this.text).then(
        (e) => {
          this.$message.success("复制: " + this.text + " 成功!!")
        },
        (err) => {
          alert("复制失败")
        }
      )
    },
    // 筛选
    seekstore () {
      this.getMenuList()
    },
    // 清除所选的数据
    empty () {
      this.storerelation = ""
      this.getMenuList()
    },
    //查看核销记录logid为核销记录id
    cancelog (logid) {
      this.$router.push({
        path: '/CancelLog', query: { logid: logid }
      })
    },
    //编辑
    updatecancel (listcancel) {
      this.$router.push({
        path: '/addCancel', query: { cancelinformation: JSON.stringify(listcancel) }
      })
    },
    //门店下拉框选择或者清除时的调用
    styleChange (value) {
      if (value == undefined) {
        this.storerelation = ""
      }
    },
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页 
  },

}

</script>
 
 <!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
  padding: 18px 48px 0 48px;
}
</style>
 
 