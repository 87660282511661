import { createApp } from 'vue' // 固定导入
import App from './App'
import './assets/css/global.css' // 设置全局样式
import '@/utils/rem.js'
import router from './router'
import * as antIcons from '@ant-design/icons-vue'
import UUID from "vue-uuid"
import 'amfe-flexible/index.js'
// import JsonExcel from "vue-json-excel";
import axios from '@/assets/js/axiosInstance.js'
import Axios from "axios"
// import {
//   ARow,ACol,Button, Form, Input, Checkbox, Menu, Layout, Table, Select, DatePicker, Spin, Switch,
//   ConfigProvider, InputNumber, Radio, Upload, FormItem, Dropdown, Space, Grid, Image, Tag, Cascader, Popconfirm, Modal, Pagination, Descriptions, Card, List, TreeSelect, Empty
// } from 'ant-design-vue' // 引入antdv中常用的组件【基本上就这些，如果有需要全局进入的，再另行添加】
import Antd from 'ant-design-vue'

//注入全局属性$message
import { message } from 'ant-design-vue'
import VueClipboard from 'vue-clipboard2'
import ReturnAddressVue from './components/return/ReturnAddress.vue'
import 'ant-design-vue/dist/reset.css'

const app = createApp(App) // 方便表达
app.use(router)
app.use(UUID)
app.use(Antd)
app.use(VueClipboard)
// app.use(ARow).use(ACol).use(Button).use(Form).use(ConfigProvider).use(Switch)
//   .use(Input).use(Checkbox).use(Menu).use(Layout).use(Table).use(Select).use(DatePicker).use(Spin).use(router).use(InputNumber).use(Radio)
//   .use(Upload).use(FormItem).use(Dropdown).use(Space).use(Grid).use(UUID).use(Image).use(Tag).use(Cascader).use(Popconfirm).use(Modal).use(Pagination)
//   .use(Descriptions).use(Card).use(List).use(TreeSelect).use(Empty)
// 自动注册这些组件下的子组件 例如Button下的Button.Group
//将这个vue3app全局挂载到#app元素上
app.config.globalProperties.$axios = axios  //配置axios的全局引
// 全局请求拦截器（统一发送请求头，把session中的token发送）
Axios.interceptors.request.use(config => {
  let token = sessionStorage.getItem("token")
  if (token) {
    config.headers["token"] = token + "," + JSON.parse(sessionStorage.getItem("user_info")).id
  }
  return config
})
// 创建一个错误信息的队列，用于保存已经显示的错误信息
const errorQueue = []
//全局响应拦截器（处理后端返回的错误码）
Axios.interceptors.response.use(
  re => {
    return re
  },
  err => {
    if (err.response.status === 520) {
      if (sessionStorage.getItem("errorQueue") != "1") {
        alert("您的账号已被修改密码，请重新登录")
        // 添加错误信息到队列
        sessionStorage.setItem("errorQueue", "1")
        //跳转到login页面
        router.push("/login")
      }
      return Promise.reject("520")
    }
    return Promise.reject("服务器异常")
  }
)
Object.keys(antIcons).forEach(key => {
  app.component(key, antIcons[key])
})

app.config.globalProperties.$antIcons = antIcons

app.config.globalProperties.$message = message // 为message设置全局样式

app.mount('#app')