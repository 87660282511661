<template>
  <div class="header">
    <a-space direction="vertical" :size="30">
      <a-space direction="vertical" size="large">
        <h3>核销管理</h3>
        <a-radio-group v-model:value="value" @change="changepage">
          <a-radio-button value="large" style="width:160px;text-align: center">手机核销</a-radio-button>
          <a-radio-button value="small" style="width:160px;text-align: center">核销记录</a-radio-button>
        </a-radio-group>
      </a-space>
      <a-space>
        <a-space :size="size">
          <span><span style="color: red;">*</span>选择时间：</span>
          <a-range-picker style="width: 200px" v-model:value="valuetime" :locale="locale"
            :placeholder="['开始时间', '结束时间']" />
        </a-space>
        <a-space align="baseline" :size="size">
          <span>关联门店：</span>
          <a-cascader v-model:value="storerelation" ref="selectStyle" :options="options1" placeholder="门店名称"
            change-on-select @change="styleChange" />
        </a-space>
        <a-space>
          <a-button type="primary" @click="empty()">重置</a-button>
          <a-button @click="seekstore()">筛选</a-button>
        </a-space>
      </a-space>
    </a-space>
    <a-table class="tablewidth" :dataSource="dataSource" :columns="columnsb" :pagination="pagination"
      @change="tablePaginationChange">
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex == 'index'">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-if="column.dataIndex == 'writeOffTime'">
          <span>{{ record.writeOffTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}</span>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      storerelation: "",
      value: "small",
      valuetime: "",
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // 搜索页数回车跳转
        showQuickJumper: true,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 4 // 每页条数，所有页设置统一的条数
      },
      dataSource: [],
      options1: [],
      columnsb: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
        },
        {
          title: '核销时间',
          dataIndex: 'writeOffTime',
          key: 'writeOffTime',
        },
        {
          title: '订单号',
          dataIndex: 'orderNumber',
          key: 'orderNumber',
        },
        {
          title: '关联门店',
          dataIndex: 'affiliatedStore',
          key: 'affiliatedStore',
        },
        {
          title: '核销人',
          dataIndex: 'verificationOfficerName',
          key: 'verificationOfficerName',
        },
        {
          title: '核销商品',
          dataIndex: 'huoName',
          key: 'huoName',
        },
        {
          title: '核销码',
          dataIndex: 'cardNumber',
          key: 'cardNumber',
        },
      ],
    }

  },
  created () {
    this.getMenuList(),
      this.getstore()
  },
  methods: {
    handleMenuClick () {

    },
    // 关联门店
    async getstore () {
      axios.get(API + "/store", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id,
        }
      }
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.options1.push({ index: i, id: res.data[i].id, value: res.data[i].storeName, label: res.data[i].storeName })

        }

      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //获取菜单的数据
    async getMenuList () {
      // 初始化一个数值做判断
      var minvaluetime = ""
      var maxvaluetime = ""
      // 假设选择了时间进入转换格式
      if (this.valuetime != undefined && this.valuetime != "") {
        minvaluetime = this.valuetime[0].format('YYYY-MM-DD HH:mm:ss')
        maxvaluetime = this.valuetime[1].format('YYYY-MM-DD HH:mm:ss')
      }
      // 做此操作主要是值为undefined取下标会报错，判断不是undefined赋值否则为空
      var storerelation = ""
      if (this.storerelation != undefined) {
        storerelation = this.storerelation[0]
      }
      axios.get(API + "/writeOffRecords", {
        params: {
          storeRelation: storerelation,
          minValueTime: minvaluetime,
          maxValueTime: maxvaluetime,
          verificationOfficerId: this.$route.query.logid,
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id,
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    // 筛选
    seekstore () {
      this.getMenuList()
    },
    // 清除所选的数据
    empty () {
      this.storerelation = ""
      this.valuetime = ""
      this.getMenuList()
    },
    //点击改变页面（电脑核销，手机核销，核销记录）
    changepage (value) {
      if (value.target.value == 'default') {
        this.$router.push('/PhoneCancel')
      } else if (value.target.value == 'large') {
        this.$router.push('/CancelManagement')
      } else {
        this.$router.push('/CancelLog')
      }
    },
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
  padding: 18px 0 0 48px;
}
</style>

