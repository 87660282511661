<template>
  <div class="header">

    <div style="display: flex;gap:10px;align-items: center;">
      <div style="flex: 1;"></div>
      <a-button @click="getback">返回</a-button>
      <!-- <a-button type="primary" @click="show = true"
        v-if="info.afterSaleStatus == 1 || info.afterSaleStatus == 5">处理</a-button> -->
    </div>
    <div style="padding-top: 20px;">

      <a-descriptions title="基本信息" bordered>
        <a-descriptions-item label="订单编号" :span="2">{{ orderNumber }}</a-descriptions-item>
        <a-descriptions-item label="售后状态">
          {{ after_labels[info.afterSaleStatus] }}
        </a-descriptions-item>
        <a-descriptions-item label="下单用户">{{ info.receiverName }}</a-descriptions-item>
        <a-descriptions-item label="售后商品数量">{{ info.huoQty }}</a-descriptions-item>
        <a-descriptions-item label="需退运费">￥{{ info.freight || 0 }}</a-descriptions-item>
        <a-descriptions-item label="售后类型">
          {{ info.afterSaleType == 1 ? "仅退款" : "退款退货" }}
        </a-descriptions-item>
        <a-descriptions-item label="申请原因">{{ info.reasonForApplication }}</a-descriptions-item>
        <a-descriptions-item label="售后总额">￥{{ info.huoPriceAmount }}</a-descriptions-item>
        <a-descriptions-item label="补充说明">
          <div v-html="info.supplementaryExplanation"></div>
        </a-descriptions-item>
        <a-descriptions-item label="凭证">
          <a-image :src="x" v-for="x, i in (info.afterSalePic || '').split(',')" :key="i"
            style="width:80px;height: 80px;"></a-image>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <h3 class="paddingtop"></h3>
    <a-descriptions title="用户寄回息信" bordered v-if="info.afterSaleLogisticsCompany">
      <a-descriptions-item label="寄回物流公司">{{ info.afterSaleLogisticsCompany }}</a-descriptions-item>
      <a-descriptions-item label="寄回物流单号">{{ info.afterSaleLogisticsNumber }}</a-descriptions-item>
    </a-descriptions>


    <h3 class="paddingtop">售后订单清单</h3>
    <a-table class="tablewidth" :dataSource="dataSourcea" :columns="columnsa" :pagination="pagination">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'huoPic'">
          <a-image :width="150" :src="record.huoPic" />
        </template>
        <template v-if="column.dataIndex == 'orderSpecifications'">
          {{ record.specialFields && record.specialFields.sizeName && record.specialFields.sizeId ?
            record.specialFields.sizeName + ': ' + record.specialFields.sizeId : '' }}
        </template>
      </template>
    </a-table>

    <a-modal v-model:open="show" title="处理售后" @ok="saveAfter" :confirm-loading="confirmLoading">
      <a-space v-if="info.orderStatus == 2 && info.afterSaleStatus == 1">
        当前订单尚未发货,请确认是否直接退款处理 ?
        <a-radio-group v-model:value="tuikuan_ok">
          <a-radio :value="1">同意退款</a-radio>
          <a-radio :value="0">不同意退款</a-radio>
        </a-radio-group>
      </a-space>

      <a-space v-if="(info.orderStatus == 3 || info.orderStatus == 4) && info.afterSaleStatus == 1"
        direction="vertical">
        <p>此订单已发货,用户收到商品之后, 需要寄回,</p>
        <p>请确认您已经在“设置”--“退货地址设置” 中配置了默认寄回地址 ?</p>
        <p>点 [确认], 说明您已经配置了有效的退货地址.</p>
      </a-space>

      <a-space v-if="info.afterSaleStatus == 5" direction="vertical">
        <p>买家已经寄回了商品, 请确认您[已经收到了]用户退回的商品 ?</p>
        <p>点 [确认], 说明您已经收到了买家寄回了商品.</p>
        <p>如果退回的商品符合退货标准 , 请确认是否退款 ?</p>
        <a-radio-group v-model:value="tuikuan_ok">
          <a-radio :value="1">同意退款</a-radio>
          <a-radio :value="0">不同意退款</a-radio>
        </a-radio-group>
      </a-space>

    </a-modal>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup() {
    return {
      SearchOutlined,
    }
  },
  data() {
    return {
      confirmLoading: false,
      tuikuan_ok: 1,
      show: false,
      after_labels: {
        1: '待平台审核',
        2: '退款成功',
        3: '退款失败',
        4: '待寄回货品',
        5: '待商家收货',
        6: '售后取消',
      },
      dataSourcea: [
      ],

      columnsa: [
        {
          title: '商品名称',
          dataIndex: 'huoName',
          key: 'huoName',
        },
        {
          title: '规格',
          dataIndex: 'orderSpecifications',
          key: 'orderSpecifications',
        },
        {
          title: '图片',
          dataIndex: 'huoPic',
          key: 'huoPic',
        },
        {
          title: '售后数量',
          dataIndex: 'huoQty',
          key: 'huoQty',
        },
        {
          title: '售价',
          dataIndex: 'huoPriceAmount',
          key: 'huoPriceAmount',
        },
      ],
      orderNumber: 0,
      // afterSaleType: "",
      // afterSaleStatus: "",
      // receiverName: "",
      // allhuoQty: 0,
      // freight: 0,
      // reasonForApplication: "",
      // supplementaryExplanation: "",
      // afterSaleType: "",
      // allhuoPriceAmount: "",
      // pics: [],
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 4 // 每页条数，所有页设置统一的条数
      },
      info: {}
    }

  },
  created() {
    this.getOrderInfo()
  },
  methods: {
    saveAfter() {
      let data = new FormData()
      data.append("state", this.tuikuan_ok)
      data.append("orderId", this.info.id)
      this.confirmLoading = true
      axios.post(API + "/UpdateAfterOrder", data).then(({ data }) => {
        this.confirmLoading = false
        console.log(data.code, 111)
        if (data.code == 200) {
          this.$message.success("操作成功")
          this.getOrderInfo()
        } else {
          this.$message.error(data.msg || "操作失败")
        }
        this.show = false
      }).catch(() => {
        this.confirmLoading = false

      })
    },
    handleMenuClick() {

    },
    //获取菜单的数据
    async getOrderInfo() {
      this.orderNumber = this.$route.query.orderNumber
      axios.get(API + "/OrderInfo", {
        params: {
          orderNumber: this.$route.query.orderNumber,
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(resone => {
        this.info = resone.data[0];
        try {
          this.info.specialFields = JSON.parse(this.info.specialFields || '{}')
        } catch (e) { }
        // this.afterSaleType = resone.data[0].afterSaleType
        // this.afterSaleStatus = resone.data[0].afterSaleStatus
        // this.receiverName = resone.data[0].receiverName
        this.dataSourcea = resone.data
        // this.freight = resone.data[0].freight == undefined ? 0 : resone.data[0].freight
        // this.reasonForApplication = resone.data[0].reasonForApplication
        // this.supplementaryExplanation = resone.data[0].supplementaryExplanation
        // for (var i = 0; i < resone.data.length; i++) {
        //   this.allhuoPriceAmount += resone.data[i].huoPriceAmount
        //   this.allhuoQty += resone.data[i].huoQty
        // }
        // this.pics = resone.data[0].afterSalePic.split(',')
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    getback() {
      this.$router.push('/OrderList')
    },
    tablePaginationChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.tablewidth {
  width: 100%;
  padding-top: 20px
}

.header {
  padding: 24px 48px 0 48px;
}

.paddingtop {
  padding-top: 50px
}

.table {


  width: 100%;
  height: 290px;
  text-align: center;

  border-right: 1px solid rgb(133, 131, 131);

  border-bottom: 1px solid rgb(133, 131, 131);

  /* 设置边缘间距0 */

  border-spacing: 0;

  /* 用于表格属性, 表示表格的两边框合并为一条 */

  border-collapse: collapse
}

.table td {

  border-left: 1px solid rgb(133, 131, 131);

  border-top: 1px solid rgb(133, 131, 131);

  text-align: center;

  font-size: 12px;

  font-weight: bold;

  border-right: 1px solid rgb(172, 172, 172);

}

.tablehead {
  background-color: rgb(223, 223, 223);
  width: 8%;
}

.tablecontext {
  width: 12%;
}
</style>
