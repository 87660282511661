<template>
  <div class="header">
    <a-space direction="vertical">
      <a-space style="padding: 20px 0 0 15px;" :size="size">
        <span><span style="color: red;">*</span>收货人：</span>
        <a-input placeholder="请输入收货人" style="width: 400px;" v-model:value="receivername" />
      </a-space>
      <a-space style="padding: 20px 0 0 0;" :size="size">
        <span><span style="color: red;">*</span>联系电话：</span>
        <a-input placeholder="请输入联系电话" style="width: 400px;" v-model:value="phone" />
      </a-space>
      <a-space style="padding: 20px 0 0 0;" :size="size">
        <span><span style="color: red;">*</span>收货地址：</span>
        <span>省份：</span>
        <a-select :default-value="province" placeholder="请选择省" :options="provinces" @change="handleChange"
          class="selectCss" ref="provinceSelect" v-model:value="provinceName">
        </a-select>
        <span>市区：</span>
        <a-select :default-value="city" placeholder="请选择市" :options="cities" @change="handleChange2" class="selectCss"
          ref="citySelect" v-model:value="cityName">
        </a-select>
        <span>区县：</span>
        <a-select :default-value="area" placeholder="请选择区县" :options="areas" @change="handleChange3" class="selectCss"
          ref="areaSelect" v-model:value="areaName"></a-select>
      </a-space>
      <a-space style="padding: 20px 0 0 84px;" :size="size">
        <a-input placeholder="请输入详细地址" style="width: 400px;" v-model:value="orderaddress" />
      </a-space>
      <a-space style="padding: 20px 0 0 84px;" :size="size">
        <a-checkbox-group v-model:value="defaultaddress1">
          <a-checkbox value="shop" @change="huotype">设为默认地址</a-checkbox>
        </a-checkbox-group>
      </a-space>
      <a-space style="padding: 20px 0 0 84px;" :size="size">
        <a-popconfirm title="确定保存吗?" ok-text="确定" cancel-text="取消" @confirm="yesok()" >
          <a-button type="primary" style="font-size:12px">保存</a-button>
        </a-popconfirm>
      </a-space>
    </a-space>
    <a-table class="tablewidth" :dataSource="dataSource" :columns="columns" :pagination="pagination" size="middle"
      @change="tablePaginationChange">
      <template #bodyCell="{ column, record, index }">

        <template v-if="column.dataIndex == 'index'">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-if="column.dataIndex == 'defaultAddress'">
          <span>{{ record.defaultAddress == 1 ? "是" : "否" }}</span>
        </template>
        <template v-if="column.dataIndex == 'contactAddress'">
          {{ record.contactAddressProvince }}{{ record.contactAddressCity }}{{ record.contactAddressArea }}{{
            record.contactAddress }}
        </template>
        <template v-if="column.dataIndex == 'controller'">
          <a-space>

            <a style="font-size: 14px;" @click="updateaddress(record)">编辑</a>
            <a-popconfirm title="确定删除吗?" ok-text="确定" cancel-text="取消" @confirm="deleteaddress(record.id)"
             >
              <a style="font-size: 14px;">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </a-table>
    <a-modal :open="visible1" title="修改地址" @ok="handleOk" @cancel="visible1 = false" :width="650">
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-popconfirm title="确定修改吗?" ok-text="确定" cancel-text="取消" @confirm="handleOk" >
          <a-button key="submit" type="primary" :loading="loading">保存</a-button>
        </a-popconfirm>
      </template>
      <a-space direction="vertical">
        <a-space style="padding: 20px 0 0 15px;" :size="size">
          <span><span style="color: red;">*</span>收货人：</span>
          <a-input placeholder="请输入收货人" style="width: 400px;" v-model:value="updatereceivername" />
        </a-space>
        <a-space style="padding: 20px 0 0 0;" :size="size">
          <span><span style="color: red;">*</span>联系电话：</span>
          <a-input placeholder="请输入联系电话" style="width: 400px;" v-model:value="updatephone" />
        </a-space>
        <a-space style="padding: 20px 0 0 0;" :size="size">
          <span><span style="color: red;">*</span>收货地址：</span>
          <span>省份：</span>
          <a-select :default-value="updateprovince" placeholder="请选择省" :options="provinces" @change="handleChange"
            class="selectCss" ref="provinceSelect" v-model:value="updateprovinceName">
          </a-select>
          <span>市区：</span>
          <a-select :default-value="updatecity" placeholder="请选择市" :options="cities" @change="handleChange2"
            class="selectCss" ref="citySelect" v-model:value="updatecityName">
          </a-select>
          <span>区县：</span>
          <a-select :default-value="updatearea" placeholder="请选择区县" :options="areas" @change="handleChange3"
            class="selectCss" ref="areaSelect" v-model:value="updateareaName"></a-select>
        </a-space>
        <a-space style="padding: 20px 0 0 84px;" :size="size">
          <a-input placeholder="请输入地址详情" style="width: 400px;" v-model:value="updateorderaddress" />
        </a-space>
        <a-space style="padding: 20px 0 0 84px;" :size="size">
          <a-checkbox-group v-model:value="updatedefaultaddress1">
            <a-checkbox value="1" @change="updatehuotype">设为默认地址</a-checkbox>
          </a-checkbox-group>
        </a-space>
      </a-space>
    </a-modal>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import pcaa from 'china-area-data'
export default {
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      provinces: [],
      province: undefined,
      cities: [],
      city: undefined,
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // 搜索页数回车跳转
        showQuickJumper: true,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      visible1: false,
      areas: [],
      areaName: undefined,
      updatedefaultaddress1: 0,
      defaultaddress1: 0,
      dataSource: [
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
        },
        {
          title: '收货人',
          dataIndex: 'receiverName',
          key: 'receiverName',
        },
        {
          title: '联系电话',
          dataIndex: 'addressPhone',
          key: 'addressPhone',
        },
        {
          title: '联系地址',
          dataIndex: 'contactAddress',
          key: 'contactAddress',
        },
        {
          title: '是否默认',
          dataIndex: 'defaultAddress',
          key: 'defaultAddress',
        },
        {
          title: '操作',
          dataIndex: 'controller',
          key: 'controller',
        },

      ],
      contactaddress1: [],
      contactaddress2: [],
      contactaddress3: [],
      receivername: "",
      orderaddress: "",
      phone: "",
      defaultaddress: "",
      id: 0,
      updatereceivername: "",
      updateorderaddress: "",
      updatephone: "",
      updatedefaultaddress: 2,
      updateprovinceName: undefined,
      updatecityName: undefined,
      updateareaName: undefined
    }

  },
  created () {
    this.getMenuList(),
      this.getProvince()

  },
  methods: {
    handleMenuClick () {

    },
    //获取菜单的数据
    async getMenuList () {
      axios.get(API + "/ReturnAddresses", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data.reverse()

      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    // 获取省份
    async getProvince () {
      let arr = []
      const province = pcaa['86']
      Object.keys(province).map(key => {
        arr.push({
          value: key,
          label: province[key],
        })
      })
      this.provinces = arr
      return arr

    },
    // 获取城市
    getCity (province) {
      let arr = []
      const city = pcaa[province]
      Object.keys(city).map(key => {
        arr.push({
          value: key,
          label: city[key],
        })
      })
      this.cities = arr
      return arr
    },
    // 获取区县
    getArea (city) {
      let arr = []
      const area = pcaa[city]
      Object.keys(area).map(key => {
        arr.push({
          value: key,
          label: area[key],
        })
      })
      this.areas = arr
      return arr
    },
    // 根据选择的省，更新市的下拉选项
    handleChange (value, index) {
      console.log(this.provinceName)
      this.contactaddress1 = index
      this.getCity(value)
    },
    // 选择市
    handleChange2 (value, index) {
      this.contactaddress2 = index
      this.getArea(value)
    },
    // 选择区
    handleChange3 (value, index) {
      this.contactaddress3 = index
    },
    //是否为默认
    huotype (value) {
      if (value.target.checked) {
        this.defaultaddress = 1
      } else {
        this.defaultaddress = 2
      }
    },
    //添加退货地址 this.phone电话，this.receivername收货人， this.contactaddress地区信息
    // this.defaultaddress默认地址
    yesok () {
      if (this.phone != "" && this.receivername != "" && this.contactaddress != []) {
        console.log(this.contactaddress1)
        axios.get(API + "/addReturnAddresses", {
          params: {
            phone: this.phone,
            receiverName: this.receivername,
            contactAddress: this.orderaddress,
            defaultAddress: this.defaultaddress,
            province: this.contactaddress1.label,
            city: this.contactaddress2.label,
            area: this.contactaddress3.label,
            provinceCode: this.contactaddress1.value,
            cityCode: this.contactaddress2.value,
            areaCode: this.contactaddress3.value,
            supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }
        ).then(res => {

          this.$message.success("提交成功")
          // 初始化数值
          Object.assign(this.$data, this.$options.data())
          this.updatecityName = undefined
          this.updateareaName = undefined
          this.provinceName = undefined
          this.cityName = undefined
          this.areaName = undefined
          this.defaultaddress1 = 0
          this.getMenuList()
          this.getProvince()
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
        })

      } else {
        this.$message.error("还有信息未填写")
      }
    },
    //删除退货信息
    deleteaddress (id) {
      axios.get(API + "/delReturnAddresses", {
        params: {
          id: id,
        }
      }
      ).then(res => {
        this.getMenuList()
        this.$message.success("删除成功")
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //编辑,把编辑的数值放入文本框
    updateaddress (listaddress) {
      // 收货人
      this.updatereceivername = listaddress.receiverName
      // 联系地址
      this.updateorderaddress = listaddress.contactAddress
      // 电话
      this.updatephone = listaddress.addressPhone
      // 是否默认
      this.updatedefaultaddress = listaddress.defaultAddress
      //默认按钮样式
      this.updatedefaultaddress1 = listaddress.defaultAddress
      // 省
      this.updateprovinceName = listaddress.addressProvinceCode
      this.getCity(listaddress.addressProvinceCode)
      // 市
      this.updatecityName = listaddress.addressCityCode
      this.getArea(listaddress.addressCityCode)
      // 区
      this.updateareaName = listaddress.addressAreaCode
      // 编辑的地址id
      this.id = listaddress.id
      // 小窗口
      this.visible1 = true
    },
    //编辑默认按钮
    updatehuotype (value) {
      if (value.target.checked) {
        this.updatedefaultaddress = 1
      } else {
        this.updatedefaultaddress = 2
      }
    },
    //  编辑取消
    handleCancel () {
      this.visible1 = false
    },
    // 编辑成功
    handleOk () {
      if (this.updatephone != "" && this.updatereceivername != "" && this.updatecontactaddress != []) {
        axios.get(API + "/updateReturnAddresses", {
          params: {
            id: this.id,
            phone: this.updatephone,
            receiverName: this.updatereceivername,
            contactAddress: this.updateorderaddress,
            defaultAddress: this.updatedefaultaddress,
            province: this.contactaddress1.label,
            city: this.contactaddress2.label,
            area: this.contactaddress3.label,
            provinceCode: this.contactaddress1.value,
            cityCode: this.contactaddress2.value,
            areaCode: this.contactaddress3.value,
            supplyId: JSON.parse(sessionStorage.getItem("user_info")).id

          }
        }
        ).then(res => {
          this.$message.success("提交成功")
          //初始化数值
          Object.assign(this.$data, this.$options.data())
          this.updateprovinceName = undefined
          this.updatecityName = undefined
          this.updateareaName = undefined
          this.getMenuList()
          this.getProvince()
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
        })

      } else {
        this.$message.error("还有信息未填写")
      }
      this.visible1 = false
    },
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页 
  }
}
</script>

<style>
.header {
  padding: 18px 48px 0 48px;
}
</style>

