<template>
  <div>
    <a-layout-sider width="200" style="background: #fff">
      <a-menu mode="inline" style="height: 100%">
        <a-sub-menu key="sub1">
          <template #title>
            <span @click="shopmanage(1)">
              <user-outlined />
              商品管理
            </span>
          </template>
          <a-menu-item key="1" @click="shopmanage(2)">新增商品-单规格</a-menu-item>
          <a-menu-item key="2" @click="shopmanage(3)">新增商品-多规格</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2">
          <template #title>
            <span @click="orderlist(1)">
              <laptop-outlined />
              订单列表
            </span>
          </template>
          <a-menu-item key="3" @click="orderlist(2)">售后</a-menu-item>
          <a-menu-item key="4" @click="orderlist(3)">售后详情</a-menu-item>
          <a-menu-item key="5" @click="orderlist(4)">售后流程</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="6">
          <file-outlined />
          <span>审核记录</span>
        </a-menu-item>
        <a-menu-item key="7">
          <file-outlined />
          <span>门店管理</span>
        </a-menu-item>
        <a-sub-menu key="sub3">
          <template #title>
            <span>
              <notification-outlined />
              核销管理
            </span>
          </template>
          <a-menu-item key="8">核销管理-手机</a-menu-item>
          <a-menu-item key="9">核销管理-记录</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="10">
          <file-outlined />
          <span>区域限制</span>
        </a-menu-item>
        <a-menu-item key="11">
          <file-outlined />
          <span>退货地址设置</span>
        </a-menu-item>
      </a-menu>

    </a-layout-sider>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'

export default {
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    //  i为商品 管理的那个页面，1为商品管理，2为单规格，3为多规格
    shopmanage (i) {
      if (i == 1) {
        this.$router.replace({ path: '/' })
      } else if (i == 2) {
        this.$router.replace({ path: 'AddShop' })
      } else {
        this.$router.replace({ path: 'AddShop' })
      }
    },
    orderlist (i) {
      if (i == 1) {
        this.$router.replace({ path: 'OrderList' })
      } else if (i == 2) {
        this.$router.replace({ path: 'AfterSale' })
      } else if (i == 3) {
        this.$router.replace({ path: 'AfterSaleDetails' })
      } else {
        this.$router.replace({ path: 'AfterSaleProcess' })
      }
    },
    //获取菜单的数据
    async getMenuList () {

    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}




.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
</style>

