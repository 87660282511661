<template>
  <div>
    <a-menu v-model="seekstate" mode="horizontal" @click="handelClick" :selectedKeys="current">
      <a-menu-item key="-1" :disabled="false">
        全部（{{ allsum }}）
      </a-menu-item>
      <a-menu-item key="5">
        已上架（{{ gallerysum }}）
      </a-menu-item>
      <a-menu-item key="4">
        已下架（{{ removesum }}）
      </a-menu-item>
      <a-menu-item key="1">
        草稿箱（{{ housesum }}）
      </a-menu-item>
    </a-menu>
    <br />
    <div style="display: flex;">
      <a-input-search v-model="seek" placeholder="想快速搜索什么商品呢？输入商品名称可快速找到" style="width:805px;padding-left: 30px;"
        @search="onSearch" />
      <a-button style="position: absolute;right: 2%;" type="primary" @click="addshop(undefined)">新增商品</a-button>
    </div>
    <a-space style="padding-top: 20px;padding-left: 30px;flex-wrap: wrap;">
      <a-select :value="shopState" :allowClear="true" ref="selectState" :options="options" placeholder="商品状态"
        @change="StateChange">

      </a-select>
      <a-cascader popupClassName="AcascaderMenu" v-model:value="shoptype" ref="selectStyle"
        style="padding-left: 10px;width: 115px;" :options="options1" placeholder="商品分类" change-on-select
        @change="styleChange" />

      <a-input-group compact style="padding-left: 10px;">
        <span style="padding-top: 5px;">
          零售价：</span>
        <a-input v-model:value="minsaleprice" style="width: 90px; text-align: center" placeholder="请输入" />
        <a-input style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff" placeholder="~"
          disabled />
        <a-input v-model:value="maxsaleprice" style="width: 90px; text-align: center; border-left: '"
          placeholder="请输入" />
        <!-- </a-input-group>
      <a-input-group compact> -->
        <span style="padding-top: 5px;padding-left: 10px;">
          供货价：</span>
        <a-input v-model:value="minprice" style="width:90px; text-align: center" placeholder="请输入" />
        <a-input style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff" placeholder="~"
          disabled />
        <a-input v-model:value="maxprice" style="width: 90px; text-align: center; border-left: 0" placeholder="请输入" />
      </a-input-group>
      <a-space style="display: flex;">
        <a-button @click="reset">重置</a-button>
        <a-button type="primary" @click="seekselect">筛选</a-button>
      </a-space>
    </a-space>
    <a-modal :open="previewVisible" :footer="null"
      style="width: 40%;text-align: center;position: absolute;right: 30%;top: 5%;">
      <span>商品ID：{{ viewHuoId }}</span>
      <img :title="'商品ID：' + viewHuoId" alt="example" style="width: 90%;" :src="previewImage" @cancel="handleCancel" />
    </a-modal>
    <a-table style="padding-top: 5px;text-align: center;font-size: 14px;white-space: nowrap;" :columns="columns"
      :data-source="dataSource" size="middle" :pagination="pagination" @change="tablePaginationChange">
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'huoLevelOne'">
          <a-space :size="0" v-if="record.huoLevelOne != undefined">
            <div v-for="onelist in shoptypeonelist">
              <span v-if="record.huoLevelOne == onelist.id">
                {{ onelist.levelOneName }}
              </span>
            </div>
            <span>-></span>
            <div v-for="twolist in shoptypetwolist">
              <span v-if="record.huoLevelTwo == twolist.id">
                {{ twolist.levelTwoName }}
              </span>
            </div>
          </a-space>
          <div v-if="record.huoLevelOne == undefined">
            未选择分类
          </div>
        </template>
        <template v-if="column.dataIndex == 'huoName'">
          <span style="white-space: normal;" v-if="record.huoName != ''">
            {{ record.huoName }}
          </span>
          <span v-if="record.huoName == ''">
            未填写姓名
          </span>
        </template>
        <template v-if="column.dataIndex == 'huoPic'">
          <img style="width: 60px;height: 60px;margin-bottom: -15px;margin-top: -10px;" :src="record.huoPic"
            v-if="record.huoPic != ''" :preview="false" @click="handlePreview(record.huoPic, record.huoId)" />
          <img :width="60" src="@/assets/zanwutupian.png" v-if="record.huoPic == ''" />
        </template>
        <template v-if="column.dataIndex == 'huoState'">
          <a-tag color="#87d068"
            v-if="record.huoState == 5 && record.auditStatus != 2 && record.auditStatus != 9">已上架</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.auditStatus == 2">待审核</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.auditStatus == 9">已驳回</a-tag>
          <a-tag color="#A9A9A9" v-else-if="record.huoState == 1 && record.auditStatus == 1">草稿箱</a-tag>
          <a-tag color="#A9A9A9"
            v-else-if="record.huoState == 4 && record.auditStatus != 2 && record.auditStatus != 9">已下架</a-tag>
        </template>

        <template v-else-if="column.dataIndex == 'controller'">
          <a-space>
            <a-popconfirm v-if="record.huoState == 4" title="确定发布吗?" ok-text="确定" cancel-text="取消"
              @confirm="issueshop(record.huoId)">
              <a-button style="font-size: 12px;" size="small" type="primary">发布</a-button>
            </a-popconfirm>
            <a-popconfirm v-if="record.huoState == 5" title="确定下架吗?" ok-text="确定" cancel-text="取消"
              @confirm="outofshop(record.huoId)">
              <a-button style="font-size: 12px;" size="small" type="primary">下架</a-button>
            </a-popconfirm>
            <a-button style="font-size: 10px;" size="small" @click="addshop(record.huoId)" type="primary">编辑</a-button>
            <a-popconfirm title="确定删除吗?" placement="left" ok-text="确定" cancel-text="取消"
              @confirm="delshop(record.huoId)">
              <a-button style="font-size: 10px;" size="small" danger>删除</a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </a-table>

  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
// import { message } from 'ant-design-vue';
export default {
  name: 'ShopManage',
  components: {
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup() {
    return {
      SearchOutlined,
    }
  },
  data() {
    return {
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      previewVisible: false,
      previewImage: "",
      viewHuoId: "",
      dataSource: [

      ],
      columns: [
        {
          title: '商品图',
          dataIndex: 'huoPic',
          key: 'huoPic',
          width: 70,
          className: 'column-money',
        },
        // {
        //   title: '商品id',
        //   dataIndex: 'huoId',
        //   key: 'huoId',
        //   ellipsis: true,
        //   className: 'column-money',
        // },
        {
          title: '商品名称',
          dataIndex: 'huoName',
          key: 'huoName',
          width: 500,
          className: 'column-money',
        },
        {
          title: '商品分类',
          dataIndex: 'huoLevelOne',
          key: 'huoLevelOne',
          width: 150,
          className: 'column-money',
        },
        {
          slots: { customRender: 'huoPriceSale' },
          title: '零售价',
          dataIndex: 'huoPriceSale',
          key: 'huoPriceSale',
          sorter: (a, b) => {
            let prev = a.huoPriceSale ? a.huoPriceSale : ''
            let next = b.huoPriceSale ? b.huoPriceSale : ''
            return prev - next
          },
          className: 'column-money',
          width: 100,
        },
        {
          slots: { customRender: 'huoPriceSupply' },
          title: '供货价',
          dataIndex: 'huoPriceSupply',
          key: 'huoPriceSupply',
          sorter: (a, b) => {
            let prev = a.huoPriceSupply ? a.huoPriceSupply : ''
            let next = b.huoPriceSupply ? b.huoPriceSupply : ''
            return prev - next
          },
          className: 'column-money',
          width: 100,
        },
        {
          slots: { customRender: 'huoStockCount' },
          title: '库存',
          dataIndex: 'huoStockCount',
          key: 'huoStockCount',
          sorter: (a, b) => {
            let prev = a.huoStockCount ? a.huoStockCount : ''
            let next = b.huoStockCount ? b.huoStockCount : ''
            return prev - next
          },
          className: 'column-money',
          width: 100,
        },
        {
          slots: { customRender: 'huoSaleCount' },
          title: '销量',
          dataIndex: 'huoSaleCount',
          key: 'huoSaleCount',
          sorter: (a, b) => {
            let prev = a.huoSaleCount ? a.huoSaleCount : ''
            let next = b.huoSaleCount ? b.huoSaleCount : ''
            return prev - next
          },
          className: 'column-money',
          width: 100,
        },
        {
          title: '商品状态',
          dataIndex: 'huoState',
          key: 'huoState',
          className: 'column-money',
          width: 100,
        },
        {
          title: '操作',
          dataIndex: 'controller',
          key: 'controller',
          width: 250,
          className: 'column-money',
        },
      ],
      options: [{
        value: 5,
        label: "已上架"
      }, {
        value: 4,
        label: "已下架"
      }, {
        value: 1,
        label: "草稿箱"
      },],
      options1: [],
      children: [],
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // 搜索页数回车跳转
        showQuickJumper: true,
        // 控制显示多少条
        // showSizeChanger: true,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      width: 500,  // 初始值为null
      current: ['-1'],
      seek: "",
      seekstate: '-1',
      selectcurrent: -1,
      minprice: '',
      maxprice: '',
      minsaleprice: '',
      maxsaleprice: '',
      shoptype: undefined,
      shoptypeonelist: [],
      shoptypetwolist: [],
      shoptypeone: "",
      shoptypetwo: "",
      shopState: undefined,
      allsum: 0,
      gallerysum: 0,
      removesum: 0,
      housesum: 0
    }

  },
  created() {
    this.getMenuList()
  },
  props: {
    onOk: {    //定义onOK属性
      type: Function
    }
  },
  methods: {
    // 关闭模态框
    handleCancel() {
      console.log("qweqweqw")
      this.previewVisible = false
    },
    // 打开模态框（图片预览）
    handlePreview(file, viewHuoId) {
      // console.log(file);
      // if (!file.url && !file.preview) {
      //     file.preview = await getBase64(file.originFileObj);
      // }
      // this.previewImage = file.url || file.preview;
      this.viewHuoId = viewHuoId
      this.previewImage = file
      this.previewVisible = true
    },
    //获取当前页面大小
    handleResize() {
      this.width = this.$el.clientWidth  // 更新宽度
    },
    //封装ajax url为访问的后端后缀，state为传会后端的数据为商品当前的状态,seek为搜索内容,typeone第一类型,typetwo第二类型,minprice最小钱数,maxprice最大钱数
    vueajax(url, state, seek, typeone, typetwo, minprice, maxprice) {
      axios.get(API + url, {
        params: {
          state: state,
          seek: seek,
          typeOne: typeone,
          typeTwo: typetwo,
          minPrice: minprice,
          maxPrice: maxprice,
          audit: "商品库",
          maxSalePrice: this.maxsaleprice,
          minSalePrice: this.minsaleprice,
          supplierId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
      this.shopsum()
    },
    //查询商品数量api
    shopsumajax(state) {
      axios.get(API + '/huoShop', {
        params: {
          state: state,
          audit: "商品库",
          supplierId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        let dataSource = res.data
        if (state == -1) {

          this.allsum = dataSource.length
        }
        if (state == 5) {
          this.gallerysum = dataSource.length
        } if (state == 4) {
          this.removesum = dataSource.length
        } if (state == 1) {
          this.housesum = dataSource.length
        }
      }).catch(error => {
      })
    },
    //查询每个商品-1为全部，0为已上架，1为已下架，2为仓库中
    shopsum() {
      this.shopsumajax(-1)
      this.shopsumajax(5)
      this.shopsumajax(4)
      this.shopsumajax(1)
    },
    //获取菜单的数据,状态为-1传全部商品
    async getMenuList() {
      // 查询商品
      this.vueajax('/huoShop', '-1')
      //一级类别
      axios.get(API + "/levelOneList"
      ).then(res => {
        this.shoptypeonelist = res.data
        for (var i = 0; i < res.data.length; i++) {
          this.options1.push({ index: i, id: res.data[i].id, value: res.data[i].id, label: res.data[i].levelOneName, children: [{}] })
        }
      }).catch(error => {
      })
      // 二级类别,通过商品中获得的分类id用于匹配商品的分类
      axios.get(API + "/levelTwoList", {
        params: {
          id: 0
        }
      }
      ).then(res => {
        this.shoptypetwolist = res.data
      }).catch(error => {

      })
    },
    //控制选择，获取选择（全部，已上架，已下架，创库中）的key
    handelClick(item) {
      this.selectcurrent = item.key
      this.current = [item.key]
      this.vueajax('/huoShop', item.key)
    },
    //跳转到添加/修改商品huoid为空为添加
    addshop(huoid) {
      this.$router.push({
        path: '/AddShop', query: { shopinformation: huoid }
      })
    },
    //搜索框接收的数据
    onSearch(item) {
      this.seek = item
      this.vueajax('/huoShop', this.selectcurrent, item)
    },
    //选择商品状态查商品，value接收到的商品状态的值
    StateChange(value) {
      this.shopState = value
    },
    //商品分类value拿到的是选择的商品类型1和2
    styleChange(value, index) {
      if (value != undefined) {
        if (value[1] != undefined) {
          //把选择的一级二级分类存到data中方便提交时调用
          this.shoptypeone = value[0]
          this.shoptypetwo = value[1]
        } else {
          //这是遍历出第二分类
          axios.get(API + "/levelTwoList", {
            params: {
              id: index[0].id,
            }
          }
          ).then(res => {
            //每次进来先清空分类在重新填充
            this.children.splice(0, res.data.length)
            for (var j = 0; j < res.data.length; j++) {
              this.children.push({ value: res.data[j].id, label: res.data[j].levelTwoName })
            }
            this.options1.splice(index[0].index, 1, {
              index: index[0].index, id: index[0].id, value: index[0].value, label: index[0].label,
              children: this.children
            })
          }).catch(error => {

          })
        }
      } else {
        this.shoptypeone = null
        this.shoptypetwo = null
      }
    },
    //重置将数据全部初始化
    reset() {
      this.selectcurrent = -1,
        this.minprice = ''
      this.maxprice = ''
      this.minsaleprice = ""
      this.maxsaleprice = ''
      this.seek = ''
      this.shopState = null
      this.shoptype = null
      this.shoptypeone = null
      this.shoptypetwo = null
      this.vueajax('/huoShop', "-1")
    },
    //筛选
    seekselect() {
      var shoptype = "-1"
      if (this.shopState != undefined && this.shopState != null) {
        shoptype = this.shopState
      }
      this.vueajax('/huoShop', shoptype, this.seek, this.shoptypeone, this.shoptypetwo, this.minprice, this.maxprice)
    },
    //分页
    tablePaginationChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },
    //删除商品
    delshop(huoid) {
      axios.get(API + "/delShop", {
        params: {
          huoId: huoid,
          supplierId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.seekselect()
        this.$message.success("删除成功")
        //  message.success('删除成功');
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    // 商品下架
    outofshop(huoId) {
      axios.get(API + "/updateShopState", {
        params: {
          huoId: huoId,
          state: 4
        }
      }).then(res => {
        this.$message.success("下架成功")
        this.getMenuList()
      })
    },
    // 草稿箱发布
    issueshop(huoId) {
      axios.get(API + "/updateShopState", {
        params: {
          huoId: huoId,
          state: 2
        }
      }).then(res => {
        this.$message.success("发布成功")
        this.getMenuList()
      })
    }
  },
  mounted() {
    this.width = this.$el.clientWidth  // 获取父元素的宽度
    window.addEventListener('resize', this.handleResize)  // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)  // 取消监听
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.AcascaderMenu .ant-cascader-menu {
  min-height: 500px;
}

th.column-money,
td.column-money {
  border-style: none;
  text-align: center !important;
}

#components-layout-demo-top-side .logo {
  text-align: center;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
</style>
