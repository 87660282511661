<template>
  <div>
    <a-space :size="size" direction="vertical" style=" margin: 2px 0 0 0; padding: 20px 24px 24px 67px; '">
      <a-space :size="1000">
        <div></div>
        <a-button @click="getback">返回</a-button>
      </a-space>
      <a-space>
        <a-descriptions title="审核详情" bordered>
          <a-descriptions-item label="商品名称">{{ shopname }}</a-descriptions-item>
          <a-descriptions-item label="商品分类">{{ shoptype }}</a-descriptions-item>
          <a-descriptions-item label="发货方式">
            <span>{{ shippingMode == "shop" ? "支持物流发货" : "" }}</span>
            <span v-if="shippingMode == 'shop' && storename != ''">/</span>
            <span>{{ storename == "" ? "" : "支持发码" }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="发货说明" :span="3">{{ shippingText }}</a-descriptions-item>
          <a-descriptions-item label="归属门店" v-if="codestore">
            <span v-if="dataSource == ''">暂无添加门店请先添加门店</span>
            <a-space :size="120" v-else>
              <a-checkbox-group disabled v-model:value="shippingstore" @change="storetype">
                <a-checkbox :value="data.storeId + ',' + data.storeName" v-for="data in dataSource">{{ data.storeName
                }}</a-checkbox>
              </a-checkbox-group>
            </a-space>
          </a-descriptions-item>
          <a-descriptions-item label="售后权益">
            <span>{{ afterSaleInterest == "1" ? "支持七天无理由退换货" : "非质量问题不退换" }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="发货时间" v-if="huotime">
            {{ Timevalue == 1 ? "下单后发货" : "指定时间发货" }}
          </a-descriptions-item>
          <a-descriptions-item label="选择时间" v-if="huotime && huoselecttype">
            <span>{{ shippingTime }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="商品单位">
            <span>{{ commodity }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="税率">
            <span>
              {{ value1 }}
            </span>
            <span>%</span>
          </a-descriptions-item>
          <a-descriptions-item label="价格是否含税" :span="2">
            <span>{{ invoiceInterest == "1" ? "含税" : "不含税" }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="商品图" :span="3">
            <draggable v-if="fileList.length > 0" style="  display: flex" v-model="fileList" :list="fileList"
              :animation="100">
              <template #item="{ element, index }">
                <div>
                  <a-image alt="example" style="width: 100px" :src="element.url" />
                </div>

              </template>

            </draggable>
          </a-descriptions-item>
          <a-descriptions-item label="商品详情">
            <div v-html="shoptextshow"></div>
          </a-descriptions-item>
        </a-descriptions>
      </a-space>



      <a-space :size="size">
        <h3>多规格商品：{{ config.visibleFlag == "WhetherNo" ? "否" : "是" }}</h3>
      </a-space>

      <a-space :size="size" style="padding-left: 22px;" v-if="config.visibleFlag == 'WhetherNo'">
        <span>商品货号：</span>
        <span>{{ number == "" ? "此商品未填写货号" : number }}</span>
        <!-- <a-input placeholder="单行输入" v-model:value="number" /> -->
      </a-space>

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherYes'">
        <h3>商品规格：</h3>
      </a-space>
      <a-space :size="size" direction="vertical" v-if="config.visibleFlag == 'WhetherYes' && stdata != ''">
        <a-table :columns="columns" :data-source="stdata" @change="tablePaginationChange" 
          :pagination="false">
          <template #name="{ text }">
            <a>{{ text }}</a>
          </template>
          <template #bodyCell="{ column, index, record }">
            <template v-if="column.dataIndex == 'memberprice'">
              <span>
                {{ stdata[index].priceonetext }}
              </span>
            </template>
            <template v-if="column.dataIndex == 'markrtprice'">

              <span>{{ stdata[index].pricetwotext }}</span>
            </template>
            <template v-if="column.dataIndex == 'inventory'">
              {{ stdata[index].invtext }}
            </template>
            <template v-if="column.dataIndex == 'huo'">
              {{ stdata[index].huotext == "" ? "此商品未填写货号" : stdata[index].huotext }}
            </template>
          </template>
        </a-table>
      </a-space>

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherNo'" style="padding-left: 16px;">

        <span><span style="color: red;">*</span>商品价格：</span>


        <a-space>
          <span>零售价：￥{{ PriceSale }}</span>
          <span>供货价：￥{{ PriceSupply }}</span>
        </a-space>
      </a-space>

      <a-space :size="size" v-if="config.visibleFlag == 'WhetherNo'" style="padding-left: 16px;">
        <span><span style="color: red;">*</span>商品库存：</span>
        <span>{{ huoStockCounts }}</span>
      </a-space>
    </a-space>
  </div>
</template>
<script >
// import { addQuillTitle } from '../assets/js/quill-title.js'
import draggable from 'vuedraggable'
import { SearchOutlined } from '@ant-design/icons-vue'
import headerTopNav from '@/common/HeadSlot.vue'
import headerHead from '@/common/PublicHeader.vue'
import Editor from '@/common/EditorText.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import API from "@/assets/js/axiosInstance.js"
import moment from 'moment'
import axios from 'axios'
const columns = [
  {
    title: '零售价',
    dataIndex: 'memberprice',
    key: 'memberprice',

  },
  {
    title: '供货价',
    dataIndex: 'markrtprice',
    key: 'markrtprice',
    ellipsis: true,
  },
  {
    title: '库存',
    dataIndex: 'inventory',
    key: 'inventory',
    ellipsis: true,
  },
  {
    title: '货号',
    dataIndex: 'huo',
    key: 'huo',
    ellipsis: true,
  },
]

export default {
  props: {
    multiple: {
      type: Number,
      default: 1
    },
    img: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    headerTopNav,
    draggable,
    headerHead,
    Editor,
  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
      locale
    }
  },
  data () {
    return {
      size: "middle",//small | middle | large | number
      selectedKeys1: '2',
      selectedKeys2: '1',
      openKeys: 'sub1',
      loading: false,
      Probability: 0,
      shippingMode: "shop",
      afterSaleInterest: "1",
      maxtype: [],
      mintype: [],
      stdata: [],
      texttitle: "",
      Timevalue: 1,
      uploadList: [],
      allindex: "",
      alltxt: "",
      uploading: false,
      codestore: false,
      stdatasize: 0,
      huoselect: 1,
      addmaxspecification: false,
      allContent: '',
      maxtypeindex: "",
      data: [
      ],
      config: {
        visibleFlag: "WhetherNo"
      },
      columns: columns,
      options1: [],
      children: [],
      storename: "",
      storeid: "",
      shoptype: undefined,
      visible: false,
      visiblea: false,
      visibleb: false,
      shoptypeone: "",
      invoiceInterest: "1",
      isClear: false,//设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      commodity: "",
      tax: 0,
      shoptypetwo: "",
      shopname: '',
      huoStockCounts: "",
      specification: 1,
      Status: undefined,
      number: "",
      value1: 0,
      shippingText: "",
      PriceSale: "",
      PriceSupply: "",
      shoptext: "",
      shippingcode: "",
      imageUrl: '',
      selectsum: 0,
      previewImage: "",
      stdatamin: "",
      shoptextshow: "",
      stdatamax: "",
      stdatavalue: false,
      Buttontype: true,
      shippingstore: [],
      fileList: [

      ],
      huotime: true,
      dataSource: [],
      huocode: false,
      huoselecttype: false,
      shippingTime: "2"
    }

  },
  watch: {
    $route () {
      // 初始化数值
      Object.assign(this.$data, this.$options.data())
      this.getMenuList()
    }
  },
  created () {
    this.getMenuList(),
      this.getstore()
  },
  methods: {
    //获取菜单的数据
    async getMenuList () {
      this.config.visibleFlag = "WhetherNo"
      if (this.$route.query.visibleFlag != undefined) {
        this.config.visibleFlag = this.$route.query.visibleFlag
      }


      //编辑进入此处获取已编辑的信息
      if (this.$route.query.shopinformation != undefined) {
        axios.get(API + "/redisUpdate", {
          params: {
            huoId: this.$route.query.shopinformation,
            supplierId: JSON.parse(sessionStorage.getItem("user_info")).id
          }
        }).then(res => {
          //商品分类
          //商品分类
          axios.get(API + "/levelOneAndTwo", {
            params: {
              id: res.data.huoLevelTwo
            },
          }).then(res => {
            this.shoptype = res.data
          })
          this.shoptypeone = res.data.huoLevelOne
          this.shoptypetwo = res.data.huoLevelTwo
          // 商品名称
          this.shopname = res.data.huoName
          //库存
          this.huoStockCounts = res.data.huoStockCounts
          // 零售价
          this.PriceSale = res.data.huoPriceSale
          // 供货价
          this.PriceSupply = res.data.huoPriceSupply
          // 发货说明
          this.shippingText = res.data.shippingText
          // 税率
          this.value1 = res.data.taxRate / 0.01
          this.Status = res.data.taxRate
          // 商品单位
          this.commodity = res.data.commodity
          //判断是否发码
          if (res.data.shippingCode != "1") {
            // 编辑选择的门店进入页面
            this.storeid = res.data.storeId
            this.storename = res.data.storeName
            for (var i = 0; i < res.data.storeId.split(",").length; i++) {
              this.shippingstore.splice(0, 0, res.data.storeId.split(",")[i] + "," + res.data.storeName.split(",")[i])
            }
            this.codestore = true
          }
          //判断是否发货
          if (res.data.shippingTime == "1") {
            this.shippingMode = ""
            this.huotime = false
          } else {
            if (res.data.shippingTime != "2") {
              // 时间转moment格式渲染到时间选择器上
              var alltime = res.data.shippingTime.split(",")
              const validTime = alltime[0] + "~" +
                alltime[1]
              this.shippingTime = validTime
              this.huoselecttype = true
              this.Timevalue = 2
            }
          }
          // 编辑选择的是否发票数据进入页面
          this.invoiceInterest = res.data.invoiceInterest
          // 编辑的商品为多规格时进入
          if (res.data.specificationType == "2") {
            this.config.visibleFlag = "WhetherYes"
            //多规格初始化，防止出现大规格有缓存出现重复规格
            this.maxtype = []
            this.stdata = []
            this.columns = [
              {
                title: '零售价',
                dataIndex: 'memberprice',
                key: 'memberprice',

              },
              {
                title: '供货价',
                dataIndex: 'markrtprice',
                key: 'markrtprice',
                ellipsis: true,
              },
              {
                title: '库存',
                dataIndex: 'inventory',
                key: 'inventory',
                ellipsis: true,
              },
              {
                title: '货号',
                dataIndex: 'huo',
                key: 'huo',
                ellipsis: true,
              },
            ]
            //大规格
            for (var i = 0; i < res.data.stDataMax.split(",").length; i++) {
              this.maxtype.push({ mintext: [{ text: "" }], text: res.data.stDataMax.split(",")[i], minvalue: res.data.stDataMax.split(",")[i] })
              this.columns.splice(i, 0, {
                dataIndex: "text" + i,
                title: res.data.stDataMax.split(",")[i]
              })
            }
            // 初始化判断重复的值
            var samestdata = ""
            // 获取到小规格中的基本信息遍历到页面data的stdata中
            for (var i = 0; i < res.data.stDataMin.split(",").length; i++) {
              var stdatamin = res.data.stDataMin.split(",")[i].split("?")
              if (stdatamin[1] == "undefined") {
                this.stdata.splice(i, 0, { text0: stdatamin[0], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], invtext: stdatamin[5], huotext: stdatamin[6] })
                //  k判断有几个类型
                var k = 1
              } else if (stdatamin[2] == "undefined") {
                this.stdata.splice(i, 0, { text0: stdatamin[0], text1: stdatamin[1], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], invtext: stdatamin[5], huotext: stdatamin[6] })
                var k = 2
              } else {
                var k = 3
                this.stdata.splice(i, 0, { text0: stdatamin[0], text1: stdatamin[1], text2: stdatamin[2], priceonetext: stdatamin[3], pricetwotext: stdatamin[4], invtext: stdatamin[5], huotext: stdatamin[6] })
              }
              //通过有几种类型往添加规格小窗口中添加数值
              for (var j = 0; j < k; j++) {
                if (samestdata.indexOf(stdatamin[j]) == -1) {
                  samestdata += stdatamin[0] + "," + stdatamin[1] + "," + stdatamin[2]
                  this.mintype = this.maxtype[j].mintext
                  this.mintype.push({ text: stdatamin[j] })
                  this.maxtype[j].mintext = this.mintype
                  this.mintype = ''
                }
              }
            }
            this.addmaxspecification = true
          }
          //售后权益改变
          this.afterSaleInterest = res.data.afterSaleInterest
          //编辑之后的图片做处理
          var fileList = res.data.huoPic.split(",")
          this.imageUrl = res.data.huoPic
          for (var i = 0; i < fileList.length; i++) {
            this.fileList.push({
              uid: '-' + i, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
              name: 'i' + i, // 文件名
              status: 'done', // 状态有：uploading done error removed
              url: fileList[i],
            })
          }
          //  给富文本框赋值
          this.shoptextshow = res.data.shopText
          //变换成编辑按钮
          this.Buttontype = false
        }).catch(error => {
          if (error != "520") {
            this.$message.error("后端接口连接异常")
          }
        })
        //首页点击修改之后传商品id进来
      }
      //一级类别
      axios.get(API + "/levelOneList"
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.options1.push({ index: i, id: res.data[i].id, value: res.data[i].levelOneName, label: res.data[i].levelOneName, children: [{}] })
        }
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    //核销门店
    async getstore () {
      axios.get(API + "/store", {
        params: {
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    getback () {
      this.$router.back()
    }
  },

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.span {
  font-size: 16px;
}


.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}


.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/* // 因为我们用的ant的组件的class，会有些默认样式要去掉，如果样式是你自己写的，那就不需要这块了
    //这个样式特别重要，否则偶尔会出现不显示的bug */
.ant-upload-list {
  display: block !important
}
</style>
  
  