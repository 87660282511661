<template>
  <div class="header">

    <div style="display: flex;">
      <h3>订单详情</h3>
      <a-button @click="getback" style="position: absolute;right: 4%;">返回</a-button>
    </div>
    <a-space style="padding-top: 20px;" :size="30">
      <a-card title="交易状态" :bordered="false" style="width: 600px;">
        <p v-if="orderinfodata.isSettled == '1'">
          已结算
        </p>
        <p v-else>
          {{ orderinfodata.orderStatus == 1 ? "待付款" : orderinfodata.orderStatus == 2 ? "待发货" : orderinfodata.orderStatus
            == 3 ? "已发货" : orderinfodata.orderStatus == 4 ? "已关闭" : "已结算" }}
        </p>
      </a-card>

      <a-card title="订单编号" :bordered="false" style="width: 600px;">
        <p>{{ orderNumber }}</p>
      </a-card>
    </a-space>

    <a-descriptions title="订单信息" bordered style="padding-top: 20px;">
      <a-descriptions-item label="用户下单时间">{{ createTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
      }}</a-descriptions-item>
      <a-descriptions-item label="用户支付时间">{{ createTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
      }}</a-descriptions-item>
      <a-descriptions-item label="用户支付方式">{{ orderinfodata.payMethod == 1 ? "微信支付" : "其他" }}</a-descriptions-item>
      <a-descriptions-item label="商品总价">￥{{ allhuoPriceAmount }}</a-descriptions-item>
      <a-descriptions-item label="运费总价">￥{{ freight }}</a-descriptions-item>
      <a-descriptions-item label="订单总价">￥{{ allhuoPriceAmount + freight }}</a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="收货信息" layout="vertical" bordered style="padding-top: 20px;">
      <a-descriptions-item label="收货人姓名：">{{ orderinfodata.receiverName }}</a-descriptions-item>
      <a-descriptions-item label="收货人手机号：">{{ orderinfodata.receiverPhone }}</a-descriptions-item>
      <a-descriptions-item label="收货人地址：">{{ orderinfodata.receiverAddress }}</a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="发货信息" layout="vertical" bordered style="padding-top: 20px;"
      v-if="orderinfodata.orderStatus == 3">
      <a-descriptions-item label="快递公司">{{ orderinfodata.logisticsCompany }}</a-descriptions-item>
      <a-descriptions-item label="快递单号">{{ orderinfodata.logisticsNumber }}</a-descriptions-item>
      <a-descriptions-item label="发货时间">{{ orderinfodata.receiveTime }}</a-descriptions-item>
    </a-descriptions>

    <h3 class="paddingtop">商品详情</h3>
    <a-table class="tablewidth" :dataSource="dataSourcea" :columns="columnsa" :pagination="pagination">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'huoPic'">
          <a-image :width="150" :src="record.huoPic" />
        </template>
        <template v-if="column.dataIndex == 'orderSpecifications'">
          {{ record.specialFields && record.specialFields.sizeName && record.specialFields.sizeId ? record.specialFields.sizeName + ': ' + record.specialFields.sizeId : '' }}
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import API from "@/assets/js/axiosInstance.js"
import axios from 'axios'
import moment from 'moment'
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup() {
    return {
      SearchOutlined,
    }
  },
  data() {
    return {
      dataSourcea: [
      ],

      columnsa: [
        {
          title: '商品名称',
          dataIndex: 'huoName',
          key: 'huoName',
        },
        {
          title: '规格',
          dataIndex: 'orderSpecifications',
          key: 'orderSpecifications',
        },
        {
          title: '图片',
          dataIndex: 'huoPic',
          key: 'huoPic',
        },
        {
          title: '订单数量',
          dataIndex: 'huoQty',
          key: 'huoQty',
        },
        {
          title: '售价',
          dataIndex: 'huoPriceAmount',
          key: 'huoPriceAmount',
        },
      ],
      orderinfodata: {},
      allhuoQty: 0,
      freight: 0,
      allhuoPriceAmount: 0,
      receiveTime: "",
      createTime: "",
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }

  },
  created() {
    this.getMenuList()
  },
  methods: {
    handleMenuClick() {

    },
    //获取菜单的数据
    async getMenuList() {
      this.orderNumber = this.$route.query.orderNumber
      axios.get(API + "/OrderInfo", {
        params: {
          orderNumber: this.$route.query.orderNumber,
          supplyId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(resone => {
        let data = resone.data[0] || {}
        try {
          data.specialFields = JSON.parse(data.specialFields || '{}')
        } catch (e) { }
        // 此订单号的基本信息
        this.orderinfodata = data //resone.data[0]
        // 此订单号的订单
        this.dataSourcea = resone.data
        // 此订单号运费
        this.freight = data.freight == undefined ? 0 : data.freight
        // 此订单号的发货时间
        this.receiveTime = data.receiveTime ? moment(data.receiveTime).format('YYYY-MM-DD HH:mm:ss') : '-'
        // 此订单号的下单时间
        this.createTime = data.createTime
        // 此订单号的总价格
        for (var i = 0; i < resone.data.length; i++) {
          this.allhuoPriceAmount += resone.data[i].huoPriceAmount
          this.allhuoQty += resone.data[i].huoQty
        }

      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },
    getback() {
      this.$router.push('/OrderList')
    },
    tablePaginationChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },//分页
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.tablewidth {
  width: 100%;
  padding-top: 20px
}

.header {
  padding: 24px 48px 0 48px;
}

.paddingtop {
  padding-top: 50px
}

.table {


  width: 100%;
  height: 290px;
  text-align: center;

  border-right: 1px solid rgb(133, 131, 131);

  border-bottom: 1px solid rgb(133, 131, 131);

  /* 设置边缘间距0 */

  border-spacing: 0;

  /* 用于表格属性, 表示表格的两边框合并为一条 */

  border-collapse: collapse
}

.table td {

  border-left: 1px solid rgb(133, 131, 131);

  border-top: 1px solid rgb(133, 131, 131);

  text-align: center;

  font-size: 12px;

  font-weight: bold;

  border-right: 1px solid rgb(172, 172, 172);

}

.tablehead {
  background-color: rgb(223, 223, 223);
  width: 8%;
}

.tablecontext {
  width: 12%;
}
</style>

