<template>
  <div class="header">
    <a-space style="flex-wrap: wrap;">
      <a-space>
        <span>审核状态：</span>
        <a-cascader v-model:value="shopstate" ref="selectStyle" :options="options1" placeholder="全部" change-on-select
          @change="StateChange" />
      </a-space>

      <a-space align="baseline">
        <span>商品名称：</span>
        <a-input placeholder="请输入商品名称" v-model:value="huoname" />
      </a-space>

      <a-space style="padding-left: 22px;">
        <a-button type="primary" @click="reset">重置</a-button>
        <a-button @click="seekselect">筛选</a-button>
      </a-space>
    </a-space>


    <a-table style="padding-top: 20px;width:99%;text-align: center;font-size: 14px;" :columns="columns"
      :data-source="dataSource" size="middle" :pagination="pagination" @change="tablePaginationChange">
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>

      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex == 'huoindex'">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-if="column.dataIndex == 'createTime'">
          <span>{{ record.createTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}</span>
        </template>
        <template v-if="column.dataIndex == 'auditTime' && record.auditTime != ''">
          <span v-if="record.auditTime != undefined">{{ record.auditTime.toLocaleString().replace(/T/g,
            '').replace(/\.[\d]{3}Z/, '') }}</span>
        </template>
        <template v-if="column.dataIndex == 'auditStatus' && record.auditStatus == 3">
          <a-tag color="#87d068">已通过</a-tag>
        </template>
        <template v-else-if="column.dataIndex == 'auditStatus' && record.auditStatus == 2">

          <a-tag color="#A9A9A9">待审核</a-tag>
        </template>
        <template v-else-if="column.dataIndex == 'auditStatus' && record.auditStatus == 9">

          <a-tag color="#A9A9A9">已驳回</a-tag>
        </template>

        <template v-else-if="column.dataIndex == 'controller'">
          <a-space>
            <a style="font-size: 12px;" @click="updateshop(record.huoId)" v-if="record.auditStatus == 9">编辑</a>
            <a style="font-size: 12px;" @click="auditdetails(record.huoId)">详情</a>
          </a-space>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import axios from 'axios'
import API from "@/assets/js/axiosInstance.js"
export default {
  components: {

  }, //表示headerTopNav标签的内容被headerTopNav组件代替
  setup () {
    return {
      SearchOutlined,
    }
  },
  data () {
    return {
      options1: [{
        value: 3,
        label: "已通过"
      }, {
        value: 2,
        label: "待审核"
      }, {
        value: 9,
        label: "已驳回"
      },],
      dataSource: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'huoindex',
          key: 'huoindex',
        },
        {
          title: '商品名称',
          dataIndex: 'huoName',
          key: 'huoName',
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: '处理时间',
          dataIndex: 'auditTime',
          key: 'auditTime',
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatus',
          key: 'auditStatus',
        },
        {
          title: '驳回原因',
          dataIndex: 'rejectReason',
          key: 'rejectReason',
        },
        {
          title: '操作',
          dataIndex: 'controller',
          key: 'controller',
        },
      ],
      huoname: "",
      visible: false,
      shopstate: undefined,
      pagination: {
        current: 1,
        // defaultCurrent: 1,
        total: 0, // 总数
        showSizeChanger: false,
        // 搜索页数回车跳转
        showQuickJumper: true,
        // pageSizeOptions: ['1', '10', '20', '40', '80', '100'],
        showTotal: total => `共 ${total} 条`, // 分页中显示总的数据
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }

  },
  created () {
    this.getMenuList()
  },
  methods: {
    handleMenuClick () {

    },
    // 商品ajax
    vueajax (url, state, audit, seek) {
      axios.get(API + url, {
        params: {
          auditStatus: state,
          audit: audit,
          seek: seek,
          shopState: "",
          supplierId: JSON.parse(sessionStorage.getItem("user_info")).id
        }
      }
      ).then(res => {
        this.dataSource = res.data
      }).catch(error => {
        if (error != "520") {
          this.$message.error("后端接口连接异常")
        }
      })
    },//封装ajax url为访问的后端后缀，state为传会后端的数据为商品当前的状态,seek为搜索内容,typeone第一类型,typetwo第二类型,minprice最小钱数,maxprice最大钱数
    //获取菜单的数据
    StateChange (value) {
      this.shopState = value
      if (value == undefined) {
        this.shopState = ""
      }
    },//选择商品状态查商品，value接收到的商品状态的值
    async getMenuList () {
      this.vueajax('/huoShop', '', '审核页面')
    },
    //筛选
    seekselect () {
      if (this.shopstate == undefined || this.shopstate == null) {
        this.vueajax('/huoShop', "", '审核页面', this.huoname)
      } else {
        this.vueajax('/huoShop', this.shopstate[0], '审核页面', this.huoname)
      }

    },
    updateshop (huoId) {
      // 点击编辑/详情后进入编辑状态
      this.$router.push({
        path: '/AddShop', query: { shopinformation: huoId }
      })
    },
    auditdetails (huoId) {
      // 点击编辑/详情后进入编辑状态
      this.$router.push({
        path: '/AuditDetails', query: { shopinformation: huoId }
      })
    },
    //重置将数据全部初始化
    reset () {
      this.shopstate = null
      this.huoname = null
      this.getMenuList()
    },
    //分页 
    tablePaginationChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current  // 重新设置当前页
      this.pagination.pageSize = pagination.pageSize
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
  padding: 24px 48px 0 48px;
}
</style>

